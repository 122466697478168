import React, { Fragment, useState } from "react";
import {
    Container,
    TableCell,
    Box,
    TableRow,
    Stack,
    Button,
    Tooltip,
    Switch,
    IconButton,
    Typography,
} from "@mui/material";
import CreateIcon from '@mui/icons-material/Create';

import { useNavigate } from "react-router-dom";
import qs from "qs";

import PageTitle from "../../../hooks/page-title";
import {
    SelectBox,
    InputField,
    AddProjectBtn,
    Header,
    UsePagination,
    TableWrapper,
    OrderDataBtn,
    CustomModal,
    MultiSelect,
    PopUpMsg,
    SearchComboBox,
    FilterWrapper
} from "../../../components";
import API from "../../../axios";
// create project form
import ProjectForm from "./project-form";
import moment from "moment/moment";
import { generateKey } from "../../../helpers/generate-key";
import {
    langOptions,
    techOptions,
    statusOpt,
    _columns,
    rowsOption
} from "./data";
import { useSelector } from "react-redux";
import { icon1, icon2, icon3 } from "../../../assets";

export default function Projects() {
    PageTitle('Projects');
    // project modal
    const [openModal, setOpenModal] = React.useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [records, setRecords] = useState(null);
    const [payload, setPayload] = useState({
        open: false,
        message: '',
        type: 'info'
    });
    // filters
    const [page, setPage] = useState(1);
    const [columns, setColumns] = React.useState(_columns);
    const [perPage, setPerPage] = useState(25);
    const [search, setSearch] = useState("");
    const [language, setLanguage] = useState("");
    const [status, setStatus] = useState("");
    const [technologies, setTechnologies] = useState('');
    const [orderBy, setOrderBy] = useState({
        name: "created_at",
        order: "desc",
    });
    const [projectManager, setProjectManager] = React.useState('');
    const [updateKey, setUpdateKey] = useState(generateKey());
    const [toggler, setToggler] = React.useState(false);
    const [IsFilterApply, setIsFilterApply] = React.useState(false);
    const [checked, setChecked] = useState(false);
    const navigate = useNavigate();
    const _userRole = useSelector((state) => state.storeReducer.user.role);

    React.useEffect(() => {
        getData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [page, orderBy, perPage]);

    // get project records from db
    const getData = async (flush = false) => {
        setIsLoading(true);
        var params;
        try {
            if (flush) {
                params = {
                    page: page,
                    per_page: perPage,
                    column: 'created_at',
                    orderby: 'desc',
                };
            } else {
                params = {
                    page: page,
                    per_page: perPage,
                    search: search,
                    active: status === 'active' ? 1 : 0,
                    inactive: status === 'disable' ? 1 : 0,
                    status: status,
                    battery_storage: technologies === 'battery_storage' ? 1 : 0,
                    energy_management: technologies === 'energy_management' ? 1 : 0,
                    photovoltaic: technologies === 'photovoltaic' ? 1 : 0,
                    manager: projectManager.first_name,
                    language: language,
                    column: orderBy.name,
                    orderby: orderBy.order,
                };
            }
            let { data } = await API(`${_userRole}/projects?` + qs.stringify(params), "get");
            setRecords(data);
            setIsLoading(false);
        } catch (error) {
            if (!!records) setRecords(null);
            setIsLoading(false);
        }
    };

    const handleChange = () => {
        setChecked(!checked);
    }

    const applyFilters = () => {
        setIsFilterApply(true);
        getData();
    };

    const clearFilters = () => {
        setIsFilterApply(false);
        if (search !== "") {
            setSearch("");
        }
        if (language !== "") {
            setLanguage("");
        }
        if (status !== '') {
            setStatus('');
        }
        if (technologies !== "") {
            setTechnologies("");
        }
        if (projectManager !== "") {
            setProjectManager("");
        }
        if (orderBy.name !== "created_at" && orderBy.order !== "desc") {
            setOrderBy({
                name: "created_at",
                order: "desc",
            });
        }
        setUpdateKey(generateKey());
        getData(true);
    };

    const myFilters = () => {
        return (
            <>
                <InputField
                    size="small"
                    fullWidth
                    label="Search"
                    handleChange={(e) => setSearch(e.target.value)}
                    initValue={search}
                />
                {_userRole === 'manager' &&
                    <SearchComboBox
                        label='Project Manager'
                        fullWidth
                        url={`manager/users?active=1&`}
                        objLabel="first_name"
                        size='small'
                        required
                        handleChange={(item) => setProjectManager(item)}
                        key={updateKey}
                    />
                }
                <SelectBox
                    items={techOptions}
                    label="Technologies"
                    size="small"
                    fullWidth
                    initValue={technologies}
                    handleChange={(e) => setTechnologies(e.target.value)}
                />
                <SelectBox
                    items={langOptions}
                    label="Language"
                    fullWidth
                    size="small"
                    initValue={language}
                    handleChange={(e) => setLanguage(e.target.value)}
                />
                <SelectBox
                    items={statusOpt}
                    label="Status"
                    size="small"
                    fullWidth
                    initValue={status}
                    handleChange={(e) => setStatus(e.target.value)}
                />
            </>
        );
    };

    const tableFilters = () => {
        return (
            <>
                <MultiSelect
                    columnsList={columns}
                    setColumnsList={(e) => {
                        setColumns(e);
                        setToggler(!toggler);
                    }}
                />
                <SelectBox
                    label="Shown"
                    size="small"
                    items={rowsOption}
                    fullWidth
                    handleChange={(e) => setPerPage(e.target.value)}
                    initValue={perPage}
                />
            </>
        )
    }

    const printTh = () => {
        return (
            <>
                <TableCell width="20px">
                </TableCell>
                {columns.map((v, _) => {
                    if (!v.show) return <></>
                    return (<Fragment key={v.id}>
                        {v.type === 'simple_label' &&
                            <TableCell>
                                {v.label}
                            </TableCell>
                        }
                        {v.type === 'order_label' &&
                            <TableCell>
                                <OrderDataBtn handleBtn={setOrderBy} selected={orderBy.name} value={v.value} title={v.label} />
                            </TableCell>
                        }
                        {v.type === 'switch_label' &&
                            <TableCell>
                                <Stack
                                    direction='row'
                                    alignItems='center'
                                >
                                    <span
                                        color='success'
                                        style={{
                                            cursor: 'pointer',
                                        }}
                                        onClick={handleChange}
                                    >
                                        {v.label}
                                    </span>
                                    <Switch
                                        checked={checked}
                                        color="success"
                                        onChange={handleChange}
                                        inputProps={{ 'aria-label': 'controlled' }}
                                    />
                                </Stack>
                            </TableCell>
                        }
                    </Fragment>);
                })}
                <TableCell>
                    Actions
                </TableCell>
            </>
        );
    };

    const usePaginate = (st = {}) => {
        return (
            records?.last_page > 1 && (
                <Box component="div" sx={st}>
                    <UsePagination
                        total={records?.total}
                        perPage={records?.per_page}
                        page={records?.current_page}
                        setPage={setPage}
                        key={records?.last_page}
                    />
                </Box>
            )
        )
    }

    const updateProjectStatus = async (e, id) => {
        try {
            await API(`${_userRole}/projects/${id}`, "post", { project_status: e.target.value, _method: 'patch' });
            setPayload({
                open: true,
                message: 'Project status updated has been updated',
                type: 'success'
            })
        } catch (error) {
            setPayload({
                open: true,
                message: 'Error occurred while updating project status.',
                type: 'error'
            })
        }
    }

    return (
        <>
            <Header hideMenu />
            <Container
                maxWidth="100%"
                sx={{ paddingTop: "15px", paddingBottom: "15px" }}
            >
                <FilterWrapper
                    isApplied={IsFilterApply}
                    filters={myFilters()}
                    btn={(search || projectManager || language || technologies || status) !== '' && (
                        <Button
                            variant="contained"
                            sx={{ borderRadius: "25px", textTransform: "none", width: { md: "fit-content", xs: "100%" } }}
                            onClick={applyFilters}
                        >
                            Filter
                        </Button>
                    )}
                    tableFilters={tableFilters()}
                    clearFilters={clearFilters}
                />
                <TableWrapper
                    tableStyle={{ minWidth: '1800px' }}
                    thContent={printTh()}
                    isLoading={isLoading}
                    isContent={records?.data.length}
                    spanTd={columns.length + 2}
                >
                    {records?.data.map((v, i) => {
                        let rowNum = (records?.current_page - 1) * records?.per_page + i + 1;
                        return (
                            <TableRow key={v.id}>
                                <TableCell>
                                    {rowNum}
                                </TableCell>
                                {columns[0].show &&
                                    <TableCell>
                                        {v?.reference || '-'}
                                    </TableCell>
                                }
                                {columns[1].show &&
                                    <TableCell>
                                        {v?.title || '-'}
                                    </TableCell>
                                }
                                {columns[2].show &&
                                    <TableCell>
                                        <Stack
                                            direction="row"
                                            spacing={1}
                                        >
                                            {v?.photovoltaic &&
                                                <Tooltip title='Photovoltaic' placement="top">
                                                    <div className="cube">
                                                        <img src={icon1} alt='' />
                                                    </div>
                                                </Tooltip>
                                            }
                                            {v?.battery_storage &&
                                                <Tooltip title='Battery Storage' placement="top">
                                                    <div className="cube">
                                                        <img src={icon2} alt='' />
                                                    </div>
                                                </Tooltip>
                                            }
                                            {v?.energy_management &&
                                                <Tooltip title='Energy Management' placement="top">
                                                    <div className="cube">
                                                        <img src={icon3} alt='' />
                                                    </div>
                                                </Tooltip>
                                            }
                                        </Stack>
                                    </TableCell>
                                }
                                {columns[3].show &&
                                    <TableCell />
                                }
                                {columns[4].show &&
                                    <TableCell>
                                        {v?.users?.first_name + ' ' + v?.users?.last_name}
                                    </TableCell>
                                }
                                {columns[5].show &&
                                    <TableCell>
                                        {v?.users?.first_name + ' ' + v?.users?.last_name}
                                    </TableCell>
                                }
                                {columns[6].show &&
                                    <TableCell
                                        sx={{
                                            maxWidth: "150px"
                                        }}
                                    >
                                        {v?.address}
                                    </TableCell>
                                }
                                {columns[7].show &&
                                    <TableCell>
                                        <SelectBox
                                            items={statusOpt}
                                            label="Status"
                                            size="small"
                                            initValue={v?.project_status}
                                            handleChange={(e) => updateProjectStatus(e, v.id)}
                                        />
                                    </TableCell>
                                }
                                {columns[8].show &&
                                    <TableCell>
                                        Loren Ipsum
                                    </TableCell>
                                }
                                {columns[9].show &&
                                    <TableCell>
                                        <Box>
                                            {moment(v?.updated_at).format('YYYY-MM-DD')}
                                            <br />
                                            <Typography
                                                variant='small'
                                                style={{
                                                    color: '#969696',
                                                    fontSize: '10px'
                                                }}
                                            >
                                                {moment(v?.updated_at).format('hh:mm A')}
                                            </Typography>
                                        </Box>
                                    </TableCell>
                                }
                                <TableCell>
                                    <Tooltip title='View' placement='top'>
                                        <IconButton
                                            color='info'
                                            onClick={() => {
                                                navigate(`/projects/${v.id}/overview`, { id: v.id })
                                            }}
                                        >
                                            <CreateIcon />
                                        </IconButton>
                                    </Tooltip>
                                </TableCell>
                            </TableRow>)
                    })}
                </TableWrapper>
                {usePaginate({ mt: 2 })}
            </Container>

            {/* add project */}
            {openModal ? (
                <CustomModal
                    width="700px"
                    children={
                        <ProjectForm
                            handleClose={() => setOpenModal(false)}
                            afterSubmit={item => {
                                getData();
                                setPayload(item);
                                setOpenModal(false);
                            }
                            }
                            setPayload={setPayload}
                        />}
                    handleClose={() => setOpenModal(false)}
                />
            ) : (
                <AddProjectBtn tooltip='Create a new Project' handleClick={() => setOpenModal(true)} />
            )}
            <PopUpMsg
                open={payload.open}
                type={payload.type}
                message={payload.message}
                handleClose={() => setPayload({ ...payload, open: false })}
            />
        </>
    )
}
