import React, { useState } from "react";
import {
  Box,
  Grid,
  Typography,
  Button,
  CircularProgress,
  Stack,
} from "@mui/material";

import API from "../../../../axios";
import {
  InputField,
  SelectBox,
} from "../../../../components";
import { useSelector } from "react-redux";
import { openPopUp } from "../../../../store/reducer";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { errorsSetter } from "../../../../helpers/errors-setter";

function GroupForm({ handleClose }) {
  const _user = useSelector((state) => state.storeReducer.user);
  const [isLoading, setIsLoading] = useState(false);
  const [formData, setFormData] = useState({
    organization_id: _user.organization_id
  });
  const [formErrors, setFormErrors] = useState({});
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const _userRole = useSelector((state) => state.storeReducer.user.role);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleForm = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    setFormErrors({});
    try {
      let { data } = await API(`${_userRole}/groups`, "post", formData);
      setIsLoading(false);
      dispatch(
        openPopUp({
          message: "Group created successfully.",
          type: "success",
        })
      );
      navigate(`/library/groups/${data.id}`, { id: data.id });
    } catch (error) {
      setIsLoading(false);
      setFormErrors(errorsSetter(error));
      dispatch(
        openPopUp({
          message: "Error Occurred. While creating group.",
          type: "error",
        })
      );
    }
  };
  return (
    <>
      <Box
        component="form"
        autoCapitalize="off"
        autoComplete="off"
        onSubmit={handleForm}
        sx={{
          pb: 4,
          px: 1,
        }}
      >
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Typography variant="h6" component="h5">
              ADD GROUP TYPE
            </Typography>
          </Grid>
          <Grid item md={12} xs={12}>
            <SelectBox
              items={techOptions}
              label="Group Type"
              size="small"
              fullWidth
              required
              handleChange={(item) => {
                setFormData({ ...formData, group_type: item.target.value });
              }}
              error={formErrors?.group_type}
            />
          </Grid>
          <Grid item md={12} xs={12}>
            <InputField
              size="small"
              fullWidth
              required
              label="Title"
              name="title"
              handleChange={handleChange}
              error={formErrors?.title}
            />
          </Grid>
          <Grid item md={12} xs={12}>
            <InputField
              size="small"
              fullWidth
              label="Description (optional)"
              name="description"
              multiline
              rows="4"
              handleChange={handleChange}
              error={formErrors?.description}
            />
          </Grid>

          <Grid item xs={12}>
            <Stack direction="row" spacing={1}>
              <Button
                variant="contained"
                onClick={() => { }}
                disabled={isLoading}
                type="submit"
              >
                {isLoading ? (
                  <CircularProgress
                    size={18}
                    sx={{ color: "#fff", mr: 1, size: "12px" }}
                  />
                ) : null}
                Add to
              </Button>
              <Button
                type="button"
                variant="contained"
                color="error"
                onClick={handleClose}
              >
                Abort
              </Button>
            </Stack>
          </Grid>
        </Grid>
      </Box>
    </>
  );
}
GroupForm.defaultProps = {
  handleClose: () => { },
};
export default GroupForm;
const techOptions = [
  { label: 'Battery Storage', value: 'battery_storage' },
  { label: 'Energy Management', value: 'energy_management' },
  { label: 'Photovoltaic', value: 'photovoltaic' }
];