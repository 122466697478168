import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { openPopUp } from "../../../store/reducer";

import {
  Box,
  Button,
  CircularProgress,
  Container,
  Typography,
} from "@mui/material";

import {
  CustomModal,
  InputField,
  SearchComboBox,
} from "../../../components";
import API from "../../../axios";
import { errorsSetter } from "../../../helpers/errors-setter";
import myTranslator from "../../../helpers/myTranslator";


export default function CreateManufacturer({
  handleClose,
  getData = () => { },
}) {
  const [isLoading, setIsLoading] = useState(false);
  const { selectedLang, translation } = useSelector((state) => state.storeReducer);  
  const [formData, setFormData] = useState({
    manufacturer: "",
    componentId: 0,
    component: "",
  });
  const [formErrors, setFormErrors] = React.useState({});

  const dispatch = useDispatch();
  useEffect(() => { }, []);

  const handleForm = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    setFormErrors({});
    try {
      await API(
        `admin/component-types/${formData.componentId}/manufacturers`,
        "post",
        formData
      );
      setIsLoading(false);
      let obj = {
        message: "Manufacturer has been created.",
        type: "success",
      };
      dispatch(openPopUp(obj));
      getData();
      handleClose(false);
    } catch (err) {
      if (err?.response?.status === 422) {
        setFormErrors(errorsSetter(err));
      } else {
        let obj = {
          message: err?.response?.message,
          type: "error",
        };
        dispatch(openPopUp(obj));
      }
      setIsLoading(false);
    }
  };

  return (
    <>
      <CustomModal
        handleClose={() => {
          handleClose();
        }}
      >
        <Container maxWidth="100%">
          <Box>
            <Typography component="h6" variant="h6" textAlign="">
             
             { myTranslator(translation,selectedLang,"sa_mnu_create_heading")}
            </Typography>
          </Box>
          
            <form autoComplete="off" onSubmit={handleForm}>
              <Box mt={2} mb={3}>
                <InputField
                  placeholder={myTranslator(translation,selectedLang,"sa_mnu_create_label_title")}
                  size="small"
                  label={myTranslator(translation,selectedLang,"sa_mnu_create_label_title")}
                  required
                  fullWidth
                  styles={{ mb: 2 }}
                  name="manufacturer"
                  error={formErrors?.manufacturer}
                  handleChange={(e) =>
                    setFormData({ ...formData, manufacturer: e.target.value })
                  }
                />
                <SearchComboBox
                  initValue={formData.component}
                  url={"admin/component-types?non_custom=1&"}
                  fullWidth
                  required={true}
                  objLabel="title"
                  size="small"
                  handleChange={(e) => {
                    setFormData({
                      ...formData,
                      component: e.title,
                      componentId: e.id,
                    });
                  }}
                  label={myTranslator(translation,selectedLang,"sa_mnu_create_label_component_type")}
                  styles={{ mb: 2 }}
                />
                <Box sx={{ textAlign: "end" }}>
                  <Button
                    onClick={() => handleClose(false)}
                    sx={{
                      mt: 2,
                      mr: 1,
                    }}
                    type="button"
                  >
                
                    {myTranslator(translation,selectedLang,"sa_mnu_create_btn_ cancel")}
                  </Button>
                  <Button
                    variant="contained"
                    onClick={() => { }}
                    sx={{
                      mt: 2,
                    }}
                    disabled={isLoading}
                    type="submit"
                  >
                    {isLoading ? (
                      <CircularProgress
                        size={18}
                        sx={{ color: "#fff", mr: 1, size: "12px" }}
                      />
                    ) : null}
                    {myTranslator(translation,selectedLang,"sa_mnu_create_btn_create")}
                  </Button>
                </Box>
              </Box>
            </form>
        </Container>
      </CustomModal>

    </>
  );
}
