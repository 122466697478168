import React, { useState } from "react";
import {
    Box,
    Stack,
    Divider,
    Button,
    Typography,
    Grid,
    TableCell,
    Paper,
    Tooltip,
    IconButton,
    FormGroup,
    FormControlLabel,
    Checkbox
} from "@mui/material";
import AddIcon from '@mui/icons-material/Add';
import ImageIcon from '@mui/icons-material/Image';
import KeyboardReturnRoundedIcon from '@mui/icons-material/KeyboardReturnRounded';

import { InputField, InfoHeading, MyLoader, SaveChangesBtn, StartIconBtn, TableWrapper, SelectBox } from "../../../../components";
import API from "../../../../axios";
import PriceTableRow from "./price-table-row";
import { useDispatch, useSelector } from "react-redux";
import { openPopUp } from "../../../../store/reducer";
import { useNavigate, useParams } from "react-router-dom";
import LibraryLayout from "../library-layout";
import ApiImage from "../../../../hooks/fetch-image";
import _ from "lodash";
import ComponentInfoCard from "./component-info-card";
import { errorsSetter } from "../../../../helpers/errors-setter";
import { icon1, icon2, icon3 } from "../../../../assets";

function EditComponentsPanel() {
    const [record, setRecord] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [showUpdateBtn, setShowUpdateBtn] = useState(false);
    const [toggler, setToggler] = useState(false);
    const [formData, setFormData] = useState({});
    const [pricesTable, setPricesTable] = useState([]);
    const [formErrors, setFormErrors] = useState({});
    const dispatch = useDispatch();
    const recordID = useParams();
    const navigate = useNavigate();
    const _userRole = useSelector((state) => state.storeReducer.user.role);

    React.useEffect(() => {
        getData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getData = async () => {
        setIsLoading(true);
        try {
            let { data } = await API(`${_userRole}/components/${recordID.id}`, 'get');
            setRecord(data);
            setFormData({
                name: data.name || '',
                item_number: data.item_number || '',
                description: data.description || '',
                battery_storage: data.battery_storage || '',
                energy_management: data.energy_management || '',
                photovoltaic: data.photovoltaic || '',
                price_dependency: data?.price_dependency || '',
                price_type: data.price_type || '',
                price_definition: data?.price_definition || '',
                price_repetition: data.price_repetition || '',
                tags: '',
            });
            setPricesTable(data?.componentprice || []);
            setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
        }
        return;
    }

    const handleSwitchChange = (e) => {
        const { name, checked } = e.target
        setFormData({ ...formData, [name]: checked });
        setRecord({ ...record, [name]: checked })
        setShowUpdateBtn(true);
    }

    const printTh = () => {
        return (
            <>
                <td></td>
                <TableCell>Price level</TableCell>
                <TableCell>Cost Price</TableCell>
                <TableCell>Calculation surcharge</TableCell>
                <TableCell>Installation costs</TableCell>
                <TableCell>Selling price</TableCell>
                <TableCell>Actions</TableCell>
            </>
        )
    }

    const removeRow = index => {
        let arr = pricesTable;
        arr.splice(index, 1);
        setPricesTable(arr);
        setToggler(!toggler);
    }

    const newRecord = () => {
        setPricesTable([...pricesTable, {
            price_level: '',
            cost_price: '',
            calculation_surcharge: '',
            installation_cost: '',
            selling_price: '',
            editable: true,
            id: Math.random() * 100000,
        }]);
    }

    const handleChange = e => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
        setShowUpdateBtn(true);
    }

    const updateComponent = async () => {
        setIsLoading(true);
        let _fd = {
            ...formData,
            battery_storage: formData.battery_storage ? '1' : '0',
            energy_management: formData.energy_management ? '1' : '0',
            photovoltaic: formData.photovoltaic ? '1' : '0',
            component_type_id: Number(record.component_type_id),
            organization_id: Number(record.organization_id),
            _method: 'patch'
        }
        setFormErrors({});
        try {
            let { data } = await API(`${_userRole}/components/${recordID.id}`, 'post', _fd);
            dispatch(openPopUp({ message: 'Component updated successfully', type: 'success' }))
            if (record.name !== data?.name) {
                setRecord({ ...record, name: data?.name });
            }
            setIsLoading(false);
            setShowUpdateBtn(false);
        } catch (error) {
            setIsLoading(false);
            setFormErrors(errorsSetter(error));
            dispatch(openPopUp({ message: 'Error occurred. While updating the component.', type: 'error' }))
        }
    }

    const updateStatus = async () => {
        setIsLoading(true);
        let _fd = {
            status: record?.active ? 0 : 1,
            component_type_id: Number(record.component_type_id),
            organization_id: Number(record.organization_id),
            _method: 'patch'
        }
        try {
            let { data } = await API(`${_userRole}/components/${recordID.id}`, 'post', _fd);
            dispatch(openPopUp({ message: 'Component status updated successfully', type: 'success' }))
            setRecord({ ...record, active: data?.active });
        } catch (error) {
            dispatch(openPopUp({ message: 'Error occurred. While updating the component.', type: 'error' }))
        } finally {
            setIsLoading(false);
        }
    }

    const printModals = (data = []) => {
        if (data.length === 0) return;
        let obj = _.groupBy(record?.manufacturer_modal?.modal?.information, "group");
        return Object.entries(obj).map((v, i) => {
            return <Grid item lg={3} md={4} sm={6} xs={12} key={i}>
                <Box component={Paper}
                    sx={{
                        p: 2,
                        height: '100%',
                    }}
                >
                    <ComponentInfoCard data={v} />
                </Box>
            </Grid>
        })

    }

    return (
        <LibraryLayout>
            {isLoading &&
                <MyLoader />
            }
            <Box sx={{ pt: 2, pb: 2 }}>
                <Box>
                    <Stack
                        direction="row"
                        alignItems='center'
                        divider={<Divider orientation="vertical" flexItem />}
                        spacing={2}
                    >
                        <Tooltip title='Back' placement="top">
                            <IconButton
                                onClick={() => navigate(-1)}
                            >
                                <KeyboardReturnRoundedIcon />
                            </IconButton>
                        </Tooltip>
                        <Typography
                            variant='p'
                            component='p'
                            sx={{ fontSize: '13px' }}
                        >
                            {record?.name ?? '-'}
                        </Typography>
                        <Button
                            variant='contained'
                            sx={{
                                textTransform: 'none',
                                opacity: record?.active ? 1 : 0.4
                            }}
                            onClick={updateStatus}
                        >
                            {record?.active ? 'Archive' : 'Restore'}
                        </Button>
                    </Stack>
                </Box>
                <Stack direction='row' spacing={1} mt={3} alignItems='center'>
                    <Typography
                        variant='h6'
                    >
                        {record?.componenttypes?.title}
                    </Typography>
                    {record?.photovoltaic &&
                        <Tooltip title='Photovoltaic' placement="top">
                            <div className="cube sm">
                                <img src={icon1} alt='' />
                            </div>
                        </Tooltip>
                    }
                    {record?.battery_storage &&
                        <Tooltip title='Battery Storage' placement="top">
                            <div className="cube sm">
                                <img src={icon2} alt='' />
                            </div>
                        </Tooltip>
                    }
                    {record?.energy_management &&
                        <Tooltip title='Energy Management' placement="top">
                            <div className="cube sm">
                                <img src={icon3} alt='' />
                            </div>
                        </Tooltip>
                    }
                </Stack>
                <Box sx={{ borderBottom: '1px solid #ccc', mt: 2 }}>
                    <Typography
                        variant='p'
                        component='p'
                        sx={{ borderBottom: '1px solid', pb: 1, borderColor: 'primary.main', width: 'fit-content' }}
                    >
                        DEUTCH (CH)
                    </Typography>
                </Box>
                <Grid container spacing={2} sx={{ mt: 3 }}>
                    <Grid item md={4} xs={12}>
                        <InputField
                            size='small'
                            label='Name'
                            name="name"
                            initValue={formData.name}
                            error={formErrors?.name}
                            fullWidth
                            handleChange={handleChange}
                        />
                        <Box sx={{ mt: 2 }}>
                            <InputField
                                size='small'
                                label='Item Number (optional)'
                                fullWidth
                                initValue={formData.item_number}
                                error={formErrors?.item_number}
                                name='item_number'
                                handleChange={handleChange}
                            />
                        </Box>
                    </Grid>
                    <Grid item md={4} xs={12}>
                        <InputField
                            placeholder='Description'
                            size='small'
                            label='Description'
                            fullWidth
                            multiline
                            initValue={formData.description}
                            rows={4}
                            name='description'
                            error={formErrors?.description}
                            handleChange={handleChange}
                        />
                    </Grid>
                    <Grid item md={4} xs={12}>
                        <InputField
                            placeholder='Manual input'
                            size='small'
                            label='Tags'
                            fullWidth
                            initValue={formData?.tags}
                            name='tags'
                            error={formErrors?.tags}
                            handleChange={handleChange}
                        />
                    </Grid>
                </Grid>
                {record?.is_custom &&
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Box mt={2} width="fit-content">
                                <InfoHeading variant='h6' text='Technology Affiliation' />
                                <FormGroup>
                                    <FormControlLabel control={<Checkbox color='success' checked={Boolean(formData.energy_management)} name='energy_management' onChange={handleSwitchChange} size='small' />} label="Energy Management" />
                                    <FormControlLabel control={<Checkbox color='success' checked={Boolean(formData.battery_storage)} name='battery_storage' onChange={handleSwitchChange} size='small' />} label="Battery Storage" />
                                    <FormControlLabel control={<Checkbox color='success' checked={Boolean(formData.photovoltaic)} name='photovoltaic' onChange={handleSwitchChange} size='small' />} label="Photovoltaic" />
                                </FormGroup>
                            </Box>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography
                                variant='h6'
                                component='h6'
                                sx={{ mt: 3 }}
                            >
                                PRICE PARAMETERS
                            </Typography>
                        </Grid>
                        <Grid item md={3} sm={6} xs={12}>
                            <InfoHeading text='Price dependency' containerSx={{ mb: 1 }} />
                            {(record?.energy_management || record?.battery_storage) ?
                                <SelectBox
                                    items={depOpt1}
                                    initValue={formData?.price_dependency || ''}
                                    error={formErrors?.price_dependency}
                                    fullWidth
                                    name="price_dependency"
                                    size='small'
                                    handleChange={handleChange}
                                />
                                :
                                <SelectBox
                                    items={depOpt2}
                                    initValue={formData?.price_dependency || ''}
                                    error={formErrors?.price_dependency}
                                    fullWidth
                                    name="price_dependency"
                                    size='small'
                                    handleChange={handleChange}
                                />
                            }
                        </Grid>
                        <Grid item md={3} sm={6} xs={12}>
                            <InfoHeading text='Price type' containerSx={{ mb: 1 }} />
                            <SelectBox
                                items={propOpt}
                                initValue={formData?.price_type || ''}
                                error={formErrors?.price_type}
                                fullWidth
                                name="price_type"
                                size='small'
                                handleChange={handleChange}
                            />
                        </Grid>
                        {record?.componenttypes?.cycling &&
                            <>
                                <Grid item md={3} sm={6} xs={12}>
                                    <InfoHeading text='Price definition' containerSx={{ mb: 1 }} />
                                    <SelectBox
                                        items={priceDefOpt}
                                        initValue={formData?.price_definition || ''}
                                        error={formErrors?.price_definition}
                                        fullWidth
                                        name="price_definition"
                                        size='small'
                                        handleChange={handleChange}
                                    />
                                </Grid>
                                <Grid item md={3} sm={6} xs={12}>
                                    <InfoHeading text='Price repetition' containerSx={{ mb: 1 }} />
                                    <SelectBox
                                        items={priceReqOpt}
                                        initValue={formData?.price_repetition || ''}
                                        error={formErrors?.price_repetition}
                                        fullWidth
                                        name="price_repetition"
                                        size='small'
                                        handleChange={handleChange}
                                    />
                                </Grid>
                            </>
                        }
                    </Grid>
                }
                <Typography
                    variant='h6'
                    component='h6'
                    sx={{ mt: 3 }}
                >
                    PRICE PARAMETERS (BATTERY CABINET) (EXCL. VAT)
                </Typography>
                <Box component='div' sx={{ mt: 3 }}>
                    <TableWrapper
                        thContent={printTh()}
                        isContent={pricesTable.length}
                        spanTd={7}
                    >
                        {pricesTable.map((v, i) => {
                            return <PriceTableRow
                                key={v.id}
                                item={v}
                                index={i}
                                typeID={record.component_type_id}
                                orgID={record.organization_id}
                                recID={record.id}
                                removeRow={removeRow} />;
                        })}
                    </TableWrapper>
                </Box>
                <StartIconBtn
                    title='Add price'
                    variant='contained'
                    size='small'
                    fullWidth
                    styles={{ maxWidth: '150px', mt: 4 }}
                    handleClick={newRecord}
                    icon={<AddIcon />} />
                {record?.manufacturer_modal &&
                    <Typography
                        variant='h6'
                        component='h6'
                        sx={{ mt: 3 }}
                    >
                        COMPONENT INFORMATION
                    </Typography>
                }
                {record?.manufacturer_modal &&
                    <Grid container spacing={2} sx={{ mt: 3, alignItems: 'stretch' }}>
                        <Grid item lg={3} md={4} sm={6} xs={12}>
                            <Box component={Paper}
                                sx={{
                                    height: '100%',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    p: 1
                                }}
                            >
                                <MyImage size='large' id={record?.manufacturer_modal?.modal?.id} />
                            </Box>
                        </Grid>
                        {record?.manufacturer_modal?.modal?.information.length ?
                            printModals(record?.manufacturer_modal?.modal?.information)
                            : null
                        }
                    </Grid>
                }
            </Box>
            {showUpdateBtn &&
                <SaveChangesBtn update={() => updateComponent()} cancel={() => setShowUpdateBtn(false)} />}
        </LibraryLayout>
    )
}

export default EditComponentsPanel;

const MyImage = ({ id }) => {
    const image = ApiImage(`modal-image/${id}`);
    return (
        <>
            {image ?
                <img
                    alt="..."
                    src={image}
                    width="100%"
                />
                :
                <ImageIcon />
            }
        </>
    );
};

const depOpt1 = [
    { label: 'Amp', value: 'ampere' },
    { label: 'Number of general counters (auto)', value: 'number_general_counter' },
    { label: 'Number of parties (auto)', value: 'number_of_party' },
    { label: 'Working hour, h', value: 'working_hour' },
    { label: 'Generator area, m² (auto)', value: 'generator_area' },
    { label: 'Weight, kg', value: 'weight' },
    { label: 'Cubic meter, m³', value: 'cubic_meter' },
    { label: 'Meter, m', value: 'meter' },
    { label: 'Power, kWp (auto)', value: 'power' },
    { label: 'Palette', value: 'palette' },
    { label: 'Lump sum', value: 'lump_sum' },
    { label: 'Per module (auto)', value: 'per_module' },
    { label: 'Square meter, m²', value: 'square_meter' },
    { label: 'Target price', value: 'target_price' },
    { label: 'Role', value: 'role' },
    { label: 'Piece', value: 'piece' }
]

const depOpt2 = [
    { label: 'Amp', value: 'ampere' },
    { label: 'Working hour, h', value: 'working_hour' },
    { label: 'Weight, kg', value: 'weight' },
    { label: 'Cubic meter, m³', value: 'cubic_meter' },
    { label: 'Meter, m', value: 'meter' },
    { label: 'Palette', value: 'palette' },
    { label: 'Lump sum', value: 'lump_sum' },
    { label: 'Square meter, m²', value: 'square_meter' },
    { label: 'Target price', value: 'target_price' },
    { label: 'Role', value: 'role' },
    { label: 'Plugged', value: 'plugged' }
]

const propOpt = [
    { label: 'Proportional', value: 'proportional' },
    { label: 'Lump sum', value: 'lump_sum' },
]

const priceDefOpt = [
    { label: 'normal(not in economy)', value: 'normal_not_in_economy' },
    { label: 'normal (in economy)', value: 'normal_in_economy' },
]

const priceReqOpt = [
    { label: 'Per monthly', value: 'monthly' },
    { label: 'Quarterly', value: 'quarterly' },
    { label: 'Yearly', value: 'yearly' },
]