import React, { useState } from 'react';
import {
    Box,
    FormGroup,
    FormControlLabel,
    Checkbox,
    Grid,
    Typography,
    Button,
    CircularProgress,
    Stack,
} from "@mui/material";

import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from 'react-router-dom';

import API from '../../../../axios';
import { InfoHeading, InputField } from "../../../../components";
import { openPopUp } from '../../../../store/reducer';
import { errorsSetter } from '../../../../helpers/errors-setter';

function TemplateForm({ handleClose }) {
    const [isLoading, setIsLoading] = useState(false);
    const [formData, setFormData] = useState({
        title: '',
        description: '',
        battery_storage: 0,
        energy_management: 0,
        photovoltaic: 0,
    });
    const [formErrors, setFormErrors] = useState({});
    const _userRole = useSelector((state) => state.storeReducer.user.role);

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const handleChange = (e) => {
        const { name, value } = e.target
        setFormData({ ...formData, [name]: value })
    }

    const handleSwitchChange = (e) => {
        const { name, checked } = e.target
        setFormData({ ...formData, [name]: checked })
    }

    const handleForm = async (e) => {
        e.preventDefault();
        setIsLoading(true);
        var _data = {};
        _data = {
            title: formData.title,
            description: formData.description ?? '',
            battery_storage: formData.battery_storage ? '1' : '0',
            energy_management: formData.energy_management ? '1' : '0',
            photovoltaic: formData.photovoltaic ? '1' : '0',
        }
        setFormErrors({});
        try {
            let { data } = await API(`${_userRole}/project-templates`, 'post', _data);
            dispatch(openPopUp({
                message: 'Project template has been created!',
                type: 'success'
            }));
            setIsLoading(false);
            navigate(`/library/project-template/${data.id}`, { id: data.id })
        } catch (error) {
            setIsLoading(false);
            setFormErrors(errorsSetter(error));
            dispatch(openPopUp({
                message: 'Error Occurred. While creating project template.',
                type: 'error'
            }));
        }
    }

    return (
        <>
            <Box
                component='form'
                autoCapitalize="off"
                autoComplete="off"
                onSubmit={handleForm}
                sx={{
                    pb: 4,
                    px: 1
                }}
            >
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <Typography variant="h6" component="h5">
                            Create a New Project Template
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <InputField
                            size="small"
                            fullWidth
                            required
                            labelTop='Project Template Name'
                            name='title'
                            error={formErrors?.title}
                            handleChange={handleChange}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <InputField
                            size="small"
                            fullWidth
                            labelTop='Description (optional)'
                            name='description'
                            multiline
                            rows="4"
                            error={formErrors?.description}
                            handleChange={handleChange}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <InfoHeading
                            text='Technology Affiliation'
                            variant='h6'
                        />
                        <FormGroup>
                            <FormControlLabel control={<Checkbox color='success' checked={Boolean(formData.energy_management)} name='energy_management' onChange={handleSwitchChange} size='small' />} label="Energy Management" />
                            <FormControlLabel control={<Checkbox color='success' checked={Boolean(formData.battery_storage)} name='battery_storage' onChange={handleSwitchChange} size='small' />} label="Battery Storage" />
                            <FormControlLabel control={<Checkbox color='success' checked={Boolean(formData.photovoltaic)} name='photovoltaic' onChange={handleSwitchChange} size='small' />} label="Photovoltaic" />
                        </FormGroup>
                    </Grid>
                    <Grid item xs={12}>
                        <Stack
                            direction='row'
                            spacing={1}
                        >
                            <Button
                                variant="contained"
                                onClick={() => { }}
                                disabled={isLoading}
                                type="submit"
                            >
                                {isLoading ? (
                                    <CircularProgress
                                        size={18}
                                        sx={{ color: "#fff", mr: 1, size: "12px" }}
                                    />
                                ) : null}
                                Add to
                            </Button>
                            <Button
                                type="button"
                                variant="contained"
                                color='error'
                                onClick={handleClose}
                            >
                                Abort
                            </Button>
                        </Stack>
                    </Grid>
                </Grid>
            </Box>
        </>
    );
}
TemplateForm.defaultProps = {
    handleClose: () => { }
}
export default TemplateForm;