import React from 'react'
import {
    Box,
    Container,
    Typography,
} from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';

const IndependenceAndConsumption = () => {
    const min535px = useMediaQuery('(min-width:33.4375rem)');
    return (
        <Container maxWidth="100%" sx={{ paddingTop: "25px", paddingBottom: "105px" }}>
            <Box sx={{ px: { sm: 4 } }}>
                <Box sx={{ display: 'flex', justifyContent: "space-between", alignItems: "center" }}>
                    <Typography component="h5" variant="h5" sx={{ fontSize: { xs: '1rem', sm: "1.35rem" }, fontWeight: 500 }}>UNABHÄNGIGKEIT & EIGENVERBRAUCH</Typography>
                    <img width={min535px ? "154" : "100"} src={require('../../../../../assets/images/logo.png')} alt="logo"></img>
                </Box>
                <Box component="div" sx={{ mt: 4 }}>
                    <Box sx={{ display: 'flex' }}>
                        <span style={styles.arrow}></span>
                        <Typography component="h6" variant="h6" sx={{ fontSize: { xs: '0.95rem', sm: "1.15rem" }, fontWeight: 500 }}>EIGENVERBRAUCH: WOHIN GEHT MEIN STROM?</Typography>
                    </Box>
                </Box>
                <Box sx={{ display: 'flex', flexDirection: { sm: "row", xs: "column" }, justifyContent: { xs: "center", sm: "flex-start" }, alignItems: 'center', mt: 6 }}>
                    <img style={{ width: '100%', maxWidth: '350px', borderRadius: '5px' }} src={require("../../../../../assets/images/placeholder.png")} alt="placeholder"></img>
                    <Box component='div' sx={{ ml: { xs: 0, sm: 3 } }}>
                        <Typography component="h6" sx={{ fontSize: '1.2rem', fontWeight: 500, mb: 1, textAlign: { sm: "left", xs: "center" }, mt: { xs: 3, sm: 0 } }}>Warum speise ich Strom ins Netz ein?</Typography>
                        <Typography component="p" sx={{ fontSize: '1.1rem', color: '#464646', width: '100%', maxWidth: "500px" }}>Sie können Ihren Solarstrom direkt verbrauchen oder in Ihrem Batteriespeicher zwischenspeichern. Überschüsse werden ins Stromnetz eingespeist.</Typography>
                    </Box>
                </Box>
                <Box component="div" sx={{ mt: 4 }}>
                    <Box sx={{ display: 'flex' }}>
                        <span style={styles.arrow}></span>
                        <Typography component="h6" variant="h6" sx={{ fontSize: { xs: '0.95rem', sm: "1.15rem" }, fontWeight: 500 }}>EIGENVERBRAUCH: WOHIN GEHT MEIN STROM?</Typography>
                    </Box>
                </Box>
                <Box sx={{ display: 'flex', flexDirection: { sm: "row", xs: "column" }, justifyContent: { xs: "center", sm: "flex-start" }, alignItems: 'center', mt: 6 }}>
                    <img style={{ width: '100%', maxWidth: '350px', borderRadius: '5px' }} src={require("../../../../../assets/images/placeholder.png")} alt="placeholder"></img>
                    <Box component='div' sx={{ ml: { xs: 0, sm: 3 } }}>
                        <Typography component="h6" sx={{ fontSize: '1.2rem', fontWeight: 500, mb: 1, textAlign: { sm: "left", xs: "center" }, mt: { xs: 3, sm: 0 } }}>Warum speise ich Strom ins Netz ein?</Typography>
                        <Typography component="p" sx={{ fontSize: '1.1rem', color: '#464646', width: '100%', maxWidth: "500px" }}>Sie können Ihren Solarstrom direkt verbrauchen oder in Ihrem Batteriespeicher zwischenspeichern. Überschüsse werden ins Stromnetz eingespeist.</Typography>
                    </Box>
                </Box>
                <Box component="div" sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', maxWidth: { sm: '40%' }, mt: 10 }}>
                    <Typography sx={{ fontSize: '0.875rem' }}>05.10.2022</Typography>
                    <Typography sx={{ fontSize: '0.875rem' }}>gam-448</Typography>
                </Box>
            </Box>

        </Container>
    )
}

const styles = {
    arrow: {
        width: 0,
        height: 0,
        display: 'inline-block',
        borderTop: '7px solid transparent',
        borderBottom: '7px solid transparent',
        borderLeft: '7px solid black',
        transform: 'rotate(221deg)',
        marginRight: '5px'
    }
}

export default IndependenceAndConsumption