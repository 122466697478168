import React, { useState, useEffect } from "react";

import {
  Container,
  Box,
  TableRow,
  TableCell,
  Typography,
  Chip,
  IconButton,
  Tooltip,
} from "@mui/material";
import ModeEditIcon from "@mui/icons-material/ModeEdit";

import CreateComponentType from "./create-power-consumption";
import UpdateComponentType from "./update-power-consumption";
import API from "../../../axios";
import {
  AddProjectBtn,
  TableWrapper,
  UsePagination,
} from "../../../components";
import { useSelector } from "react-redux";
import myTranslator from "../../../helpers/myTranslator";

var tableCols = [
  "sa_power_get_tb_name",
  "sa_power_get_tb_building_type",
  "sa_power_get_tb_heating_system",
  "sa_power_get_tb_water_system",
  "sa_power_get_tb_annual_consumption",
  "sa_power_get_tb_status",
  "sa_power_get_tb_action",
];

export default function GetPowerConsumption() {
  const [isLoading, setIsLoading] = useState(false);
  const [records, setRecords] = useState(null);
  const [page, setPage] = useState(1);
  const [id, setId] = useState(0);
  const [createModal, setCreateModal] = useState(false);
  const [updateModal, setUpdateModal] = useState(false);
  const { selectedLang, translation } = useSelector((state) => state.storeReducer);
  useEffect(() => {
    getPowerConsumption();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  const getPowerConsumption = async () => {
    setIsLoading(true);
    try {
      const { data } = await API(`admin/power-consumptions?page=${page}`, "get");
      setRecords(data);
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      setRecords(null);
    }
  };

  const usePaginate = () => {
    return (
      !!records && records?.last_page > 1 && (
        <Box component="div" sx={{ mt: 2 }}>
          <UsePagination
            total={records?.total}
            perPage={records?.per_page}
            page={page}
            setPage={setPage}
          />
        </Box>
      )
    )
  }

  return (
    <Container maxWidth="100%" sx={{ py: 3 }}>
      <Typography variant="h6" mb={2}>
       {myTranslator(translation, selectedLang, "sa_power_get_heading")}
      </Typography>
      <TableWrapper
        thContent={tableCols.map((column, index) => (
          <TableCell key={index}>{myTranslator(translation, selectedLang, column)}</TableCell>
        ))}
        spanTd={tableCols.length}
        isLoading={isLoading}
        isContent={Boolean(records?.length)}
        tableStyle={{ minWidth: "400px" }}
      >
        {records?.map((item, index) => (
          <TableRow key={index}>
            <TableCell>{item?.name}</TableCell>
            <TableCell sx={{ textTransform: "capitalize" }}>
              {item?.building_type.replace("_", " ")}
            </TableCell>
            <TableCell>{item?.heating_system}</TableCell>
            <TableCell>{item?.water_system}</TableCell>
            <TableCell>{item?.annual_consumption}</TableCell>
            <TableCell>
              <Chip
                label={item?.active ? "active" : "disabled"}
                color={item?.active ? "success" : "default"}
                size="small"
              />
            </TableCell>
            <TableCell>
              <Tooltip title={myTranslator(translation, selectedLang, "sa_power_get_tooltip_edit")} placement="top">
                <IconButton
                  onClick={() => {
                    setUpdateModal(true);
                    setId(item?.id);
                  }}
                  color="primary"
                >
                  <ModeEditIcon />
                </IconButton>
              </Tooltip>{" "}
            </TableCell>
          </TableRow>
        ))}
      </TableWrapper>
      {usePaginate()}
      {createModal ? (
        <CreateComponentType
          open={!!createModal}
          handleClose={() => setCreateModal(false)}
          getData={getPowerConsumption}
        />
      ) : (
        <AddProjectBtn
          handleClick={() => setCreateModal(true)}
          tooltip={myTranslator(translation, selectedLang, "sa_power_get_tooltip_cr_pow_con")}
        />
      )}
      {updateModal && (
        <UpdateComponentType
          open={!!updateModal}
          id={id}
          setId={setId}
          handleClose={() => setUpdateModal(false)}
          getData={getPowerConsumption}
        />
      )}
    </Container>
  );
}
