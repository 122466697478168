import React from 'react';
import {
    Box,
    Container,
    Tooltip,
    Typography,
    Stack,
    Button
} from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import { useNavigate } from 'react-router-dom';

const _url = '/projects/123456';

export default function ElectroFormSolar() {
    const navigate = useNavigate();
    return (
        <>
            <Container
                maxWidth="100%"
                sx={{ paddingTop: "15px", paddingBottom: "15px", position: 'relative' }}
            >
                <Box sx={{ pt: 2 }}>
                    <Typography
                        variant='h6'
                        component='h6'
                    >
                        ELECTROFORM SOLAR
                    </Typography>
                    <Typography
                        variant='p'
                        component='p'
                        color='#969696'
                        sx={{ mb: 2 }}
                    >
                        Connect Eturnity to ElektroForm solar and get an overview of the administrative progress of your project.
                    </Typography>
                </Box>
                <Stack
                    direction="row"
                    spacing={1}
                    sx={boxStyle}
                >
                    <Tooltip>
                        <InfoIcon sx={{ opacity: 0.4 }} />
                    </Tooltip>
                    <Typography
                        variant="p"
                        component="p"
                        color='#969696'
                    >
                        The ElektroForm solar connection has been activated for your company. Please log in with your personal Brunner Informatik Cloud ID. Eturnity does not save your login data, only a token for the connection
                    </Typography>
                </Stack>
                <Box
                    mt={2}
                >
                    <Button
                        variant='contained'
                        sx={{
                            textTransform: 'none',
                            backgroundColor: '#000'
                        }}
                        onClick={() => navigate(_url + '/steps')}
                    >
                        Register with EF solar
                    </Button>
                </Box>
            </Container>
        </>
    )
}


const boxStyle = {
    py: 5,
    px: 2,
    mt: 3,
    maxWidth: '600px',
    borderWidth: 2,
    borderStyle: 'dashed',
    borderRadius: 2,
    borderColor: '#ABABAB',
}