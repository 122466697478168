import React, { useState } from "react";
import {
    Box,
    Tab,
    Tabs,
    Typography,
} from "@mui/material";

import TypeDefinitionPanel from "./tabs-panel/type-definition-panel";
import ElectronicSigPanel from "./tabs-panel/electronic-sig-panel";
import WorkFlowTempaltePanel from "./tabs-panel/workflow-template-panel";

export default function IdeasTabs() {
    const [activeTab, setActiveTab] = useState('WORKFLOW_TEMPLATE');

    const handleTabs = (event, newValue) => {
        setActiveTab(newValue);
    };

    return (
        <>
            <Typography variant="h6">
                IDEAS
            </Typography>
            <Typography variant="p">
                Under "Settings" you can manage your freely definable technologies, workflows and activities.
            </Typography>
            <Box sx={{ width: '100%', mt: 3, borderBottom: '1px solid #ccc' }}>
                <Tabs
                    value={activeTab}
                    onChange={handleTabs}
                    textColor="primary"
                    indicatorColor="primary"
                    aria-label="secondary tabs example"
                    sx={{ '& .MuiTabs-flexContainer': { overflowX: 'auto' } }}
                >
                    <Tab value="WORKFLOW_TEMPLATE" label="WORKFLOW TEMPLATE" sx={{ textTransform: 'none' }} />
                    <Tab value="ACTIVITY_TYPE_DEFINITION" label="ACTIVITY TYPE DEFINITION" sx={{ textTransform: 'none' }} />
                    <Tab value="ELECTRONIC_SIGNATURE" label="ELECTRONIC SIGNATURE" sx={{ textTransform: 'none' }} />
                </Tabs>
            </Box>
            <Box sx={{ display: activeTab === 'WORKFLOW_TEMPLATE' ? 'block' : 'none' }} >
                <WorkFlowTempaltePanel />
            </Box>
            <Box sx={{ display: activeTab === 'ACTIVITY_TYPE_DEFINITION' ? 'block' : 'none' }} >
                <TypeDefinitionPanel />
            </Box>
            <Box sx={{ display: activeTab === 'ELECTRONIC_SIGNATURE' ? 'block' : 'none' }} >
                <ElectronicSigPanel />
            </Box>
        </>
    )
}