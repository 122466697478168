import React from "react";
import {
    Box,
} from "@mui/material";

import IdeasTabs from "../ideas-tabs";

export default function EditRequirementsPanel({ switchScreen, ...props }) {

    return (
        <Box>
            <IdeasTabs />
        </Box>
    )
}