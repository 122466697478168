import React, { useState } from "react";
import {
  Box,
  Stack,
  Divider,
  Button,
  Typography,
  TableRow,
  TableCell,
  Tooltip,
  IconButton,
  ClickAwayListener,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import KeyboardReturnRoundedIcon from '@mui/icons-material/KeyboardReturnRounded';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';

import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

import {
  InputField,
  MyLoader,
  SaveChangesBtn,
  SearchComboBox,
  StartIconBtn,
  TableWrapper,
} from "../../../../components";
import API from "../../../../axios";
import { openPopUp } from "../../../../store/reducer";
import LibraryLayout from "../library-layout";
import { errorsSetter } from "../../../../helpers/errors-setter";

export default function EditGroupPanel() {
  const [record, setRecord] = useState(null);
  const [showUpdateBtn, setShowUpdateBtn] = useState(false);
  const [formData, setFormData] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [addComponent, setAddComponent] = useState(false);
  const [toggler, setToggler] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [removedComponents, setRemovedComponents] = useState([]);
  const [newAddedComponents, setNewAddedComponents] = useState([]);
  const [formErrors, setFormErrors] = useState({});
  const _userRole = useSelector((state) => state.storeReducer.user.role);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id } = useParams();

  React.useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getData = async () => {
    setIsLoading(true);
    try {
      let { data } = await API(`${_userRole}/groups/${id}`, "get");
      setRecord(data);
      setFormData({
        title: data.title,
        description: data.description,
      });
      setTableData(data.groupcomponent)
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
      dispatch(
        openPopUp({
          message: "Something went wrong!",
          type: "error",
        })
      );
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    setShowUpdateBtn(true);
  };

  const handleForm = async () => {
    setIsLoading(true);
    let _fd = {
      ...formData,
      components: newAddedComponents,
      group_id: id,
      delete_components: removedComponents,
      _method: "patch"
    };
    setFormErrors({});
    try {
      await API(`${_userRole}/groups/${id}`, "post", _fd);
      setIsLoading(false);
      dispatch(
        openPopUp({
          message: "Group has been updated.",
          type: "success",
        })
      );
      setNewAddedComponents([]);
      setRemovedComponents([]);
      setShowUpdateBtn(false);
    } catch (error) {
      setIsLoading(false);
      setFormErrors(errorsSetter(error));
      dispatch(
        openPopUp({
          message: "Error Occurred. While updating group.",
          type: "error",
        })
      );
    }
  };

  const addRow = (item) => {
    let _index = tableData.findIndex(x => x.components.id === item.id);
    if (_index !== -1) {
      dispatch(
        openPopUp({
          message: "Duplicate components are not allowed.",
          type: "warning",
        })
      );
      return
    }
    setTableData([...tableData, { components: item }]);
    setNewAddedComponents([...newAddedComponents, item.id]);
    setShowUpdateBtn(true);
    setAddComponent(!addComponent);
  }

  const removeRow = (item, index) => {
    let arr = tableData;
    arr.splice(index, 1);
    setTableData(arr);
    let arr2 = newAddedComponents;
    let _index = arr2.findIndex(x => x === item.id);
    if (_index < 0) {
      setShowUpdateBtn(true);
      setRemovedComponents([...removedComponents, item.components.id]);
      return;
    } else {
      arr2.splice(_index, 1);
      setNewAddedComponents(arr2);
      setToggler(!toggler);
    }
  };

  return (
    <LibraryLayout>
      <Box sx={{ pt: 2, pb: 2 }}>
        <Box>
          <Stack
            direction="row"
            alignItems='center'
            divider={<Divider orientation="vertical" flexItem />}
            spacing={2}
          >
            <Tooltip title='Back' placement="top">
              <IconButton
                onClick={() => navigate(-1)}
              >
                <KeyboardReturnRoundedIcon />
              </IconButton>
            </Tooltip>
            <Typography
              variant='p'
              component='p'
              sx={{ fontSize: '13px' }}
            >
              {record?.title ?? '-'}
            </Typography>
            <Button
              variant='contained'
              sx={{
                textTransform: 'none'
              }}
            >
              Archive
            </Button>
          </Stack>
        </Box>
        <Typography variant="h6" component="h6" sx={{ mt: 3 }}>
          {record?.group_type === 'energy_management' ?
            'Energy Management' :
            record?.group_type === 'battery_storage' ?
              'Battery Storage' :
              record?.group_type === 'photovoltaic' ? 'Photovoltaic' : record?.group_type
          }
        </Typography>
        <Box sx={{ borderBottom: "1px solid #ccc", mt: 2 }}>
          <Typography
            variant="p"
            component="p"
            sx={{
              borderBottom: "1px solid",
              pb: 1,
              borderColor: "primary.main",
              width: "fit-content",
            }}
          >
            DEUTCH (CH)
          </Typography>
        </Box>
        <Box sx={{ maxWidth: 400, mt: 3 }}>
          <InputField
            size="small"
            label="Title"
            name="title"
            initValue={formData.title}
            error={formErrors.title}
            fullWidth
            handleChange={handleChange}
          />
          <br />
          <InputField
            placeholder="Description"
            size="small"
            label="Description"
            fullWidth
            multiline
            initValue={formData.description}
            error={formErrors.description}
            rows={4}
            name="description"
            handleChange={handleChange}
          />
        </Box>
        <Typography variant="h6" component="h6" sx={{ mt: 3 }}>
          OTHER COMPONENTS
        </Typography>
        <Box component="div" sx={{ mt: 3 }}>
          <TableWrapper
            thContent={
              <>
                <TableCell>Component</TableCell>
                <TableCell>Item Number</TableCell>
                <TableCell>Subsidies</TableCell>
                <TableCell>Actions</TableCell>
              </>
            }
            spanTd="4"
            isContent={tableData.length}
          >
            {tableData?.map((v, i) => {
              return (
                <TableRow key={i}>
                  <TableCell>{v?.name || v?.components?.name || '-'}</TableCell>
                  <TableCell>{v?.item_number || v?.components?.item_number || '-'}</TableCell>
                  <TableCell>-</TableCell>

                  <TableCell>
                    <Tooltip title='Delete'>
                      <IconButton
                        onClick={() => removeRow(v, i)}
                        color='error'
                        disabled={isLoading}
                      >
                        <DeleteForeverIcon />
                      </IconButton>
                    </Tooltip>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableWrapper>
        </Box>
        <Stack
          spacing={2}
          direction={{ md: 'row', xs: 'column' }}
          mt={4}
        >
          <StartIconBtn
            title="Add Component"
            variant="contained"
            size="small"
            fullWidth
            styles={{ maxWidth: "150px" }}
            handleClick={() => setAddComponent(true)}
            icon={<AddIcon />}
          />
          {addComponent &&
            <ClickAwayListener onClickAway={() => setAddComponent(false)}>
              <Box>
                <SearchComboBox
                  url={`${_userRole}/components?active=1&column=created_at&orderby=desc&`}
                  objLabel="name"
                  label='Components'
                  size="small"
                  placement='top'
                  handleChange={addRow}
                />
              </Box>
            </ClickAwayListener>
          }
        </Stack>
      </Box>

      {showUpdateBtn && (
        <SaveChangesBtn
          update={handleForm}
          cancel={() => setShowUpdateBtn(false)}
        />
      )}
      {isLoading && <MyLoader />}
    </LibraryLayout>
  );
}

