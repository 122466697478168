import React, { useState } from "react";
import {
    Box,
    Container,
    Typography,
    Button,
    TableContainer,
    Paper,
    Table,
    TableRow,
    TableCell,
    TableHead,
    TableBody,
    Popper,
    Grow,
    ClickAwayListener,
    MenuList,
    MenuItem,
    Divider,
    useMediaQuery,
    Modal,
    IconButton,
    Grid,
    TextField,
    LinearProgress
} from "@mui/material";
import PageTitle from "../../../hooks/page-title";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import CloseIcon from "@mui/icons-material/Close";
import { Stack } from "@mui/system";
import { Header, StartIconBtn } from "../../../components";

export default function Activities() {
    PageTitle('Activities');
    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    return (
        <>
        <Header hideMenu/>
            <Container
                maxWidth="100%"
                sx={{ paddingTop: "15px", paddingBottom: "15px" }}
            >
                <Stack
                    direction='row'
                    alignItems='center'
                    sx={{
                        flexWrap: "wrap",
                        marginY: 3,
                        '& > button': {
                            mt: '10px !important'
                        }
                    }}
                    spacing={2}
                >
                    <StartIconBtn title='Optionale Hauptkomponente hinzufügen' />
                    <StartIconBtn title='Aus Gruppe hinzufügen' />
                    <StartIconBtn title='Andere Komponente hinzufügen' />
                    <StartIconBtn title='Sammelposition hinzufügen' />
                </Stack>
                <Box>
                    <TableContainer
                        component={Paper}
                        sx={{
                            borderRadius: 1,
                            boxShadow: 'none'
                        }}
                    >
                        <Table sx={{
                            minWidth: 650,
                            '& .MuiTableCell-root': {
                                paddingTop: '5px',
                                paddingBottom: '5px',
                            }
                        }}>
                            <TableHead>
                                <TableRow
                                    sx={{
                                        backgroundColor: "#E6F5FE",
                                    }}
                                >
                                    <TableCell
                                        sx={{
                                            '& .MuiTableCell-root': {
                                                paddingY: '12px'
                                            }
                                        }}
                                        align="left">Komponent</TableCell>
                                    <TableCell
                                        sx={{
                                            '& .MuiTableCell-root': {
                                                paddingY: '12px'
                                            }
                                        }}
                                        align="center">Artikelnummer</TableCell>
                                    <TableCell
                                        sx={{
                                            '& .MuiTableCell-root': {
                                                paddingY: '12px'
                                            }
                                        }}
                                        align="center">Menge</TableCell>
                                    <TableCell
                                        sx={{
                                            '& .MuiTableCell-root': {
                                                paddingY: '12px'
                                            }
                                        }}
                                        align="center">Einheit</TableCell>
                                    <TableCell
                                        sx={{
                                            '& .MuiTableCell-root': {
                                                paddingY: '12px'
                                            }
                                        }}
                                        align="center"></TableCell>
                                    <TableCell
                                        sx={{
                                            '& .MuiTableCell-root': {
                                                paddingY: '12px'
                                            }
                                        }}
                                        align="center">Einheitspreis</TableCell>
                                    <TableCell
                                        sx={{
                                            '& .MuiTableCell-root': {
                                                paddingY: '12px'
                                            }
                                        }}
                                        align="center">Rabatt</TableCell>
                                    <TableCell
                                        sx={{
                                            '& .MuiTableCell-root': {
                                                paddingY: '12px'
                                            }
                                        }}
                                        align="center">Preis</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <TableRow
                                    sx={{
                                        backgroundColor: "#F9FDFF",
                                    }}
                                >
                                    <TableCell
                                        sx={{
                                            '& .MuiTableCell-root': {
                                                paddingY: '12px'
                                            }
                                        }}
                                        align="left">
                                        <Typography variant="subtitle2" component="p">
                                            PV-Model
                                        </Typography>
                                    </TableCell>
                                    <TableCell
                                        sx={{
                                            '& .MuiTableCell-root': {
                                                paddingY: '12px'
                                            }
                                        }}
                                    />
                                    <TableCell
                                        sx={{
                                            '& .MuiTableCell-root': {
                                                paddingY: '12px'
                                            }
                                        }}
                                    />
                                    <TableCell
                                        sx={{
                                            '& .MuiTableCell-root': {
                                                paddingY: '12px'
                                            }
                                        }}
                                    />
                                    <TableCell
                                        sx={{
                                            '& .MuiTableCell-root': {
                                                paddingY: '12px'
                                            }
                                        }}
                                    />
                                    <TableCell
                                        sx={{
                                            '& .MuiTableCell-root': {
                                                paddingY: '12px'
                                            }
                                        }}
                                    />
                                    <TableCell
                                        sx={{
                                            '& .MuiTableCell-root': {
                                                paddingY: '12px'
                                            }
                                        }}
                                    />
                                    <TableCell
                                        sx={{
                                            '& .MuiTableCell-root': {
                                                paddingY: '12px'
                                            }
                                        }}
                                        align="center">
                                        <Typography variant="subtitle2" component="p">
                                            6’250.00 CHF
                                        </Typography>
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell
                                        sx={{
                                            '& .MuiTableCell-root': {
                                                paddingY: '12px'
                                            }
                                        }}
                                        align="left">
                                        Solyco - R-BF 108p.3/400
                                    </TableCell>
                                    <TableCell
                                        sx={{
                                            '& .MuiTableCell-root': {
                                                paddingY: '12px'
                                            }
                                        }}
                                        align="center">51</TableCell>
                                    <TableCell
                                        sx={{
                                            '& .MuiTableCell-root': {
                                                paddingY: '12px'
                                            }
                                        }}
                                        align="center">25.00</TableCell>
                                    <TableCell
                                        sx={{
                                            '& .MuiTableCell-root': {
                                                paddingY: '12px'
                                            }
                                        }}
                                        align="center">St.</TableCell>
                                    <TableCell
                                        sx={{
                                            '& .MuiTableCell-root': {
                                                paddingY: '12px'
                                            }
                                        }}
                                    />
                                    <TableCell
                                        sx={{
                                            '& .MuiTableCell-root': {
                                                paddingY: '12px'
                                            }
                                        }}
                                        align="center">250.00 CHF</TableCell>
                                    <TableCell
                                        sx={{
                                            '& .MuiTableCell-root': {
                                                paddingY: '12px'
                                            }
                                        }}
                                        align="center">0.00%</TableCell>
                                    <TableCell
                                        sx={{
                                            '& .MuiTableCell-root': {
                                                paddingY: '12px'
                                            }
                                        }}
                                        align="center">
                                        <DropDown />
                                    </TableCell>
                                </TableRow>
                                <TableRow
                                    sx={{
                                        backgroundColor: "#F9FDFF",
                                    }}
                                >
                                    <TableCell
                                        sx={{
                                            '& .MuiTableCell-root': {
                                                paddingY: '12px'
                                            }
                                        }}
                                        align="left">
                                        <Typography variant="subtitle2" component="p">
                                            Wechselrichter
                                        </Typography>
                                    </TableCell>
                                    <TableCell
                                        sx={{
                                            '& .MuiTableCell-root': {
                                                paddingY: '12px'
                                            }
                                        }}
                                    />
                                    <TableCell
                                        sx={{
                                            '& .MuiTableCell-root': {
                                                paddingY: '12px'
                                            }
                                        }}
                                    />
                                    <TableCell
                                        sx={{
                                            '& .MuiTableCell-root': {
                                                paddingY: '12px'
                                            }
                                        }}
                                    />
                                    <TableCell
                                        sx={{
                                            '& .MuiTableCell-root': {
                                                paddingY: '12px'
                                            }
                                        }}
                                    />
                                    <TableCell
                                        sx={{
                                            '& .MuiTableCell-root': {
                                                paddingY: '12px'
                                            }
                                        }}
                                    />
                                    <TableCell
                                        sx={{
                                            '& .MuiTableCell-root': {
                                                paddingY: '12px'
                                            }
                                        }}
                                    />
                                    <TableCell
                                        sx={{
                                            '& .MuiTableCell-root': {
                                                paddingY: '12px'
                                            }
                                        }}
                                        align="center">
                                        <Typography variant="subtitle2" component="p">
                                            1’700.00 CHF
                                        </Typography>
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Box>
                <Stack
                    direction='row'
                    alignItems='center'
                    sx={{
                        flexWrap: "wrap",
                        marginY: 3,
                        '& > button': {
                            mt: '10px !important'
                        }
                    }}
                    spacing={2}
                >
                    <StartIconBtn title='Optionale Hauptkomponente hinzufügen' />
                    <StartIconBtn title='Aus Gruppe hinzufügen' />
                    <StartIconBtn title='Andere Komponente hinzufügen' />
                    <StartIconBtn title='Sammelposition hinzufügen' />
                </Stack>
                <Divider sx={{ borderWidth: '1px', borderColor: '#969696', opacity: 0.6 }} />
                <Typography
                    variant="h5"
                    component="h5"
                    fontSize={"18px"}
                    mt={"40px"}
                    mb={"10px"}
                >
                    RABATTE
                </Typography>
                <Box
                    component="div"
                    sx={{
                        maxWidth: "750px",
                        mt: "20px",
                        mb: "20px",
                    }}
                >
                    <TableContainer
                        sx={{
                            borderRadius: 1,
                        }}
                    >
                        <Table sx={{
                            minWidth: 650,
                            '& .MuiTableCell-root': {
                                paddingTop: '5px',
                                paddingBottom: '5px',
                            }
                        }}>
                            <TableHead>
                                <TableRow
                                    sx={{
                                        backgroundColor: "#E6F5FE",
                                    }}
                                >
                                    <TableCell
                                        sx={{
                                            '& .MuiTableCell-root': {
                                                paddingY: '12px'
                                            }
                                        }}
                                        align="left">Name</TableCell>
                                    <TableCell
                                        sx={{
                                            '& .MuiTableCell-root': {
                                                paddingY: '12px'
                                            }
                                        }}
                                        align="center">Typ</TableCell>
                                    <TableCell
                                        sx={{
                                            '& .MuiTableCell-root': {
                                                paddingY: '12px'
                                            }
                                        }}
                                        align="center">Rabatt</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <TableRow
                                    sx={{
                                        backgroundColor: "#F9FDFF",
                                    }}
                                >
                                    <TableCell
                                        sx={{
                                            '& .MuiTableCell-root': {
                                                paddingY: '12px'
                                            }
                                        }}
                                        align="left">
                                        <Typography variant="subtitle2" component="p">
                                            dsfghhdfghdfghdf
                                        </Typography>
                                    </TableCell>
                                    <TableCell
                                        sx={{
                                            '& .MuiTableCell-root': {
                                                paddingY: '12px'
                                            }
                                        }}
                                        align="center">
                                        <Typography variant="subtitle2" component="p">
                                            pauschal
                                        </Typography>
                                    </TableCell>
                                    <TableCell
                                        sx={{
                                            '& .MuiTableCell-root': {
                                                paddingY: '12px'
                                            }
                                        }}
                                        align="center">
                                        <Typography variant="subtitle2" component="p">
                                            8555.00 CHF
                                        </Typography>
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Box>
                <Box
                    sx={{ paddingY: 2 }}
                >
                    <StartIconBtn title='Sammelposition hinzufügen' handleClick={handleOpen} />
                </Box>
            </Container>
            <BasicModal open={!!open} handleClose={handleClose} />
        </>
    )
}

const BasicModal = ({ open, handleClose }) => {

    const match = useMediaQuery('(max-width:767px')
    const [counter, setCounter] = useState(1);
    const [progress, setProgress] = useState(0);
    const [startPrg, setStartPrg] = useState(false);

    React.useEffect(() => {
        if (!startPrg) return;
        const timer = setInterval(() => {
            setProgress((oldProgress) => {
                if (oldProgress === 100) {
                    handleClose();
                    setProgress(0);
                    setCounter(1);
                    setStartPrg(false);
                }
                const diff = Math.random() * 10;
                return Math.min(oldProgress + diff, 100);
            });
        }, 1000);

        return () => {
            clearInterval(timer);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [startPrg]);

    return (
        <Modal
            open={!!open}
            onClose={() => {
                handleClose();
            }}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            sx={{
                overflowY: 'auto',
            }}
        >
            <Box sx={[modalStyle, { padding: match ? 2 : 4 }]}>
                <Box
                    component='form'
                    noValidate
                    autoComplete="off"
                >
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'flex-end',
                        }}
                    >
                        <IconButton
                            sx={{ color: 'red' }}
                            onClick={() => {
                                handleClose();
                            }}
                        >
                            <CloseIcon />
                        </IconButton>
                    </Box>
                    {
                        counter === 1 ?
                            <>
                                <Typography variant="h6" component="h5">
                                    GRUPPE AUSWÄHLEN
                                </Typography>
                                <Typography
                                    variant="p"
                                    component="p"
                                    sx={{
                                        color: "#969696",
                                    }}
                                >
                                    Die Komponenten und wiederkehrende Kosten der von Ihnen ausgewählten Gruppe werden der Stückliste hinzugefügt.
                                </Typography>
                                <Typography variant="h6" component="h5"
                                    mt={2}
                                    sx={{
                                        color: '#000'
                                    }}
                                >
                                    GRUPPE
                                </Typography>
                                <Grid container>
                                    <Grid item md={6} xs={12}>
                                        <TextField
                                            id="outlined-select-currency-native"
                                            select
                                            SelectProps={{
                                                native: true,
                                            }}
                                            sx={inputStyle}
                                        >
                                            {['PV-Anlage Standard', 'PV-Anlage Basic'].map((v, i) => (
                                                <option key={i} value={v}>
                                                    {v}
                                                </option>
                                            ))}
                                        </TextField>
                                    </Grid>
                                </Grid>
                                <Stack
                                    direction='row'
                                    spacing={1}
                                    mt={7}
                                >
                                    <Button
                                        type="submit"
                                        onClick={() => setCounter(2)}
                                        sx={{
                                            backgroundColor: '#000',
                                            color: '#fff',
                                            textTransform: 'none',
                                            paddingLeft: '25px',
                                            paddingRight: '25px',
                                            minWidth: 150,
                                            '&:hover': {
                                                backgroundColor: '#000',
                                            }
                                        }}
                                    >
                                        OK
                                    </Button>
                                    <Button
                                        type="button"
                                        sx={{
                                            backgroundColor: '#D71B1B',
                                            color: '#fff',
                                            textTransform: 'none',
                                            paddingLeft: '25px',
                                            paddingRight: '25px',
                                            minWidth: 150,
                                            '&:hover': {
                                                backgroundColor: '#D71B1B',
                                            }
                                        }}
                                        onClick={() => {
                                            handleClose();
                                        }}
                                    >
                                        Abbrechen
                                    </Button>
                                </Stack>
                            </>
                            :
                            <>
                                <Typography variant="h6" component="h5">
                                    SIMULATION AKTUALISIEREN
                                </Typography>
                                <Typography
                                    variant="p"
                                    component="p"
                                    sx={{
                                        color: "#969696",
                                    }}
                                >
                                    Ihre Simulationsdaten sind nicht aktuell
                                </Typography>
                                {
                                    counter === 2 ?
                                        <Button
                                            type="submit"
                                            onClick={() => {
                                                setStartPrg(true);
                                                setCounter(3);
                                            }}
                                            sx={{
                                                backgroundColor: '#000',
                                                color: '#fff',
                                                textTransform: 'none',
                                                paddingLeft: '25px',
                                                paddingRight: '25px',
                                                minWidth: 150,
                                                marginTop: 2,
                                                '&:hover': {
                                                    backgroundColor: '#000',
                                                }
                                            }}
                                        >
                                            Simulation Aktualisieren
                                        </Button>
                                        :
                                        <>
                                            <Box sx={{ width: '100%', marginY: 3 }}>
                                                <LinearProgress
                                                    variant="determinate"
                                                    value={progress} color="success"
                                                    sx={progressStyle}
                                                />
                                            </Box>
                                            <Typography
                                                variant="span"
                                                component="span"
                                                sx={{
                                                    color: "#969696",
                                                    marginBottom: '12px',
                                                }}
                                            >
                                                Die Simulationsresulfate werden aktualisiert...
                                            </Typography>
                                        </>
                                }

                            </>
                    }

                </Box>
            </Box>
        </Modal >
    )
}

const DropDown = () => {
    const [open, setOpen] = React.useState(false);
    const anchorRef = React.useRef(null);

    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };

    const handleClose = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }

        setOpen(false);
    };

    function handleListKeyDown(event) {
        if (event.key === "Tab") {
            event.preventDefault();
            setOpen(false);
        } else if (event.key === "Escape") {
            setOpen(false);
        }
    }

    const prevOpen = React.useRef(open);
    React.useEffect(() => {
        if (prevOpen.current === true && open === false) {
            anchorRef.current.focus();
        }
        prevOpen.current = open;
    }, [open]);

    return (
        <>
            <Button
                variant="text"
                aria-controls={open ? "composition-menu" : undefined}
                aria-expanded={open ? "true" : undefined}
                aria-haspopup="true"
                disableElevation
                onClick={handleToggle}
                ref={anchorRef}
                endIcon={open ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
                sx={{
                    color: "#000",
                    textTransform: "none",
                    fontWeight: "400",
                    paddingY: '0px'
                }}
            >
                1’700.00 CHF
            </Button>
            <Popper
                open={!!open}
                anchorEl={anchorRef.current}
                role={undefined}
                placement="bottom-start"
                transition
                disablePortal
                sx={{
                    zIndex: 1,
                }}
            >
                {({ TransitionProps, placement }) => (
                    <Grow
                        {...TransitionProps}
                        style={{
                            transformOrigin:
                                placement === "bottom-start" ? "left top" : "left bottom",
                        }}
                    >
                        <Paper>
                            <ClickAwayListener onClickAway={handleClose}>
                                <MenuList
                                    autoFocusItem={open}
                                    id="composition-menu"
                                    aria-labelledby="composition-button"
                                    onKeyDown={handleListKeyDown}
                                >
                                    <MenuItem onClick={handleClose}>1’700.00 CHF</MenuItem>
                                    <MenuItem onClick={handleClose}>1’700.00 CHF</MenuItem>
                                    <MenuItem onClick={handleClose}>1’700.00 CHF</MenuItem>
                                </MenuList>
                            </ClickAwayListener>
                        </Paper>
                    </Grow>
                )}
            </Popper>
        </>
    );
};

const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '100%',
    maxWidth: '800px',
    bgcolor: '#fff',
    border: '0',
    borderRadius: '7px',
    boxShadow: 24,
    zIndex: 100
};

const inputStyle = {
    mt: 1,
    width: '100%',
    '& .MuiInputBase-input': {
        paddingTop: '10px',
        paddingBottom: '10px',
    },
    "& .Mui-focused fieldset": {
        borderColor: "#1B9607 !important",
    },
    '& .MuiNativeSelect-select': {
        paddingTop: '10px',
        paddingBottom: '10px',
    }
}

const progressStyle = {
    borderRadius: 2,
    height: 6,
    backgroundColor: '#EBEBEB',
    '& .MuiLinearProgress-bar': {
        borderRadius: 2
    }
}








