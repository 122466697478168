import React, { useState, useEffect } from "react";
import {
    Box,
    Grid,
    TableContainer,
    Table,
    TableHead,
    TableBody,
    TableRow,
    TableCell,
    Typography,
    Stack,
    Divider,
    Button,
    Tooltip,
    IconButton,
    ClickAwayListener,
} from "@mui/material";
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import KeyboardReturnRoundedIcon from '@mui/icons-material/KeyboardReturnRounded';
import AddIcon from "@mui/icons-material/Add";

import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import API from "../../../../axios";
import { openPopUp } from "../../../../store/reducer";
import {
    InputField,
    MegaSearchBox,
    StartIconBtn,
    MyLoader,
    SearchComboBox,
    SaveChangesBtn,
    InfoHeading,
    TableWrapper
} from "../../../../components";
import LibraryLayout from "../library-layout";
import { errorsSetter } from "../../../../helpers/errors-setter";

export default function EditProjectTemplatePanel({ switchScreen, ...props }) {
    const [record, setRecord] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [showUpdateBtn, setShowUpdateBtn] = useState(false);
    const [formData, setFormData] = useState({
        title: '',
        description: ''
    });
    const [pvModule, setPvModule] = useState(null);
    const [inverter, setInverter] = useState(null);
    const [subStructure, setSubStructure] = useState(null);
    const [addComponent, setAddComponent] = useState(false);
    const [addFromGroup, setAddFromGroup] = useState(false);
    const [toggler, setToggler] = useState(false);
    const [tableData, setTableData] = useState([]);
    const [removedComponents, setRemovedComponents] = useState([]);
    const [newAddedComponents, setNewAddedComponents] = useState([]);
    const [formErrors, setFormErrors] = useState({});
    const _userRole = useSelector((state) => state.storeReducer.user.role);

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { id } = useParams();

    useEffect(() => {
        getData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getData = async () => {
        setIsLoading(true);
        try {
            let { data } = await API(`${_userRole}/project-templates/${id}`, "get");
            setRecord(data);
            setFormData({
                title: data.title,
                description: data.description
            });
            setTableData(data.template_components);
            setIsLoading(false);
        } catch (e) {
            setIsLoading(false);
            dispatch(
                openPopUp({
                    message: "Error Occurred.",
                    type: "error",
                })
            );
        }
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
        setShowUpdateBtn(true);
    };

    const handleForm = async () => {
        if (formData?.title === '') {
            setFormErrors({
                title: 'Field title is required'
            })
            return
        }
        setIsLoading(true);
        let _fd = {
            ...formData,
            id: id,
            _method: "patch",
            components: newAddedComponents || [],
            pv_module: pvModule || record?.pv_module?.id,
            inverter: inverter || record?.inverter?.id,
            sub_structure: subStructure || record?.subStructure?.id,
            delete_components: removedComponents || [],
        };
        setFormErrors({});
        try {
            await API(`${_userRole}/project-templates/${id}`, "post", _fd);
            setIsLoading(false);
            setShowUpdateBtn(false);
            dispatch(
                openPopUp({
                    message: "Project template has been updated.",
                    type: "success",
                })
            );
        } catch (error) {
            setIsLoading(false);
            setFormErrors(errorsSetter(error));
            dispatch(
                openPopUp({
                    message: "Error Occurred. While updating project template.",
                    type: "error",
                })
            );
        }
    };

    const addGroupRow = (item) => {
        if (!item.groupcomponent.length) {
            dispatch(
                openPopUp({
                    message: "Components are not present in this group.",
                    type: "warning",
                })
            );
            return
        }
        let _flag = false;
        let _arr = [];
        let _gID = item.id;
        let _gTitle = item.title;
        let _components = [];
        for (let i = 0; i < item.groupcomponent.length; i++) {
            let _index = tableData.findIndex(x => x.components.id === item.groupcomponent[i].components.id);
            if (_index !== -1) {
                _flag = true;
            } else {
                _arr.push({ id: item.groupcomponent[i].components.id, group: _gID });
                _components.push({ components: item.groupcomponent[i].components, group: { title: _gTitle } });
            }
        }
        if (_flag) {
            dispatch(
                openPopUp({
                    message: "Duplicate component found. Duplicate component will be removed.",
                    type: "warning",
                })
            );
        }
        setNewAddedComponents([...newAddedComponents, ..._arr]);
        setTableData([...tableData, ..._components]);
        setShowUpdateBtn(true);
        setAddFromGroup(false);
    }

    const addRow = (item) => {
        let _index = tableData.findIndex(x => x.components.id === item.id);
        if (_index !== -1) {
            dispatch(
                openPopUp({
                    message: "Duplicate components are not allowed.",
                    type: "warning",
                })
            );
            return
        }
        setTableData([...tableData, { components: item }]);
        setNewAddedComponents([...newAddedComponents, { id: item.id, group: null }]);
        setShowUpdateBtn(true);
        setAddComponent(false);
    }

    const removeRow = (item, index) => {
        let arr = tableData;
        arr.splice(index, 1);
        setTableData(arr);
        let arr2 = newAddedComponents;
        let _index = arr2.findIndex(x => x.id === item.id);
        if (_index < 0) {
            setShowUpdateBtn(true);
            setRemovedComponents([...removedComponents, item.components.id]);
            setShowUpdateBtn(true);
        } else {
            arr2.splice(_index, 1);
            setNewAddedComponents(arr2);
            setToggler(!toggler);
        }
    };

    return (
        <LibraryLayout>
            <Box sx={{ pt: 2, pb: 2 }}>
                <Box>
                    <Stack
                        direction="row"
                        alignItems='center'
                        divider={<Divider orientation="vertical" flexItem />}
                        spacing={2}
                    >
                        <Tooltip title='Back' placement="top">
                            <IconButton
                                onClick={() => navigate(-1)}
                            >
                                <KeyboardReturnRoundedIcon />
                            </IconButton>
                        </Tooltip>
                        <Typography
                            variant='p'
                            component='p'
                            sx={{ fontSize: '13px' }}
                        >
                            {record?.title ?? '-'}
                        </Typography>
                        <Button
                            variant='contained'
                            sx={{
                                textTransform: 'none'
                            }}
                        >
                            Archive
                        </Button>
                    </Stack>
                </Box>
                <Box sx={{ pt: 4, pb: 2 }}>
                    <InfoHeading
                        text='Project'
                        variant='h5'
                    />
                    <Box sx={{ borderBottom: '1px solid #ccc', mt: 2 }}>
                        <Typography
                            variant='p'
                            component='p'
                            sx={{ borderBottom: '1px solid', pb: 1, borderColor: 'primary.main', width: 'fit-content' }}
                        >
                            DEUTCH (CH)
                        </Typography>
                    </Box>
                    <Grid container spacing={2} sx={{ mt: 3 }}>
                        <Grid item xs={12}>
                            <Box sx={{ maxWidth: '500px' }}>
                                <Stack spacing={2}>
                                    <InputField
                                        label='Project template name'
                                        size="small"
                                        required
                                        fullWidth
                                        initValue={formData.title}
                                        error={formErrors?.title}
                                        name='title'
                                        handleChange={handleChange}
                                    />
                                    <InputField
                                        placeholder='Standard template for a PV system'
                                        label='Description'
                                        name='description'
                                        size="small"
                                        multiline
                                        fullWidth
                                        rows={4}
                                        error={formErrors?.description}
                                        initValue={formData.description}
                                        handleChange={handleChange}
                                    />
                                </Stack>
                            </Box>
                            <Divider sx={{ mt: 4, mb: 2 }} />
                        </Grid>
                        <Grid item xs={12}>
                            <Typography
                                variant='h6'
                                component='h6'
                            >
                                PV
                            </Typography>
                        </Grid>
                        {record?.photovoltaic &&
                            <Grid item xs={12}>
                                <Box sx={{ maxWidth: '100%' }}>
                                    <Typography
                                        variant='h6'
                                        component='h6'
                                        sx={{ mt: 2, mb: 2 }}
                                    >
                                        PV Module
                                    </Typography>
                                    {/* mega combo box */}
                                    <MegaSearchBox
                                        url={`${_userRole}/components?active=1&column=created_at&orderby=desc&`}
                                        objLabel="name"
                                        placeholder='Type ...'
                                        size="small"
                                        fullWidth
                                        obj={record?.pv_module}
                                        handleChange={item => {
                                            setShowUpdateBtn(true);
                                            setPvModule(item.id);
                                        }}
                                    />
                                </Box>
                            </Grid>
                        }
                        {record?.energy_management &&
                            <Grid item xs={12}>
                                <Typography
                                    variant='h6'
                                    component='h6'
                                    sx={{ mt: 2, mb: 2 }}
                                >
                                    Inverter
                                </Typography>
                                <Box sx={{ maxWidth: '100%' }}>
                                    {/* mega combo box */}
                                    <MegaSearchBox
                                        url={`${_userRole}/components?active=1&column=created_at&orderby=desc&`}
                                        objLabel="name"
                                        placeholder='Type ...'
                                        size="small"
                                        fullWidth
                                        obj={record?.inverter}
                                        handleChange={item => {
                                            setShowUpdateBtn(true);
                                            setInverter(item.id);
                                        }}
                                    />
                                </Box>
                            </Grid>
                        }
                        {record?.battery_storage &&
                            <Grid item xs={12}>
                                <Typography
                                    variant='h6'
                                    component='h6'
                                    sx={{ mt: 2, mb: 2 }}
                                >
                                    Substructure
                                </Typography>
                                <Box sx={{ maxWidth: '100%' }}>
                                    {/* mega combo box */}
                                    <MegaSearchBox
                                        url={`${_userRole}/components?active=1&column=created_at&orderby=desc&`}
                                        objLabel="name"
                                        placeholder='Type ...'
                                        size="small"
                                        fullWidth
                                        obj={record?.sub_structure}
                                        handleChange={item => {
                                            setShowUpdateBtn(true);
                                            setSubStructure(item.id);
                                        }}
                                    />
                                </Box>
                            </Grid>
                        }
                        <Grid item xs={12}>
                            <Typography
                                variant='h6'
                                component='h6'
                                sx={{ mt: 3, mb: 2 }}
                            >
                                OTHER COMPONENT
                            </Typography>
                            <TableWrapper
                                thContent={
                                    <>
                                        <TableCell>Component</TableCell>
                                        <TableCell>Item Number</TableCell>
                                        <TableCell>Group</TableCell>
                                        <TableCell>Amount</TableCell>
                                        <TableCell>Actions</TableCell>
                                    </>
                                }
                                spanTd="5"
                                isContent={tableData.length}
                            >
                                {tableData?.map((v, i) => {
                                    return (
                                        <TableRow key={i}>
                                            <TableCell>{v?.name || v?.components?.name || '--'}</TableCell>
                                            <TableCell>{v?.item_number || v?.components?.item_number || '--'}</TableCell>
                                            <TableCell>{v?.group?.title || '--'}</TableCell>
                                            <TableCell>--</TableCell>
                                            <TableCell>
                                                <Tooltip title='Delete'>
                                                    <IconButton
                                                        onClick={() => removeRow(v, i)}
                                                        color='error'
                                                        disabled={isLoading}
                                                    >
                                                        <DeleteForeverIcon />
                                                    </IconButton>
                                                </Tooltip>
                                            </TableCell>
                                        </TableRow>
                                    );
                                })}
                            </TableWrapper>
                            <Stack
                                direction={{ sm: 'row', xs: 'column' }}
                                spacing={2}
                                sx={{ mt: 2 }}
                            >
                                <Box position='relative'>
                                    <StartIconBtn
                                        title="Add Component"
                                        variant="contained"
                                        size="small"
                                        fullWidth
                                        styles={{ maxWidth: "150px" }}
                                        handleClick={() => setAddComponent(true)}
                                        icon={<AddIcon />}
                                    />
                                    {addComponent &&
                                        <ClickAwayListener onClickAway={() => setAddComponent(false)}>
                                            <Box position='absolute' left={0} top={-50} zIndex={5} width='250px'>
                                                <SearchComboBox
                                                    url={`${_userRole}/components?active=1&column=created_at&orderby=desc&`}
                                                    objLabel="name"
                                                    label='Components'
                                                    size="small"
                                                    handleChange={addRow}
                                                    placement='top'
                                                    />
                                            </Box>
                                        </ClickAwayListener>
                                    }
                                </Box>
                                <Box position='relative'>
                                    <StartIconBtn
                                        title="add from group"
                                        variant="contained"
                                        size="small"
                                        fullWidth
                                        styles={{ maxWidth: "150px" }}
                                        handleClick={() => setAddFromGroup(true)}
                                        icon={<AddIcon />}
                                        />
                                    {addFromGroup &&
                                        <ClickAwayListener onClickAway={() => setAddFromGroup(false)}>
                                            <Box position='absolute' left={0} top={-50} zIndex={5} width='250px'>
                                                <SearchComboBox
                                                    url={`${_userRole}/groups?active=1&column=title&orderby=asc&`}
                                                    objLabel="title"
                                                    size="small"
                                                    label='Components'
                                                    handleChange={addGroupRow}
                                                    placement='top'
                                                />
                                            </Box>
                                        </ClickAwayListener>
                                    }
                                </Box>
                            </Stack>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography
                                variant='h6'
                                component='h6'
                                sx={{ mt: 3, mb: 2 }}
                            >
                                RECURRING COSTS
                            </Typography>
                            <TableContainer>
                                <Table
                                    sx={{
                                        minWidth: 650,
                                        '& .MuiTableCell-root': {
                                            paddingTop: '5px',
                                            paddingBottom: '5px',
                                        }
                                    }}
                                >
                                    <TableHead>
                                        <TableRow
                                            sx={{
                                                backgroundColor: "#E6F5FE",
                                            }}
                                        >
                                            <TableCell>Components</TableCell>
                                            <TableCell>Item number</TableCell>
                                            <TableCell>Group</TableCell>
                                            <TableCell>Crowd</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        <TableRow>
                                            <TableCell>-</TableCell>
                                            <TableCell>-</TableCell>
                                            <TableCell>-</TableCell>
                                            <TableCell>-</TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            <StartIconBtn
                                title='Add RECURRING COSTS'
                                styles={{ mt: 2 }}
                            />
                        </Grid>
                    </Grid>
                </Box>
            </Box>
            {
                showUpdateBtn && (
                    <SaveChangesBtn
                        update={handleForm}
                        cancel={() => setShowUpdateBtn(false)}
                    />
                )
            }
            {isLoading && <MyLoader />}
        </LibraryLayout >
    )
}