import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  CircularProgress,
  Container,
  Typography,
  Tooltip,
  IconButton,
  Stack,
  Grid,
  TableContainer,
  Switch
} from "@mui/material";
import KeyboardReturnRoundedIcon from '@mui/icons-material/KeyboardReturnRounded';
import SquareIcon from '@mui/icons-material/Square';
import EditIcon from '@mui/icons-material/Edit';

import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";
import { useNavigate, useParams } from "react-router-dom";

import {
  CustomModal,
  InputField,
  SearchComboBox,
  MyLoader
} from "../../../components";
import API from "../../../axios";
import { errorsSetter } from "../../../helpers/errors-setter";
import { generateKey } from "../../../helpers/generate-key";
import { openPopUp } from "../../../store/reducer";
import FormModal from "./form-modal";
import myTranslator from "../../../helpers/myTranslator";


function EnergyTariffForm() {
  const [isLoading, setIsLoading] = useState(false);
  const [loader, setLoader] = useState(false);
  const [formData, setFormData] = useState({
    mon_fri: Array(24).fill('low'),
    sunday: Array(24).fill('low'),
    saturday: Array(24).fill('low'),
  });
  const [formErrors, setFormErrors] = useState({});
  const [engSupplier, setEngSupplier] = useState(null);
  const [tariff, setTariff] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [formMode, setFormMode] = useState('ADD');
  const [formType, setFormType] = useState('SUPPLIER');
  const [updateKey, setUpdateKey] = useState(generateKey());
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  const { selectedLang, translation } = useSelector((state) => state.storeReducer); 

  useEffect(() => {
    console.log("🚀 ~ file: energy-tariff-form.js:42 ~ EnergyTariffForm ~ formData", formData);
  }, [formData]);

  useEffect(() => {
    if (!!id) {
      getData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [navigate]);

  const getData = async () => {
    setLoader(true);
    try {
      let { data } = await API(`admin/tariffs/${id}`, 'get');
      createFormData(data);
    } catch (error) {
      console.error("🚀 ~ file: update-energy-supplier.js ~ getData ~ error", error)
    } finally {
      setLoader(false);
    }
  }

  const createFormData = (item) => {
    let _data = {
      ...formData,
      id: item.id,
      title: item.title,
      low_tariff: item.low_tariff,
      high_tariff: item.high_tariff,
      eng_supplier: item.energysupplier,
      status: item?.active,
      energy_supp_id: item.energy_supp_id,
      tariff_id: id,
    }
    if (Boolean(item?.tariffdata?.length)) {
      let obj = _.groupBy(item?.tariffdata, "day");
      let mon_fri = obj.mon_to_fri.map((v) => {
        return v.value;
      })
      let saturday = obj.saturday.map((v) => {
        return v.value;
      })
      let sunday = obj.sunday.map((v) => {
        return v.value;
      })
      _data = {
        ..._data,
        mon_fri: mon_fri,
        saturday: saturday,
        sunday: sunday,
      }
    }
    let _obj = {
      id: item?.id,
      energy_supp_id: item?.energy_supp_id,
      title: item?.title,
      active: item?.active
    }
    setEngSupplier(item?.energysupplier);
    setTariff(_obj);
    setFormData({ ...formData, ..._data });
  }

  const handleInput = (e) => {
    const { name, value } = e.target
    setFormData({ ...formData, [name]: value })
  }

  const handleForm = async (e) => {
    e.preventDefault();
    let _data = {
      tariff_id: tariff.id,
      energy_supp_id: engSupplier.id,
      title: tariff.title,
      status: 1,
      ...formData
    }
    setIsLoading(true);
    setFormErrors({});
    try {
      await API("admin/tariffs", "post", _data);
      setIsLoading(false);
      dispatch(
        openPopUp({
          message: myTranslator(translation,selectedLang,"sa_tariffs_form_alert_success"),
          type: "success",
        })
      ); 
      navigate('/admin/energy-tariffs');
    } catch (err) {
      setFormErrors(errorsSetter(err));
      dispatch(
        openPopUp({ message: err?.response?.data?.message, type: "error" })
      );
      setIsLoading(false);
    }
  };

  const handleSupplierChange = (item) => {
    setEngSupplier(item);
    setTariff(null);
    setUpdateKey(generateKey());
    navigate('/admin/energy-tariffs/create');
  }

  const toggleCell = (indexKey, i) => {
    let newArr = formData[indexKey];
    newArr[i] = newArr[i] === 'high' ? 'low' : 'high';
    setFormData({ ...formData, [indexKey]: newArr });
  }

  const printCells = (indexKey) => {
    return (
      formData[indexKey].map((v, i) => {
        return <td key={i} align='center'>
          <Tooltip title={v === 'high' ? 'High' : 'Low'} placement="top">
            <button
              type="button"
              onClick={() => toggleCell(indexKey, i)}
              style={{
                cursor: 'pointer',
                border: 0,
                backgroundColor: 'transparent',
              }}
            >
              <SquareIcon color={v === 'high' ? 'error' : 'default'} sx={{ color: v === 'high' ? '' : 'primary.exLight' }} />
            </button>
          </Tooltip>
        </td>
      })
    );
  };

  return (
    <>
      {loader &&
        <MyLoader />
      }
      <Container maxWidth="100%" sx={{ py: 3 }}>
        <Stack
          direction='row'
          alignItems='center'
          spacing={1}   
        >
          <Tooltip title={myTranslator(translation,selectedLang,"sa_tariffs_form_tooltip_back")} placement="top">
            <IconButton onClick={() => navigate('/admin/energy-tariffs')}>
              <KeyboardReturnRoundedIcon />
            </IconButton>
          </Tooltip>
          <Typography variant="h6">
           {myTranslator(translation,selectedLang,"sa_tariffs_form_heading")}
          </Typography>
        </Stack>
        <Box
          component='form'
          autoComplete="off"
          onSubmit={handleForm}
          mt={2} mb={3}
        >
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Grid container spacing={2}>
                <Grid item md={6} sm={8} xs={12}>
                  <Stack
                    direction='row'
                    alignItems='center'
                    spacing={1}
                  >
                    <Box width='100%' position='relative' zIndex={3}>
                      <SearchComboBox
                        url={`admin/energy-suppliers?`}
                        objLabel="title"
                        size="small"
                        record={engSupplier}
                        addBtn
                        required
                        fullWidth
                        label={myTranslator(translation,selectedLang,"sa_tariffs_form_label_energy_supplier")}
                        addBtnEvent={() => {
                          setOpenModal(true);
                          setFormMode('ADD');
                          setFormType('SUPPLIER');
                        }}
                        handleChange={handleSupplierChange}
                      />
                    </Box>
                    {engSupplier !== null &&
                      <Tooltip title={myTranslator(translation,selectedLang,"sa_tariffs_form_tooltip_edit")} placement="top">
                        <IconButton
                          color="success"
                          size='small'
                          onClick={() => {
                            setOpenModal(true);
                            setFormMode('UPDATE');
                            setFormType('SUPPLIER');
                          }}
                        >
                          <EditIcon />
                        </IconButton>
                      </Tooltip>
                    }
                  </Stack>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h6">
             {myTranslator(translation,selectedLang,"sa_tariffs_form_typo_tariff")}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Grid container spacing={2}>
                <Grid item md={6} sm={8} xs={12}>
                  <Stack
                    direction='row'
                    alignItems='center'
                    spacing={1}
                  >
                    <Box width='100%'>
                      <SearchComboBox
                        url={`admin/tariffs?energy_supp_id=${engSupplier?.id || ''}&`}
                        objLabel="title"
                        size="small"
                        addBtn
                        required
                        fullWidth
                        record={tariff}
                        disabled={engSupplier === null}
                        label={myTranslator(translation,selectedLang,"sa_tariffs_form_label_tariff")}
                        addBtnEvent={() => {
                          setOpenModal(true);
                          setFormMode('ADD');
                          setFormType('TARIFF');
                        }}
                        handleChange={(item) => navigate(`/admin/energy-tariffs/${item.id}`)}
                        key={updateKey}
                      />
                    </Box>
                    {tariff !== null &&
                      <Tooltip title={myTranslator(translation,selectedLang,"sa_tariffs_form_tooltip_edit")}>
                        <IconButton
                          color="success"
                          size='small'
                          onClick={() => {
                            setOpenModal(true);
                            setFormMode('UPDATE');
                            setFormType('TARIFF');
                          }}
                        >
                          <EditIcon />
                        </IconButton>
                      </Tooltip>
                    }
                  </Stack>
                </Grid>
              </Grid>
            </Grid>
            <Grid item sm={6} xs={12}>
              <InputField
                size="small"
                label={myTranslator(translation,selectedLang,"sa_tariffs_form_label_low_tariff_rate")}
                type="number"
                required
                fullWidth
                name="low_tariff"
                error={formErrors.low_tariff}
                initValue={formData.low_tariff}
                handleChange={handleInput}
              />
            </Grid>
            <Grid item sm={6} xs={12}>
              <InputField
                size="small"
                label={myTranslator(translation,selectedLang,"sa_tariffs_form_label_high_tariff_rate")}
                type="number"
                required
                fullWidth
                name="high_tariff"
                initValue={formData.high_tariff}
                error={formErrors.high_tariff}
                handleChange={handleInput}
              />
            </Grid>
            <Grid item xs={12}>
              <TableContainer>
                <table width='100%' style={{ maxWidth: '1100px' }}>
                  <thead>
                    <tr>
                      <td></td>
                      {Array(24).fill('').map((_, _i) => {
                        return <td key={_i} align='center' style={{ paddingBottom: 10 }}>{_i + 1}</td>
                      })}
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        <small>Mon-Fri</small>
                      </td>
                      {printCells('mon_fri')}
                    </tr>
                    <tr>
                      <td>
                        <small>Sat</small>
                      </td>
                      {printCells('saturday')}
                    </tr>
                    <tr>
                      <td>
                        <small>Sun</small>
                      </td>
                      {printCells('sunday')}
                    </tr>
                  </tbody>
                </table>
              </TableContainer>
            </Grid>
            <Grid item sm={6} xs={12}>
              <Stack alignItems='center' direction="row">
                <label>{myTranslator(translation,selectedLang,"sa_tariffs_form_label_status")}</label>
                <Switch
                  value={formData?.status}
                  checked={Boolean(formData?.status)}
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      status: e.target.checked
                    })
                  }
                  color="success"
                />
              </Stack>
            </Grid>
            <Grid item xs={12}>
              <Box sx={{ textAlign: "end" }}>
                <Button
                  onClick={() => navigate(-1)}
                  sx={{
                    mt: 2,
                    mr: 1,
                  }}
                  type="button"
                >
                  
                  {myTranslator(translation,selectedLang,"sa_tariffs_form_btn_cancel")}
                </Button>
                <Button
                  variant="contained"
                  onClick={() => { }}
                  sx={{
                    mt: 2,
                  }}
                  disabled={isLoading}
                  type="submit"
                >
                  {isLoading ? (
                    <CircularProgress
                      size={18}
                      sx={{ color: "#fff", mr: 1, size: "12px" }}
                    />
                  ) : null}
                  
                  {myTranslator(translation,selectedLang,"sa_tariffs_form_btn_send")}
                </Button>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Container>
      {
        openModal ? <CustomModal
          handleClose={() => setOpenModal(false)}
        >
          <FormModal
            handleClose={() => setOpenModal(false)}
            getData={() => { }}
            record={formMode === 'UPDATE' ? formType === 'SUPPLIER' ? engSupplier : tariff : null}
            formType={formType}
            formMode={formMode}
            id={engSupplier?.id || null}
            setEngSupplier={setEngSupplier}
            setTariff={setTariff}
          />
        </CustomModal> : null
      }
    </>
  );
}
export default EnergyTariffForm;