import { React, useEffect, useState } from "react";
import {
  Button,
  Chip,
  TableCell,
  TableRow,
  CircularProgress,
  Tooltip,
  IconButton,
  Typography,
  Switch,
  Tabs,
  Tab,
  Container,
  Grid,
  Box,
  Stack
} from "@mui/material";
import KeyboardReturnRoundedIcon from "@mui/icons-material/KeyboardReturnRounded";
import CreateIcon from "@mui/icons-material/Create";

import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

import API from "../../../axios";
import {
  InputField,
  SelectBox,
  TableWrapper,
  UsePagination,
  AddProjectBtn,
  CustomModal,
  PasswordInputField,
} from "../../../components";
import { openPopUp } from "../../../store/reducer";
import { errorsSetter } from "../../../helpers/errors-setter";
import myTranslator from "../../../helpers/myTranslator";

export default function OrganizationUsers() {
  const orgId = useParams().id;
  const [Role, setRole] = useState("");
  const [open, setOpen] = useState(false);
  const [Search, setSearch] = useState("");
  const [update, setUpdate] = useState(false);
  const [value] = useState(1);
  const [Users, setUsers] = useState();
  const [IsLoading, setIsLoading] = useState();
  const [addLoading, setAddLoading] = useState();
  const [page, setPage] = useState(1);
  const [formErrors, setFormErrors] = useState({});
  const { selectedLang, translation } = useSelector((state) => state.storeReducer);  
  const [formData, setFormData] = useState({
    language: "english",
    role: "manager",
    organization_id: orgId,
    showPassword: false,
  });
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    getUsers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  const getUsers = async (e) => {
    let role = Role;
    let search = Search;
    if (e) {
      setRole("");
      setSearch("");
      role = "";
      search = "";
    }
    setIsLoading(true);
    try {
      const res = await API(
        `admin/getusers/${orgId}?page=${page}&search=${search}&role=${role}`,
        "get"
      );
      setUsers(res?.data);
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      setUsers(null);
      dispatch(openPopUp({ message: err.message, type: "error" }));
    }
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    if (name === "status") {
      setFormData({ ...formData, [name]: formData.status ? 0 : 1 });
    } else setFormData({ ...formData, [name]: value });
  };

  const handelCreate = () => {
    setFormErrors({});
    setFormData({
      organization_id: orgId,
    });
    setOpen(true);
  };

  const handelUpdate = (user) => {
    setFormErrors({});
    setFormData({
      first_name: user.first_name,
      last_name: user.last_name,
      telephone: user.telephone,
      mobile_phone: user.mobile_phone,
      language: user.language,
      status: user.active,
      role: user.role,
      user_id: user.id,
      organization_id: orgId,
    });
    setOpen(true);
    setUpdate(true);
  };

  const handleForm = async (e) => {
    e.preventDefault();
    setAddLoading(true);
    setFormErrors({});
    try {
      let url = formData?.user_id ? `admin/update/user/${formData.user_id}` : `admin/store/userbyadmin`;
      await API(url, "post", formData);
      let msg = update ? "User has been updated" : "User has been created";
      dispatch(openPopUp({ message: msg, type: "success" }));
      setAddLoading(false);
      setOpen(false);
      getUsers();
    } catch (err) {
      if (err?.response?.status === 422) {
        setFormErrors(errorsSetter(err));
      } else
        dispatch(
          openPopUp({ message: err?.response?.data?.message, type: "error" })
        );

      setAddLoading(false);
    }
  };

  return (
    <>
      <Container maxWidth="100%" sx={{ py: 3 }}>
        <Tooltip title={myTranslator(translation,selectedLang,"sa_org_users_tooltip_back")} placement="top">
          <IconButton onClick={() => navigate('/admin/organizations')}>
            <KeyboardReturnRoundedIcon />
          </IconButton>
        </Tooltip>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs aria-label="basic tabs example" value={value}>
            <Tab
              label={myTranslator(translation,selectedLang,"sa_org_users_label_basic_info")}
              onClick={() =>
                navigate(`/admin/organizations/${orgId}`, {
                  id: orgId,
                })
              }
            />
            <Tab
            
              label={myTranslator(translation,selectedLang,"sa_org_users_label_users")}
              onClick={() =>
                navigate(`/admin/organizations/${orgId}/users`, {
                  id: orgId,
                })
              }
            />
          </Tabs>
        </Box>
        <TabPanel value={value} index={1}>
          <Box mb={2}>
            <Stack
              direction="row"
              justifyContent="flex-start"
              alignItems="flex-end"
              spacing={1}
              flexWrap="wrap"
              sx={{ "& > div,& > button": { marginTop: "10px !important" } }}
            >
              <InputField
                size="small"
                label={myTranslator(translation,selectedLang,"sa_org_users_label_search")}
                handleChange={(e) => setSearch(e.target.value)}
                initValue={Search}
              />
              <SelectBox
                items={[
                  { label: "Manager", value: "manager" },
                  { label: "Employee", value: "employee" },
                  { label: "Stock Manager", value: "stock_manager" },
                  { label: "Plumber", value: "plumber" },
                ]}
                size="small"
                label={myTranslator(translation,selectedLang,"sa_org_users_selbox_role")}
                initValue={Role}
                handleChange={(item) => setRole(item.target.value)}
              />
              <Button
                variant="contained"
                sx={{
                  backgroundColor: "#464646",
                  borderRadius: "25px",
                  textTransform: "none",
                  "&:hover": {
                    backgroundColor: "#464646",
                  },
                }}
                onClick={() => getUsers(false)}
              >

                {myTranslator(translation,selectedLang,"sa_org_users_btn_search")}
              </Button>
              <Button
                variant="contained"
                sx={{
                  backgroundColor: "#464646",
                  borderRadius: "25px",
                  textTransform: "none",
                  "&:hover": {
                    backgroundColor: "#464646",
                  },
                }}
                onClick={() => getUsers(true)}
              >
                {myTranslator(translation,selectedLang,"sa_org_users_btn_all")}
              </Button>
            </Stack>
          </Box>
          <TableWrapper
            thContent={
              <>
                <TableCell>{myTranslator(translation,selectedLang,"sa_org_users_first_name")}</TableCell>
                <TableCell>{myTranslator(translation,selectedLang,"sa_org_users_last_name")}</TableCell>
                <TableCell>{myTranslator(translation,selectedLang,"sa_org_users_email")}</TableCell>
                <TableCell>{myTranslator(translation,selectedLang,"sa_org_users_telephone")}</TableCell>
                <TableCell>{myTranslator(translation,selectedLang,"sa_org_users_mobile_phone")}</TableCell>
                <TableCell>{myTranslator(translation,selectedLang,"sa_org_users_languages")}</TableCell>
                <TableCell>{myTranslator(translation,selectedLang,"sa_org_users_role")}</TableCell>
                <TableCell>{myTranslator(translation,selectedLang,"sa_org_users_status")}</TableCell>
                <TableCell>{myTranslator(translation,selectedLang,"sa_org_users_actions")}</TableCell>
              </>
            }
            spanTd={9}
            isLoading={IsLoading}
            isContent={!!Users && Users?.data?.length ? true : false}
            tableStyle={{ minWidth: "400px" }}
          >
            {!!Users &&
              Users?.data.map((user, index) => (
                <TableRow key={index}>
                  <TableCell>{user.first_name}</TableCell>
                  <TableCell>{user.last_name}</TableCell>
                  <TableCell>{user.email}</TableCell>
                  <TableCell>{user.telephone}</TableCell>
                  <TableCell>{user.mobile_phone}</TableCell>
                  <TableCell>{user.language}</TableCell>
                  <TableCell>{user.role.replace('_',' ')}</TableCell>
                  <TableCell>
                    <Chip
                      label={user.active ? "active" : "disabled"}
                      color={user.active ? "success" : "default"}
                      size="small"
                    />
                  </TableCell>
                  <TableCell>
                    <Tooltip title={myTranslator(translation,selectedLang,"sa_org_sa_org_users_tooltip_edit")} placement="top">
                      <IconButton onClick={() => handelUpdate(user)}>
                        <CreateIcon />
                      </IconButton>
                    </Tooltip>
                  </TableCell>
                </TableRow>
              ))}
          </TableWrapper>
          {!!Users && Users?.meta?.last_page > 1 && (
            <Box component="div" sx={{ mt: 2 }}>
              <UsePagination
                total={Users?.meta?.total}
                perPage={Users?.meta?.per_page}
                page={page}
                setPage={setPage}
              />
            </Box>
          )}
        </TabPanel>
      </Container>
      {open ?
        <CustomModal
          width='650px'
          handleClose={() => {
            setOpen(false);
            setUpdate(false);
          }}
        >
          <Box
            component="form"
            onSubmit={handleForm}
            autoComplete="off"
            sx={{ padding: "0 0 25px" }}
          >
                <Typography variant="h6" mt={-3}>
                  {update ? "Update" : "Create"} User
                </Typography>
            <Grid container spacing={2}>
              <Grid item xs={12}>
              </Grid>
              <Grid item sm={6} xs={6}>
                <InputField
                  placeholder="John doe"
                  size="small"
                  labelTop={myTranslator(translation,selectedLang,"sa_org_users_input_first_name")}
                  fullWidth
                  required
                  initValue={formData?.first_name}
                  error={formErrors?.first_name}
                  name="first_name"
                  handleChange={(event) => handleChange(event)}
                />
              </Grid>
              <Grid item sm={6} xs={6}>
                <InputField
                  placeholder={myTranslator(translation,selectedLang,"sa_org_users_input_last_name")}
                  size="small"
                  fullWidth
                  initValue={formData?.last_name}
                  labelTop={myTranslator(translation,selectedLang,"sa_org_users_input_last_name")}
                  error={formErrors?.last_name}
                  name="last_name"
                  handleChange={(event) => handleChange(event)}
                />
              </Grid>
              {!update && (
                <Grid item xs={12}>
                  <InputField
                    placeholder="Johndoe@gmail.com"
                    size="small"
                    labelTop={myTranslator(translation,selectedLang,"sa_org_users_input_email")}
                    fullWidth
                    required
                    initValue={formData?.email}
                    error={formErrors?.email}
                    name="email"
                    type="email"
                    handleChange={(event) => handleChange(event)}
                  />
                </Grid>
              )}
              <Grid item sm={6} xs={6}>
                <InputField
                  placeholder="+0987654321"
                  size="small"
                  labelTop={myTranslator(translation,selectedLang,"sa_org_users_input_mobile_phone")}
                  fullWidth
                  inputProps={{
                    pattern: ".{10,20}", title: "[03339876543]"
                  }}
                  initValue={formData?.mobile_phone}
                  error={formErrors?.mobile_phone}
                  name="mobile_phone"
                  handleChange={(event) => handleChange(event)}
                />
              </Grid>
              <Grid item sm={6} xs={6}>
                <InputField
                  placeholder="+0987654321"
                  size="small"
                  labelTop={myTranslator(translation,selectedLang,"sa_org_users_input_telephone")}
                  fullWidth
                  required
                  initValue={formData?.telephone}
                  inputProps={{
                    pattern: ".{10,20}", title: "[03339876543]"
                  }}
                  error={formErrors?.telephone}
                  name="telephone"
                  handleChange={(event) => handleChange(event)}
                />
              </Grid>
              <Grid item xs={12}>
                <PasswordInputField
                  placeholder="xxxxxx"
                  labelTop={update ? "Update Password" : "Password"}
                  size="small"
                  fullWidth
                  required={!update}
                  inputProps={{
                    pattern: ".{6,}", title: "6 or more characters"
                  }}
                  name="password"
                  error={formErrors?.password}
                  handleChange={(event) => handleChange(event)}
                />
              </Grid>
              <Grid item sm={6} xs={6}>
                <SelectBox
                  items={[
                    { label: "English", value: "english" },
                    { label: "Deutsch (ch)", value: "dutch" },
                  ]}
                  initValue={formData?.language}
                  labelTop={myTranslator(translation,selectedLang,"sa_org_users_label_search")}
                  size="small"
                  name="language"
                  fullWidth
                  required
                  error={formErrors?.language}
                  handleChange={(event) => handleChange(event)}
                  styles={{ minWidth: "185px" }}
                />
              </Grid>
              <Grid item sm={6} xs={6}>
                <SelectBox
                  items={[
                    { label: "Manager", value: "manager" },
                    { label: "Employee", value: "employee" },
                    { label: "Stock manager", value: "stock_manager" },
                    { label: "Plumber", value: "plumber" },
                  ]}
                  size="small"
                  fullWidth
                  labelTop={myTranslator(translation,selectedLang,"sa_org_users_selbox_role")}
                  name="role"
                  required
                  initValue={formData?.role}
                  error={formErrors?.role}
                  handleChange={(item) => handleChange(item)}
                  styles={{ minWidth: "185px" }}
                />
              </Grid>
              <Grid item xs={12}>
                {update && (
                  <>
                    <Stack
                      justifyContent="space-between"
                      alignItems="center"
                      direction="row"
                    >
                      <label>{myTranslator(translation,selectedLang,"sa_org_users_label_status")}</label>
                      <Switch
                        name="status"
                        checked={formData.status}
                        value={formData?.status}
                        onChange={handleChange}
                        color="success"
                      />
                    </Stack>
                  </>
                )}
              </Grid>
              <Grid item xs={12}>
                <Box sx={{ textAlign: "end" }}>
                  <Button
                    sx={{
                      mt: 2,
                      mr: 1,
                    }}
                    onClick={() => {
                      setOpen(false);
                      setUpdate(false);
                    }}
                  >
                    Cancel
                  </Button>
                  <Button
                    variant="contained"
                    onClick={() => { }}
                    sx={{
                      mt: 2,
                    }}
                    disabled={addLoading}
                    type="submit"
                  >
                    {addLoading ? (
                      <CircularProgress
                        size={18}
                        sx={{ color: "#fff", mr: 1, size: "12px" }}
                      />
                    ) : null}
                    {update ? "Update" : "Create"}
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </CustomModal>
        :
        <AddProjectBtn
          handleClick={() => {
            handelCreate();
          }}
          tooltip={myTranslator(translation,selectedLang,"sa_org_users_tooltip_add_user")}
        />
      }
    </>
  );
}


function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          {children}
        </Box>
      )}
    </div>
  );
}