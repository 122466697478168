import React from 'react';
import {
    Container,
    Box,
    Typography,
    useMediaQuery,
    Stack,
    Grid,
} from '@mui/material'

import InfoIcon from '@mui/icons-material/Info';

export default function ReturnCompared() {
    const min535px = useMediaQuery('(min-width:33.4375rem)');
    return (
        <Container maxWidth="100%" sx={{ paddingTop: "25px", paddingBottom: "100px" }}>
            <Box sx={{ px: { sm: 4 } }}>
                <Typography component="h5" variant="h5" sx={{ fontSize: { xs: '1rem', sm: "1.35rem" }, fontWeight: 500 }}>ECONOMY PV SYSTEM</Typography>
                <Stack
                    direction='row'
                    justifyContent='space-between'
                    alignItems='center'
                >
                    <Stack
                        direction="row"
                        sx={{ mt: { xs: 2, sm: 0 } }}
                    >
                        <span style={styles.arrow}></span>
                        <Typography component="h6" variant="h6" sx={{ fontSize: { xs: '0.95rem', sm: "1.15rem" }, fontWeight: 500 }}>INVESTMENT AND RETURN COMPARED</Typography>
                    </Stack>
                    <img width={min535px ? "154" : "100"} src={require('../../../../../assets/images/logo.png')} alt="" />
                </Stack>
            </Box>
            <Grid container spacing={5} mt={2}>
                <Grid item xs={12} md={6}>
                    <Box
                        sx={{ maxWidth: '600px' }}
                    >
                        <Stack
                            direction='row'
                            justifyContent='space-between'
                            alignItems='center'
                            sx={{ px: { sm: 4, xs: 2 }, borderTop: 1, borderBottom: 1, py: 1 }}
                        >
                            <Typography
                                variant='p'
                                component='h5'
                            >
                                photovoltaic system
                            </Typography>
                            <Typography
                                variant='caption'
                                component='p'
                                color={'#464646'}
                            >
                                38'038.60 CHF
                            </Typography>
                        </Stack>
                        <Stack
                            direction='row'
                            justifyContent='space-between'
                            alignItems='center'
                            sx={{ px: { sm: 4, xs: 2 }, borderTop: 1, borderBottom: 1, py: 1 }}
                        >
                            <Typography
                                variant='p'
                                component='h5'
                            >
                                photovoltaic system
                            </Typography>
                            <Typography
                                variant='caption'
                                component='p'
                                color={'#464646'}
                            >
                                38'038.60 CHF
                            </Typography>
                        </Stack>
                        <Stack
                            direction='row'
                            justifyContent='space-between'
                            alignItems='center'
                            sx={{ px: { sm: 4, xs: 2 }, borderTop: 1, borderBottom: 1, py: 1 }}
                        >
                            <Typography
                                variant='p'
                                component='h5'
                            >
                                photovoltaic system
                            </Typography>
                            <Typography
                                variant='caption'
                                component='p'
                                color={'#464646'}
                            >
                                38'038.60 CHF
                            </Typography>
                        </Stack>
                        <Stack
                            direction='row'
                            justifyContent='space-between'
                            alignItems='center'
                            sx={{ px: { sm: 4, xs: 2 }, borderTop: 1, borderBottom: 1, py: 1 }}
                        >
                            <Typography
                                variant='p'
                                component='h5'
                            >
                                photovoltaic system
                            </Typography>
                            <Typography
                                variant='caption'
                                component='p'
                                color={'#464646'}
                            >
                                38'038.60 CHF
                            </Typography>
                        </Stack>
                        <Stack
                            direction='row'
                            justifyContent='space-between'
                            alignItems='center'
                            sx={{ px: { sm: 4, xs: 2 }, borderTop: 1, borderBottom: 1, py: 1 }}
                        >
                            <Typography
                                variant='p'
                                component='h5'
                            >
                                photovoltaic system
                            </Typography>
                            <Typography
                                variant='caption'
                                component='p'
                                color={'#464646'}
                            >
                                38'038.60 CHF
                            </Typography>
                        </Stack>
                        <Stack
                            direction='row'
                            justifyContent='space-between'
                            alignItems='center'
                            sx={{ px: { sm: 4, xs: 2 }, borderTop: 1, borderBottom: 1, py: 1 }}
                        >
                            <Typography
                                variant='p'
                                component='h5'
                            >
                                photovoltaic system
                            </Typography>
                            <Typography
                                variant='caption'
                                component='p'
                                color={'#464646'}
                            >
                                38'038.60 CHF
                            </Typography>
                        </Stack>
                        <Stack
                            direction='row'
                            justifyContent='space-between'
                            alignItems='center'
                            sx={{ px: { sm: 4, xs: 2 }, borderTop: 1, borderBottom: 1, py: 1 }}
                        >
                            <Typography
                                variant='p'
                                component='h5'
                            >
                                photovoltaic system
                            </Typography>
                            <Typography
                                variant='caption'
                                component='p'
                                color={'#464646'}
                            >
                                38'038.60 CHF
                            </Typography>
                        </Stack>
                        <Stack
                            direction='row'
                            justifyContent='space-between'
                            alignItems='center'
                            sx={{ px: { sm: 4, xs: 2 }, borderTop: 1, borderBottom: 1, py: 1 }}
                        >
                            <Typography
                                variant='p'
                                component='h5'
                            >
                                photovoltaic system
                            </Typography>
                            <Typography
                                variant='caption'
                                component='p'
                                color={'#464646'}
                            >
                                38'038.60 CHF
                            </Typography>
                        </Stack>
                        <Stack
                            direction='row'
                            justifyContent='space-between'
                            alignItems='center'
                            sx={{ px: { sm: 4, xs: 2 }, borderTop: 1, borderBottom: 1, py: 1 }}
                        >
                            <Typography
                                variant='p'
                                component='h5'
                            >
                                photovoltaic system
                            </Typography>
                            <Typography
                                variant='caption'
                                component='p'
                                color={'#464646'}
                            >
                                38'038.60 CHF
                            </Typography>
                        </Stack>
                    </Box>
                    <Box
                        my={3}
                        sx={{ maxWidth: '600px' }}
                    >
                        <Stack
                            direction='row'
                            alignItems='center'
                        >
                            <Stack
                                direction='row'
                                alignItems='center'
                                flexWrap='wrap'
                                sx={{ minWidth: { sm: '200px', xs: '90px' } }}
                            >
                                <Typography
                                    variant='h5'
                                    mr={1}
                                    sx={{ lineHeight: 'unset' }}
                                >
                                    *
                                </Typography>
                            </Stack>
                            <Typography
                                variant='p'
                            >
                                If commissioned before 03/30/2023
                            </Typography>
                        </Stack>
                        <Stack
                            direction='row'
                            alignItems='center'
                        >
                            <Stack
                                direction='row'
                                alignItems='center'
                                flexWrap='wrap'
                                sx={{ minWidth: { sm: '200px', xs: '90px' } }}
                            >
                                <Typography
                                    variant='h5'
                                    mr={1}
                                    sx={{ lineHeight: 'unset' }}
                                >
                                    *
                                </Typography>
                                <Typography
                                    variant='h5'
                                    mr={1}
                                    sx={{ lineHeight: 'unset' }}
                                >
                                    *
                                </Typography>
                            </Stack>
                            <Typography
                                variant='p'
                            >
                                Assumption marginal tax rate 15%
                            </Typography>
                        </Stack>
                        <Stack
                            direction='row'
                            alignItems='center'
                        >
                            <Stack
                                direction='row'
                                alignItems='center'
                                flexWrap='wrap'
                                sx={{ minWidth: { sm: '200px', xs: '90px' } }}
                            >
                                <Typography
                                    variant='h5'
                                    mr={1}
                                    sx={{ lineHeight: 'unset' }}
                                >
                                    *
                                </Typography>
                                <Typography
                                    variant='h5'
                                    mr={1}
                                    sx={{ lineHeight: 'unset' }}
                                >
                                    *
                                </Typography>
                                <Typography
                                    variant='h5'
                                    mr={1}
                                    sx={{ lineHeight: 'unset' }}
                                >
                                    *
                                </Typography>
                            </Stack>
                            <Typography
                                variant='p'
                            >
                                Grants can vary
                            </Typography>
                        </Stack>
                    </Box>
                    <Box>
                        <Stack
                            direction="row"
                            sx={{ mt: { xs: 2, sm: 0 } }}
                        >
                            <span style={styles.arrow}></span>
                            <Typography component="h6" variant="h6" sx={{ fontSize: { xs: '0.95rem', sm: "1.15rem" }, fontWeight: 500 }}>RETURN</Typography>
                        </Stack>
                        <Typography
                            variant='p'
                            ml={2}
                            color={'#464646'}
                        >
                            Return on your capital / internal rate of return:
                        </Typography>
                    </Box>
                    <Stack
                        direction='row'
                        alignItems='center'
                        justifyContent='space-between'
                        flexWrap='wrap'
                        my={2}
                        sx={{ maxWidth: '600px', backgroundColor: '#F8F8F8', borderRadius: 2, border: '1px solid #C0C0C0', p: { sm: 2, xs: 1.2 } }}
                    >
                        <Typography
                            variant='h5'
                            mt={1}
                            sx={{ fontSize: { md: '1.3rem', xs: '1rem' } }}
                        >
                            Return on your PV system
                        </Typography>
                        <Typography
                            variant='h5'
                            mt={1}
                            sx={{ fontSize: { md: '1.3rem', xs: '1rem' } }}
                        >
                            0.1%
                        </Typography>
                    </Stack>
                    <Stack
                        direction='row'
                        spacing={1}
                    >
                        <InfoIcon />
                        <Typography
                            variant='p'
                        >
                            The internal rate of return corresponds to the average, honest <br />return on your capital over the entire PV useful life
                        </Typography>
                    </Stack>
                </Grid>
                <Grid item xs={12} md={6}>
                    <Stack
                        direction='row'
                        spacing={1}
                    >
                        <InfoIcon sx={{ mt: 0.5 }} />
                        <Box>
                            <Typography variant='h6'>
                                What does yield mean?
                            </Typography>
                            <Typography variant='p'>
                                Income is the total savings from self-production of electricity and income from the sale <br /> of electricity minus operating costs over the useful life of the photovoltaic system/battery storage
                            </Typography>
                        </Box>
                    </Stack>
                    <Stack
                        direction="row"
                        mt={4}
                    >
                        <span style={styles.arrow}></span>
                        <Typography component="h6" variant="h6" sx={{ fontSize: { xs: '0.95rem', sm: "1.15rem" }, fontWeight: 500 }}>PRODUCTION COSTS</Typography>
                    </Stack>
                    <Typography variant='caption'>
                        1 kWh of solar power from your own roof will cost you
                    </Typography>
                    <Stack
                        direction='row'
                        alignItems='center'
                        justifyContent='space-between'
                        flexWrap='wrap'
                        my={2}
                        sx={{ backgroundColor: '#F8F8F8', borderRadius: 2, border: '1px solid #C0C0C0', p: { sm: 2, xs: 1 } }}
                    >
                        <Typography
                            variant='h5'
                            mt={1}
                            sx={{ fontSize: { md: '1.3rem', xs: '1rem' } }}
                        >
                            Your solar power price
                        </Typography>
                        <Typography
                            variant='h5'
                            mt={1}
                            sx={{ fontSize: { md: '1.3rem', xs: '1rem' } }}
                        >
                            13.06 centimes
                        </Typography>
                    </Stack>
                    <Stack
                        direction='row'
                        spacing={1}
                    >
                        <InfoIcon />
                        <Typography
                            variant='p'
                        >
                            About the useful life of the photovoltaic system and the battery storage ind capitalists ind investment and maintenance as well as consideration of tax influences.
                        </Typography>
                    </Stack>
                </Grid>
            </Grid>
            <Stack
                direction='row'
                justifyContent='space-between'
                alignItems='center'
                sx={{ maxWidth: { sm: '40%' }, mt: 10 }}>
                <Typography sx={{ fontSize: '0.875rem' }}>05.10.2022</Typography>
                <Typography sx={{ fontSize: '0.875rem' }}>gam-448</Typography>
            </Stack>
        </Container>
    )
}

const styles = {
    arrow: {
        width: 0,
        height: 0,
        display: 'inline-block',
        borderTop: '7px solid transparent',
        borderBottom: '7px solid transparent',
        borderLeft: '7px solid black',
        transform: 'rotate(221deg)',
        marginRight: '5px'
    }
}