import React, { useState, Fragment } from "react";
import {
    Button,
    TableCell,
    Box,
    TableRow,
    Stack,
    IconButton,
    Tooltip,
    Chip,
} from "@mui/material";
import CreateIcon from '@mui/icons-material/Create';

import { useNavigate } from "react-router-dom";
import qs from 'qs';
import { useSelector } from "react-redux";

import LibraryLayout from "../library-layout";
import PageTitle from "../../../../hooks/page-title";
import API from "../../../../axios";
import {
    SelectBox,
    InputField,
    SearchComboBox,
    AddProjectBtn,
    CustomModal,
    UsePagination,
    TableWrapper,
    OrderDataBtn,
    MultiSelect,
    FilterWrapper,
    InfoHeading
} from "../../../../components";
import ComponentForm from "./comp-form";
import { generateKey } from "../../../../helpers/generate-key";
import { icon1, icon2, icon3 } from "../../../../assets";

export default function ComponentsPanel() {
    PageTitle('Components');
    const [createCompModal, setCreateCompModal] = useState(false);
    const [page, setPage] = useState(1);
    const [isLoading, setIsLoading] = useState(false);
    const [records, setRecords] = useState(null);
    // filter states
    const [perPage, setPerPage] = useState(25);
    const [search, setSearch] = useState('');
    const [compType, setCompType] = useState('');
    const [status, setStatus] = useState('');
    const [language, setLanguage] = useState("");
    const [technologies, setTechnologies] = useState("");
    const [orderBy, setOrderBy] = useState({
        name: 'created_at',
        order: 'desc'
    });
    const [IsFilterApply, setIsFilterApply] = React.useState(false);
    const [updateKey, setUpdateKey] = useState(generateKey());
    const [columns, setColumns] = useState(_columns);
    const [toggler, setToggler] = useState(false);
    const _userRole = useSelector((state) => state.storeReducer.user.role);

    const navigate = useNavigate();

    React.useEffect(() => {
        getData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [page, orderBy, perPage]);

    const closeModal = () => setCreateCompModal(false);

    const applyFilters = () => {
        setIsFilterApply(true);
        getData();
    }

    const clearFilters = () => {
        setIsFilterApply(false);
        if (search !== '') {
            setSearch('');
        }
        if (compType !== '') {
            setCompType('');
        }
        if (status !== '') {
            setStatus('');
        }
        if (language !== "") {
            setLanguage("");
        }
        if (technologies !== "") {
            setTechnologies("");
        }
        if (orderBy.name !== 'created_at' && orderBy.order !== 'desc') {
            setOrderBy({
                name: 'created_at',
                order: 'desc',
            });
        }
        setUpdateKey(generateKey());
        getData(true);
    }

    const getData = async (flush = false) => {
        setIsLoading(true);
        var params;
        try {
            if (flush) {
                params = {
                    page: page,
                    per_page: perPage,
                    column: 'created_at',
                    orderby: 'desc',
                }
            } else {
                params = {
                    page: page,
                    per_page: perPage,
                    search: search,
                    componenttype: compType?.title ?? '',
                    active: status === 'active' ? 1 : 0,
                    inactive: status === 'disable' ? 1 : 0,
                    battery_storage: technologies === 'battery_storage' ? 1 : 0,
                    energy_management: technologies === 'energy_management' ? 1 : 0,
                    photovoltaic: technologies === 'photovoltaic' ? 1 : 0,
                    column: orderBy.name,
                    orderby: orderBy.order,
                }
            }
            let { data } = await API(`${_userRole}/components?` + qs.stringify(params), 'get');
            setRecords(data);
            setIsLoading(false);
        } catch (error) {
            if (!!records) setRecords(null);
            setIsLoading(false);
        }
    }

    const myFilters = () => {
        return (
            <>
                <InputField
                    size="small"
                    label='Search'
                    fullWidth
                    handleChange={(e) => setSearch(e.target.value)}
                    initValue={search}
                />
                <SearchComboBox
                    label='Component Type'
                    fullWidth
                    url={`${_userRole}/component-types?active=1&`}
                    objLabel='title'
                    required
                    size='small'
                    handleChange={(item) => setCompType(item)}
                    key={updateKey}
                />
                <SelectBox
                    items={techOptions}
                    label="Technologies"
                    size="small"
                    fullWidth
                    initValue={technologies}
                    handleChange={(e) => setTechnologies(e.target.value)}
                />
                <SelectBox
                    items={langOptions}
                    label="Language"
                    size="small"
                    fullWidth
                    initValue={language}
                    handleChange={(e) => setLanguage(e.target.value)}
                />
                <SelectBox items={[
                    { label: 'Active', value: 'active' },
                    { label: 'Disabled', value: 'disable' }
                ]}
                    label='Status'
                    size='small'
                    fullWidth
                    initValue={status}
                    handleChange={(e) => setStatus(e.target.value)}
                />

            </>
        );
    }

    const tableFilters = () => {
        return (
            <>
                <MultiSelect
                    columnsList={columns}
                    setColumnsList={(e) => {
                        setColumns(e);
                        setToggler(!toggler);
                    }}
                />
                <SelectBox
                    label='Shown'
                    fullWidth
                    size='small'
                    items={rowsOption}
                    handleChange={(e) => setPerPage(e.target.value)}
                    initValue={perPage}
                />
            </>
        )
    }

    const printTh = () => {
        return (
            <>
                <TableCell
                    width='20px'
                >
                </TableCell>
                {columns.map((v, _) => {
                    if (!v.show) return <></>
                    return (<Fragment key={v.id}>
                        {v.type === 'simple_label' &&
                            <TableCell>
                                {v.label}
                            </TableCell>
                        }
                        {v.type === 'order_label' &&
                            <TableCell>
                                <OrderDataBtn handleBtn={setOrderBy} selected={orderBy.name} value={v.value} title={v.label} />
                            </TableCell>
                        }
                    </Fragment>);
                })}
                <TableCell>
                    Actions
                </TableCell>
            </>
        );
    }

    return (
        <LibraryLayout>
            <Box>
                <FilterWrapper
                    isApplied={IsFilterApply}
                    filters={myFilters()}
                    btn={(search || compType || technologies || language || status) !== '' &&
                        <Button
                            variant="contained"
                            sx={{ borderRadius: "25px", textTransform: "none", width: { md: "fit-content", xs: "100%" } }}
                            onClick={applyFilters}
                        >
                            Filter
                        </Button>
                    }
                    tableFilters={tableFilters()}
                    clearFilters={clearFilters}
                />
                <Box>
                    {records?.last_page > 1 && (
                        <Box component="div" mb={3} mt={2}>
                            <UsePagination
                                total={records?.total}
                                perPage={records?.per_page}
                                page={records?.current_page}
                                setPage={setPage}
                                key={records?.last_page}
                            />
                        </Box>
                    )}
                    <TableWrapper
                        thContent={printTh()}
                        spanTd={columns.length + 2}
                        tableStyle={{ minWidth: '1200px' }}
                        isLoading={isLoading}
                        isContent={records?.data.length}
                    >
                        {records?.data.map((v, i) => {
                            let rowNum = (records?.current_page - 1) * records?.per_page + i + 1;
                            return (
                                <TableRow key={i}>
                                    <TableCell sx={{ textAlign: 'center' }}>
                                        {rowNum}
                                    </TableCell>
                                    {columns[0]?.show &&
                                        <TableCell>
                                            {v.componenttypes.title}
                                        </TableCell>
                                    }
                                    {columns[1]?.show &&
                                        <TableCell>
                                            <Box>
                                                {v.active ?
                                                    (v.name || '-')
                                                    :
                                                    <InfoHeading
                                                        color='error'
                                                        iconColor='error'
                                                        iconSize='22px'
                                                        infoText='The component has been archived and should not be used.'
                                                        text={v.name} />
                                                }
                                            </Box>
                                        </TableCell>
                                    }
                                    {columns[2]?.show &&
                                        <TableCell>
                                            {v.item_number ?? '-'}
                                        </TableCell>
                                    }
                                    {columns[3]?.show &&
                                        <TableCell>
                                            -
                                        </TableCell>
                                    }
                                    {columns[4]?.show &&
                                        <TableCell>
                                            <Stack
                                                direction="row"
                                                spacing={1}
                                            >
                                                {v?.photovoltaic &&
                                                    <Tooltip title='Photovoltaic' placement="top">
                                                        <div className="cube">
                                                            <img src={icon1} alt='' />
                                                        </div>
                                                    </Tooltip>
                                                }
                                                {v?.battery_storage &&
                                                    <Tooltip title='Battery Storage' placement="top">
                                                        <div className="cube">
                                                            <img src={icon2} alt='' />
                                                        </div>
                                                    </Tooltip>
                                                }
                                                {v?.energy_management &&
                                                    <Tooltip title='Energy Management' placement="top">
                                                        <div className="cube">
                                                            <img src={icon3} alt='' />
                                                        </div>
                                                    </Tooltip>
                                                }
                                            </Stack>
                                        </TableCell>
                                    }
                                    {columns[5]?.show &&
                                        <TableCell>
                                            -
                                        </TableCell>
                                    }
                                    {columns[6]?.show &&
                                        <TableCell>
                                            {v.active ? 'Active' : <Chip label='Disabled' size='small' />}
                                        </TableCell>
                                    }
                                    {columns[7]?.show &&
                                        <TableCell>
                                            -
                                        </TableCell>
                                    }
                                    <TableCell>
                                        <Tooltip title='Edit' placement='top'>
                                            <IconButton
                                                onClick={() => navigate(`/library/components/${v.id}`, { id: v.id })}
                                                color='info'
                                            >
                                                <CreateIcon />
                                            </IconButton>
                                        </Tooltip>
                                    </TableCell>
                                </TableRow>)
                        })}
                    </TableWrapper>
                    {records?.last_page > 1 && (
                        <Box component="div" sx={{ mt: 4 }}>
                            <UsePagination
                                total={records?.total}
                                perPage={records?.per_page}
                                page={records?.current_page}
                                setPage={setPage}
                                key={records?.last_page}
                            />
                        </Box>
                    )}
                </Box>
            </Box>
            {createCompModal ?
                <CustomModal width='850px' children={<ComponentForm handleClose={closeModal} />} handleClose={closeModal} />
                :
                <AddProjectBtn
                    tooltip='Create component'
                    handleClick={() => setCreateCompModal(true)} />
            }
        </LibraryLayout>
    )
}

const rowsOption = [
    { label: '25 Rows', value: '25' },
    { label: '50 Rows', value: '50' },
    { label: '75 Rows', value: '75' },
    { label: '100 Rows', value: '100' },
];

const langOptions = [
    { label: 'Deutsch (CH)', value: 'dutch' },
    { label: 'English', value: 'english' }
];

const techOptions = [
    { label: 'Battery Storage', value: 'battery_storage' },
    { label: 'Energy Management', value: 'energy_management' },
    { label: 'Photovoltaic', value: 'photovoltaic' }
];

const _columns = [
    {
        id: 1,
        label: 'Component Type',
        value: 'component_type',
        show: true,
        type: 'simple_label'
    },
    {
        id: 2,
        label: 'Name',
        value: 'name',
        show: true,
        type: 'order_label'
    },
    {
        id: 3,
        label: 'Item Number',
        value: 'item_number',
        show: true,
        type: 'order_label'
    },
    {
        id: 4,
        label: 'Unit',
        value: 'fourth_col',
        show: true,
        type: 'simple_label'
    },
    {
        id: 5,
        label: 'Technologies',
        value: 'fifth_col',
        show: true,
        type: 'simple_label'
    },
    {
        id: 6,
        label: 'Language',
        value: 'sixth_col',
        show: true,
        type: 'simple_label'
    },
    {
        id: 7,
        label: 'Status',
        value: 'seventh_col',
        show: true,
        type: 'simple_label'
    },
    {
        id: 8,
        label: 'Tags',
        value: 'eight_col',
        show: true,
        type: 'simple_label'
    }
];