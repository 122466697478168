import React, { useState, useEffect } from "react";

import {
  Container,
  Button,
  Box,
  TableRow,
  TableCell,
  Typography,
  Chip,
  IconButton,
  Tooltip,
} from "@mui/material";

import ModeEditIcon from "@mui/icons-material/ModeEdit";
import CreateComponentType from "./create-component-type";
import UpdateComponentType from "./update-component-type";
import API from "../../../axios";
import { AddProjectBtn, TableWrapper, UsePagination } from "../../../components";
import { useSelector } from "react-redux";
import myTranslator from "../../../helpers/myTranslator";


var tableCols = ["sa_get_comp_title", "sa_get_comp_status", "sa_get_comp_is_custom", "sa_get_comp_action"];

export default function GetComponentTypes() {
  const [isLoading, setIsLoading] = useState(false);
  const [componentTypes, setComponentTypes] = useState(null);
  const [page, setPage] = useState(1);
  const [id, setId] = useState(0);
  const [createModal, setCreateModal] = useState(false);
  const [updateModal, setUpdateModal] = useState(false);
  const { selectedLang, translation } = useSelector((state) => state.storeReducer);  
  useEffect(() => {
    getComponentType();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  const getComponentType = async () => {
    setIsLoading(true);
    try {
      const res = await API(`admin/component-types?page=${page}`, "get");
      setComponentTypes(res.data);
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      setComponentTypes(null);
    }
  };


  return (
    <Container maxWidth="100%" sx={{ py: 3 }}>
      <Typography variant="h6" mb={2}>{myTranslator(translation,selectedLang,"sa_get_comp_heading")}</Typography>
      <TableWrapper
        thContent={tableCols.map((column, index) => (
          <TableCell key={index}>{myTranslator(translation,selectedLang,column)}</TableCell>
        ))}
        spanTd={tableCols.length}
        isLoading={isLoading}
        isContent={
          !!componentTypes && componentTypes?.data.length ? true : false
        }
        tableStyle={{ minWidth: "400px" }}
      >
        {!!componentTypes &&
          componentTypes?.data.map((componentType, index) => (
            <TableRow key={index}>
              <TableCell>{componentType.title}</TableCell>
              <TableCell>
                <Chip
                  label={componentType.active ? "active" : "disabled"}
                  color={componentType.active ? "success" : "default"}
                  size="small"
                />
              </TableCell>
              <TableCell>
                <Chip
                  label={componentType.is_custom ? "Yes" : "No"}
                  color={componentType.is_custom ? "info" : "default"}
                  size="small"
                />
              </TableCell>
              <TableCell>
                <Tooltip title="Edit">
                  <IconButton
                    onClick={() => {
                      setUpdateModal(true);
                      setId(componentType.id);
                    }}
                    color="primary"
                  >
                    <ModeEditIcon />
                  </IconButton>
                </Tooltip>{" "}
              </TableCell>
            </TableRow>
          ))}
      </TableWrapper>
      {!!componentTypes && componentTypes.last_page > 1 && (
        <Box component="div" sx={{ mt: 2 }}>
          <UsePagination
            total={componentTypes?.total}
            perPage={componentTypes?.per_page}
            page={page}
            setPage={setPage}
          />
        </Box>
      )}
      {createModal ? (
        <CreateComponentType
          open={!!createModal}
          handleClose={() => setCreateModal(false)}
          getData={getComponentType}
        />
      )
        : <AddProjectBtn handleClick={() => setCreateModal(true)} tooltip={myTranslator(translation,selectedLang,"sa_get_comp_tooltip_create_compo")} />
      }
      {updateModal && (
        <UpdateComponentType
          open={!!updateModal}
          id={id}
          setId={setId}
          handleClose={() => setUpdateModal(false)}
          getData={getComponentType}
        />
      )}
    </Container>
  );
}
