import React, { useState } from "react";
import {
    Box,
    Stack,
    Button,
    Typography,
    FormControl,
    RadioGroup,
    FormControlLabel,
    Radio,
    Grid,
    CircularProgress
} from "@mui/material";

import { useSelector } from "react-redux";

import {
    InputField,
    SelectBox,
    SearchComboBox
} from "../../../components";
import API from "../../../axios";
import { errorsSetter } from "../../../helpers/errors-setter";

function ProjectForm({ setPayload, afterSubmit, handleClose }) {
    const { user } = useSelector((state) => state.storeReducer);
    const _userRole = useSelector((state) => state.storeReducer.user.role);
    const [isLoading, setIsLoading] = useState(false);
    const [formData, setFormData] = useState({
        customer_type: 'private',
        gender_type: 'mr',
        first_name: '',
        surname: '',
        email: '',
        language: '',
        address: '',
        phone_type: '',
        project_template_id: '',
    });
    const [formErrors, setFormErrors] = useState({});

    const handleForm = async (e) => {
        e.preventDefault();
        setFormErrors({});
        let _fd = formData;
        let name = formData.phone_type
        _fd.organization_id = user.organization_id;
        _fd = { ...formData, [name]: formData.phone }
        setIsLoading(true);
        try {
            await API(`${_userRole}/projects`, 'post', _fd);
            afterSubmit({
                open: true,
                message: 'Project has been created!',
                type: 'success'
            })
            setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
            setFormErrors(errorsSetter(error));
            setPayload({
                open: true,
                message: 'Error Occurred. While creating project.',
                type: 'error'
            });
        }
    }

    return (
        <Box
            component='form'
            autoComplete="off"
            onSubmit={handleForm}
        >
            <Typography variant="h6" component="h5">
                CREATE NEW PROJECT
            </Typography>
            <Typography variant="subtitle1" component="h5"
                mt={2}
                sx={{
                    color: '#464646'
                }}
            >
                Customer Type
            </Typography>
            <FormControl>
                <RadioGroup
                    row
                    name="controlled-radio-buttons-group">
                    <FormControlLabel value="private" control={
                        <Radio
                            checked={formData.customer_type === 'private' ? true : false}
                            onChange={() => setFormData({ ...formData, customer_type: 'private' })}
                            color='success'
                            size="small" />}
                        label='Private' />
                    <FormControlLabel value="business"
                        control={
                            <Radio
                                checked={formData.customer_type === 'company' ? true : false}
                                onChange={() => setFormData({ ...formData, customer_type: 'company' })}
                                color='success'
                                size="small" />}
                        label='Company' />
                </RadioGroup>
            </FormControl>
            <Typography variant="subtitle1" component="h5"
                mt={2}
                sx={{
                    color: '#464646'
                }}>
                Gender
            </Typography>
            <FormControl>
                <RadioGroup
                    row
                    name="controlled-radio-buttons-group">
                    <FormControlLabel value="herr"
                        control={<Radio
                            checked={formData.gender_type === 'mr' ? true : false}
                            onChange={() => setFormData({ ...formData, gender_type: 'mr' })}
                            color="success"
                            size="small" />} label='Male' />
                    <FormControlLabel value="women"
                        control={<Radio
                            checked={formData.gender_type === 'women' ? true : false}
                            onChange={() => setFormData({ ...formData, gender_type: 'women' })}
                            color="success"
                            size="small" />} label='Female' />
                    <FormControlLabel value="not_specified"
                        control={<Radio
                            checked={formData.gender_type === 'not_specified' ? true : false}
                            onChange={() => setFormData({ ...formData, gender_type: 'not_specified' })}
                            color="success"
                            size="small" />} label='Not specified' />
                </RadioGroup>
            </FormControl>
            <Grid container spacing={2} mt={1}>
                <Grid item md={4} xs={12}>
                    <InputField
                        fullWidth
                        labelTop="First Name"
                        required
                        placeholder="First Name"
                        size="small"
                        error={formErrors?.first_name}
                        handleChange={(e) => setFormData({ ...formData, first_name: e.target.value })}
                    />
                </Grid>
                <Grid item md={4} xs={12}>
                    <InputField
                        fullWidth
                        labelTop="Surname"
                        required
                        placeholder="Surname"
                        size="small"
                        error={formErrors?.surname}
                        handleChange={(e) => setFormData({ ...formData, surname: e.target.value })}
                    />
                </Grid>
                <Grid item md={4} xs={12}>
                    <SelectBox
                        items={langOptions}
                        labelTop="Language"
                        fullWidth
                        size="small"
                        initValue={formData.language}
                        error={formErrors?.language}
                        handleChange={(e) => setFormData({ ...formData, language: e.target.value })}
                    />
                </Grid>
                <Grid item md={4} xs={12}>
                    <InputField
                        fullWidth
                        labelTop="Email"
                        placeholder="john@example.com"
                        type="email"
                        size="small"
                        error={formErrors?.email}
                        handleChange={(e) => setFormData({ ...formData, email: e.target.value })}
                    />
                </Grid>
                <Grid item md={8} xs={12}>
                    <Stack spacing={1} direction='row'>
                        <Box>
                            <InputField
                                type="tel"
                                fullWidth
                                labelTop="Contact number(optional)"
                                placeholder="Contact number"
                                size="small"
                                inputProps={{
                                    pattern: "[0-9]{11}",
                                    title: '[098765432105]'
                                }}
                                error={formErrors?.phone}
                                handleChange={(e) => setFormData({ ...formData, phone: e.target.value })}
                            />
                        </Box>
                        <SelectBox
                            items={telType}
                            labelTop="Type"
                            fullWidth
                            size="small"
                            initValue={formData.phone_type}
                            error={formErrors?.phone_type}
                            handleChange={(e) => setFormData({ ...formData, phone_type: e.target.value })}
                        />
                    </Stack>
                </Grid>
            </Grid>
            <Grid container spacing={2} mt={1}>
                <Grid item md={8} xs={12}>
                    <InputField
                        fullWidth
                        labelTop="Building Address"
                        placeholder="Address..."
                        required
                        size="small"
                        error={formErrors?.address}
                        handleChange={(e) => setFormData({ ...formData, address: e.target.value })}
                    />
                </Grid>
            </Grid>
            <Grid container spacing={2} mt={1}>
                <Grid item md={8} xs={12}>
                    <SearchComboBox
                        url={`${_userRole}/project-templates?active=1&column=created_at&orderby=desc&`}
                        objLabel="title"
                        size="small"
                        required
                        fullWidth
                        placement='top'
                        labelTop='Project Template'
                        handleChange={(e) => {
                            setFormData({ ...formData, project_template_id: e.id })
                        }}
                    />
                </Grid>
            </Grid>
            <Stack
                direction='row'
                spacing={1}
                mt={7}
            >
                <Button
                    variant="contained"
                    onClick={() => { }}
                    disabled={isLoading}
                    type="submit"
                >
                    {isLoading ? (
                        <CircularProgress
                            size={18}
                            sx={{ color: "#fff", mr: 1, size: "12px" }}
                        />
                    ) : null}
                    Create Project
                </Button>
                <Button
                    type="button"
                    sx={{
                        backgroundColor: '#D71B1B',
                        color: '#fff',
                        textTransform: 'none',
                        paddingLeft: '25px',
                        paddingRight: '25px',
                        '&:hover': {
                            backgroundColor: '#D71B1B',
                        }
                    }}
                    onClick={handleClose}
                >
                    Abort
                </Button>
            </Stack>
        </Box>
    );
}

ProjectForm.defaultProps = {
    setPayload: () => { },
    afterSubmit: e => { },
    handleClose: () => { },
}

export default ProjectForm;

const langOptions = [
    { label: 'Deutsch (CH)', value: 'dutch' },
    { label: 'English', value: 'english' }
];

const telType = [
    { label: 'Private', value: 'private_phone' },
    { label: 'Mobile', value: 'mobile_phone' },
    { label: 'Telephone', value: 'telephone_store' }
]