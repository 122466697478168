const initialPositionScreens = [
    { text: 'Building', url: `/building`, focus: 'building' },
    { text: 'PV System', url: `/pv-system`, focus: 'pv-system' }
]
const pvModuleScreens = [
    { text: 'Module Layout', url: '/module-layout', focus: 'module-layout' },
    { text: 'Inverter', url: '/inverter', focus: 'inverter' },
    { text: 'Power Consumption', url: '/power-consumption', focus: 'power-consumption' },
    { text: 'Electricity Tariffs', url: '/electricity-tariffs', focus: 'electricity-tariffs' }
]
const reqScreens = [
    { text: 'PV Batteries', url: '/pv-batteries', focus: 'pv-batteries' },
]
const economicScreens = [
    { text: 'PV', url: '/eco-pv', focus: 'eco-pv' },
    { text: 'Batteries System', url: '/eco-battery-system', focus: 'eco-battery-system' }
]
const partListScreens = [
    { text: 'Overview', url: '/parts-overview', focus: 'parts-overview' },
    { text: 'PV', url: '/parts-pv', focus: 'parts-pv' },
    { text: 'Batteries System', url: '/batteries-system', focus: 'batteries-system' },
    { text: 'Recurring Cost', url: '/recurring-cost', focus: 'recurring-cost' }
]
const eleSolarScreens = [
    { text: 'Overview', url: '/overview', focus: 'overview' },
    { text: 'Cost Analysis', url: '/cost-analysis', focus: 'cost-analysis' },
]

export {
    initialPositionScreens,
    pvModuleScreens,
    reqScreens,
    economicScreens,
    partListScreens,
    eleSolarScreens
}