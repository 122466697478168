import React from 'react';
import {
    Box,
    Typography,
    Container,
    FormControlLabel,
    Switch,
    Grid,
    Paper,
    Stack,
    IconButton,
    Tooltip,
} from '@mui/material';
import SimCardDownloadIcon from '@mui/icons-material/SimCardDownload';
import InfoRoundedIcon from '@mui/icons-material/InfoRounded';
import PageTitle from '../../../hooks/page-title';
import { InputField, SaveChangesBtn, SelectBox } from '../../../components';

export default function BatteryStorage() {
    PageTitle('Battery Storage');

    return (
        <Container
            maxWidth="100%"
            sx={{ paddingTop: '12px', paddingBottom: '12px', position: 'relative' }}
        >
            <Typography
                variant="h6"
                component="h6"
                mb={"10px"}
            >
                BATTERY MEMORY
            </Typography>
            <Typography
                variant="p"
                component="p"
                sx={{
                    color: "#969696",
                    mb: 4
                }}
            >
                Select a battery storage for your system
            </Typography>
            <FormControlLabel control={<Switch defaultChecked color='success' />} label="Activate battery storage" />
            <Typography
                variant="h6"
                component="h6"
                mt={4}
                mb={3}
            >
                battery storage
            </Typography>
            <Grid container spacing={2}>
                <Grid item md={3} sm={6} xs={12}>
                    <SelectBox
                        items={['BYD', 'E3/DC']}
                        label='A skewer'
                        size="small"
                        fullWidth
                    />
                </Grid>
                <Grid item md={3} sm={6} xs={12}>
                    <SelectBox
                        items={['B-Box LVS 4.0 Premium', 'B-Box LVS 8.0 Premium', 'S10E PRO 13', 'S10E PRO 19.5']}
                        label='element 6'
                        size="small"
                        fullWidth
                    />
                </Grid>
                <Grid item md={3} sm={6} xs={12}>
                    <SelectBox
                        items={['10', '11', '54', '55']}
                        label='6'
                        size="small"
                        fullWidth
                    />
                </Grid>
                <Grid item md={3} sm={6} xs={12}>
                    <Stack direction='row' alignItems='center' spacing={2}>
                        <Box sx={{ flexGrow: 1 }}>
                            <SelectBox
                                label='Apply template'
                                items={['Keine Hauptkomponentenvorlage gefunden', 'Keine Hauptkomponentenvorlage gefunden']}
                                size='small'
                                styles={{ mt: 2 }}
                                fullWidth
                            />
                        </Box>
                        <IconButton sx={{ backgroundColor: '#000', '&:hover': { backgroundColor: '#000' } }}>
                            <SimCardDownloadIcon sx={{ color: '#fff' }} />
                        </IconButton>
                    </Stack>
                </Grid>
                <Grid item md={3} sm={6} xs={12}>
                    <InputField
                        label='Number of memory modules'
                        initValue='6'
                        size='small'
                        fullWidth
                    />
                </Grid>
            </Grid>
            <Grid container spacing={2} sx={{ mt: 3 }}>
                <Grid item lg={3} md={4} sm={6} xs={12}>
                    <Box sx={cardStyle} component={Paper}>
                        <p>BATTERY STORAGE SYSTEM PARAMETERS</p>
                        <table width='100%'>
                            <tr>
                                <td><small>Cost</small></td>
                                <td><small>0.00 CHF</small></td>
                            </tr>
                            <tr>
                                <td><small>rated capacity</small></td>
                                <td><small>0.00 kWh</small></td>
                            </tr>
                            <tr>
                                <td><small>Usable capacity</small></td>
                                <td><small>0.00 kWh</small></td>
                            </tr>
                        </table>
                    </Box>
                </Grid>
                <Grid item lg={3} md={4} sm={6} xs={12}>
                    <Box sx={cardStyle} component={Paper}>
                        <p>BATTERY STORAGE SYSTEM PARAMETERS</p>
                        <table width='100%'>
                            <tr>
                                <td><small>Max. charging power of the system</small></td>
                                <td>
                                    <Tooltip title='info'>
                                        <InfoRoundedIcon sx={{ opacity: 0.5 }} fontSize='12px' />
                                    </Tooltip>
                                    <small>2.2 kWh</small></td>
                            </tr>
                            <tr>
                                <td><small>Max. discharge capacity of the system</small></td>
                                <td>
                                    <Tooltip title='info'>
                                        <InfoRoundedIcon sx={{ opacity: 0.5 }} fontSize='12px' />
                                    </Tooltip>
                                    <small>1.8 kWh</small></td>
                            </tr>
                            <tr>
                                <td><small>Degradation</small></td>
                                <td>
                                    <Tooltip title='info'>
                                        <InfoRoundedIcon sx={{ opacity: 0.5 }} fontSize='12px' />
                                    </Tooltip>
                                    <small>2.4 %/a</small></td>
                            </tr>
                            <tr>
                                <td><small>Efficiency (round trip)</small></td>
                                <td>
                                    <Tooltip title='info'>
                                        <InfoRoundedIcon sx={{ opacity: 0.5 }} fontSize='12px' />
                                    </Tooltip>
                                    <small>85.6%</small></td>
                            </tr>
                            <tr>
                                <td><small>rated capacity</small></td>
                                <td>
                                    <Tooltip title='info'>
                                        <InfoRoundedIcon sx={{ opacity: 0.5 }} fontSize='12px' />
                                    </Tooltip>
                                    <small>6.5 kWh</small></td>
                            </tr>
                            <tr>
                                <td><small>Usable capacity</small></td>
                                <td>
                                    <Tooltip title='info'>
                                        <InfoRoundedIcon sx={{ opacity: 0.5 }} fontSize='12px' />
                                    </Tooltip>
                                    <small>5.85 kWh</small></td>
                            </tr>
                            <tr>
                                <td><small>Charging cycles</small></td>
                                <td>
                                    <Tooltip title='info'>
                                        <InfoRoundedIcon sx={{ opacity: 0.5 }} fontSize='12px' />
                                    </Tooltip>
                                    <small>4000</small></td>
                            </tr>
                        </table>
                    </Box>
                </Grid>
            </Grid>
            <SaveChangesBtn />
        </Container >
    )
}

const cardStyle = { '& p': { marginBottom: '15px' }, '& td': { padding: '5px', color: '#464646' }, p: 2, backgroundColor: '#F8F8F8' }