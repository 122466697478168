import React, { useState, useEffect } from "react";

import {
  Container,
  Box,
  TableRow,
  TableCell,
  Typography,
  Chip,
  IconButton,
  Tooltip,
  Stack,
  Button
} from "@mui/material";
import ModeEditIcon from "@mui/icons-material/ModeEdit";

import qs from "qs";

import API from "../../../axios";
import {
  AddProjectBtn,
  TableWrapper,
  UsePagination,
  InputField,
} from "../../../components";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import myTranslator from "../../../helpers/myTranslator";

export default function GetEnergyTariff() {
  const [isLoading, setIsLoading] = useState(false);
  const [records, setRecords] = useState(null);
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState('');
  const [searchEng, setSearchEng] = useState('');
  const navigate = useNavigate();
  const [IsFilterApply, setIsFilterApply] = React.useState(false);
  const { selectedLang, translation } = useSelector((state) => state.storeReducer);
  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  const getData = async (flush = false) => {
    setIsLoading(true);
    try {
      var params;
      if (flush) {
        params = {
          page: page,
        };
      } else {
        params = {
          page: page,
          search: search,
          energysupplier: searchEng,
        };

      }
      const { data } = await API(`admin/tariffs?${qs.stringify(params)}`, "get");
      setRecords(data);
    } catch (err) {
      setRecords(null);
    } finally {
      setIsLoading(false);
    }
  };

  const applyFilters = () => {
    setIsFilterApply(true);
    getData();
  };

  const clearFilters = () => {
    setIsFilterApply(false);
    if (search !== "") {
      setSearch("");
    }
    if (searchEng !== "") {
      setSearchEng("");
    }
    getData(true);
  };

  const useFilters = () => {
    return (
      <Stack
        direction={{ xs: 'column', md: 'row' }}
        justifyContent={{ xs: "center", md: "flex-start" }}
        alignItems={{ xs: "center", md: "flex-end" }}
        spacing={1}
        flexWrap="wrap"
        sx={{
          "& > div,& > button": { mt: "10px !important" },
          "& > div": { width: { md: "fit-content !important", xs: "100% !important" } },
        }}
      >
        <InputField
          size="small"
          fullWidth
          label={myTranslator(translation, selectedLang, "sa_tariffs_get_label_ser_by_tariff")}
          handleChange={(e) => setSearch(e.target.value)}
          initValue={search}
        />
        <InputField
          size="small"
          fullWidth
          label={myTranslator(translation, selectedLang, "sa_tariffs_get_label_ser_by_en_supp")}
          handleChange={(e) => setSearchEng(e.target.value)}
          initValue={searchEng}
        />
        {(search || searchEng) !== '' && (
          <Button
            variant="contained"
            sx={{ borderRadius: "25px", textTransform: "none", width: { md: "fit-content", xs: "100%" } }}
            onClick={applyFilters}
          >

            {myTranslator(translation, selectedLang, "sa_tariffs_get_btn_filter")}
          </Button>
        )}
        {IsFilterApply &&
          <Button
            variant="contained"
            sx={{ borderRadius: "25px", textTransform: "none", width: { md: "fit-content", xs: "100%" } }}
            onClick={clearFilters}
          >

            {myTranslator(translation, selectedLang, "sa_tariffs_get_btn_clear")}
          </Button>}
      </Stack>
    );
  };

  const usePaginate = () => {
    return (
      records?.last_page > 1 && (
        <Box component="div" mt={2}>
          <UsePagination
            total={records?.total}
            perPage={records?.per_page}
            page={records?.current_page}
            setPage={setPage}
            key={records?.last_page}
          />
        </Box>
      )
    )
  }

  return (
    <Container maxWidth="100%" sx={{ py: 3 }}>
      <Typography variant="h6" mb={2}>{myTranslator(translation, selectedLang, "sa_tariffs_get_heading")}</Typography>
      <Box mb={2}>
        {useFilters()}
      </Box>
      <TableWrapper
        thContent={tableCols.map((column, index) => (
          <TableCell key={index}>{myTranslator(translation, selectedLang, column)}</TableCell>
        ))}
        spanTd={tableCols.length}
        isLoading={isLoading}
        isContent={
          !!records && records?.data.length ? true : false
        }
        tableStyle={{ minWidth: "400px" }}
      >
        {!!records &&
          records?.data.map((v, i) => (
            <TableRow key={i}>
              <TableCell>{v?.title}</TableCell>
              <TableCell>{v?.energysupplier?.title}</TableCell>
              <TableCell>
                <Chip
                  label={v.active ? "active" : "disabled"}
                  color={v.active ? "success" : "default"}
                  size="small"
                />
              </TableCell>
              <TableCell>
                <Tooltip title={myTranslator(translation, selectedLang, "sa_tariffs_get_tooltip_edit")} placement="top">
                  <IconButton color="info" onClick={() => navigate(`/admin/energy-tariffs/${v.id}`)}>
                    <ModeEditIcon />
                  </IconButton>
                </Tooltip>
              </TableCell>
            </TableRow>
          ))}
      </TableWrapper>
      {usePaginate()}
      <AddProjectBtn tooltip='Create energy supplier' handleClick={() => { navigate('/admin/energy-tariffs/create') }} />
    </Container>
  );
}

var tableCols = ["sa_tariffs_get_tb_title", "sa_tariffs_get_tb_supplier", "sa_tariffs_get_tb_status", "sa_tariffs_get_tb_action"];