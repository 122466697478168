import React from 'react'
import {
    Box,
    Container,
    Typography,
    Grid
} from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';

const CarbonFootprints = () => {
    const min535px = useMediaQuery('(min-width:33.4375rem)');
    return (
        <Container maxWidth="100%" sx={{ paddingTop: "25px", paddingBottom: "85px", overflow: 'hidden' }}>
            <Box sx={{ px: { md: 5 } }}>
                <Box sx={{ display: 'flex', justifyContent: "space-between", alignItems: "center" }}>
                    <Typography component="h5" variant="h5" sx={{ fontSize: { xs: '1rem', md: "1.35rem" }, fontWeight: 500, mr: 2 }}>CO2-BILANZ IHRES SYSTEMS</Typography>
                    <img width={min535px ? "154" : "100"} src={require('../../../../../assets/images/logo.png')} alt="logo"></img>
                </Box>
                <Box sx={{ display: 'flex', mt: 3 }}>
                    <span style={styles.arrow}></span>
                    <Typography component="h6" variant="h6" sx={{ fontSize: { xs: '0.95rem', md: "1.3rem" }, fontWeight: 500 }}>EIGENVERBRAUCH: WOHIN GEHT MEIN STROM?</Typography>
                </Box>
                <Typography component="p" sx={{ mt: 1 }}>Mit Ihrer jährlichen CO2-Ersparnis von 1'138 kg...</Typography>
                <Box sx={{ display: 'd-flex', alignItems: "flex-start", mt: 8 }}>
                    <img style={{ maxWidth: "60px" }} src={require("../../../../../assets/images/Car.png")} alt="logo"></img>
                    <Box sx={{ ml: 4 }}>
                        <Typography component="h5" sx={{ fontSize: '1.3rem', fontWeight: 500, borderBottom: '1px solid #000000', width: "95px", paddingBottom: 0.5 }}>5'690 km</Typography>
                        <Typography component="p" sx={{ fontSize: '0.9rem', paddingTop: 0.65 }}>fahren Sie mit Ihrem Auto 5'690 km um die Welt</Typography>
                        <Grid container>
                            <Grid lg={9} xs={8} sx={styles.wrapper}>
                                <img style={{ maxWidth: '80px' }} src={require('../../../../../assets/images/place-holder-2.png')} alt="logo"></img>
                                <Box sx={{ ml: 2 }}>
                                    <Typography component="h5" sx={{ fontSize: '1.1rem', fontWeight: 500 }}>Haben Sie gewusst?</Typography>
                                    <Typography component="p" sx={{ fontSize: '1rem', color: "#464646", maxWidth: '683px' }}>Ein durchschnittliches Elektroauto ist 3-4 mal effizienter als ein Auto mit Verbrennungsmotor</Typography>
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
                <Box sx={{ display: 'flex', flexDirection: { sm: "row", xs: "column" }, alignItems: "flex-start", mt: 5 }}>
                    <img style={{ maxWidth: "60px" }} src={require("../../../../../assets/images/footprint-com1.png")} alt="logo"></img>
                    <Box sx={{ ml: 4 }}>
                        <Typography component="h5" sx={{ fontSize: '1.3rem', fontWeight: 500, borderBottom: '1px solid #000000', width: "95px", paddingBottom: 0.5 }}>31%</Typography>
                        <Typography component="p" sx={{ fontSize: '0.9rem', paddingTop: 0.65 }}>fahren Sie mit Ihrem Auto 5'690 km um die Welt</Typography>
                        <Grid container >
                            <Grid lg={9} xs={12} sx={styles.wrapper}>
                                <img style={{ maxWidth: '80px' }} src={require('../../../../../assets/images/place-holder-2.png')} alt="logo"></img>
                                <Box sx={{ ml: { md: 2 } }}>
                                    <Typography component="h5" sx={{ fontSize: '1.1rem', fontWeight: 500 }}>Haben Sie gewusst?</Typography>
                                    <Typography component="p" sx={{ fontSize: '1rem', color: "#464646", maxWidth: '683px' }}>Haben Sie gewusst? In der Schweiz liegen die jahrlichen durchschnittlichen pro Kopf Emissionen bei 3.73 Tonnen , Durch die Reduktion des co Fussabrucks tragen wir dazu bei, dass die globale klimaerwärmung so gering wie möglich ausfallt.</Typography>
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
                <Box sx={{ display: 'd-flex', alignItems: "flex-start", mt: 5 }}>
                    <img style={{ maxWidth: "60px" }} src={require("../../../../../assets/images/treecom 1.png")} alt="logo"></img>
                    <Box sx={{ ml: 4 }}>
                        <Typography component="h5" sx={{ fontSize: '1.3rem', fontWeight: 500, borderBottom: '1px solid #000000', width: "95px", paddingBottom: 0.5 }}>91</Typography>
                        <Typography component="p" sx={{ fontSize: '0.9rem', paddingTop: 0.65 }}>Sparen Sie gleich viel CO2, wie 91 Bäume pro Jahr aufnehmen</Typography>
                        <Grid container>
                            <Grid lg={9} xs={8} sx={styles.wrapper}>
                                <img style={{ maxWidth: '80px' }} src={require('../../../../../assets/images/place-holder-2.png')} alt="logo"></img>
                                <Box sx={{ ml: 2 }}>
                                    <Typography component="h5" sx={{ fontSize: '1.1rem', fontWeight: 500 }}>Haben Sie gewusst?</Typography>
                                    <Typography component="p" sx={{ fontSize: '1rem', color: "#464646", maxWidth: '683px' }}>Die tropischen Walder der Amazonas-Region speichern bis zu 140
                                        le uogischen Wider der Amazonas Bay</Typography>
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
            </Box>
        </Container >
    )
}

const styles = {
    arrow: {
        width: 0,
        height: 0,
        display: 'inline-block',
        borderTop: '7px solid transparent',
        borderBottom: '7px solid transparent',
        borderLeft: '7px solid black',
        transform: 'rotate(221deg)',
        marginRight: '5px'
    },
    wrapper: {
        backgroundColor: "#f8f8f8",
        mt: 4,
        pl: 2.6,
        pt: 2.9,
        pb: 2.9,
        pr: 5.2,
        borderRadius: '3px',
        display: 'flex',
        alignItems: "center"
    }
}

export default CarbonFootprints