import React from 'react'

import { Box } from '@mui/material';

import { Header, SideNavigation, TopBar, PopUpMsg } from '../../components';
import { useDispatch, useSelector } from "react-redux";
import { closePopUp } from "../../store/reducer";

export default function ManagerLayout({ children }) {
    const { open, message, type } = useSelector((state) => state.storeReducer);
    const dispatch = useDispatch();

    return (
        <>
            <Header />
            <TopBar />
            <Box
                sx={{
                    display: 'flex'
                }}
            >
                <SideNavigation />
                <main>
                    {children}
                </main>
            </Box>
            <PopUpMsg
                open={open}
                type={type}
                message={message}
                handleClose={() => dispatch(closePopUp())}
            />
        </>
    )
}