import React, { useState, useEffect } from "react";

import {
  Container,
  Button,
  Box,
  CircularProgress,
  Switch,
  Typography,
  Tooltip,
  IconButton,
  Tabs,
  Tab,
  Stack,
} from "@mui/material";
import KeyboardReturnRoundedIcon from "@mui/icons-material/KeyboardReturnRounded";

import { useNavigate, useParams } from "react-router-dom";

import { ImageUploader, InputField } from "../../../components";
import { useDispatch, useSelector } from "react-redux";
import { openPopUp } from "../../../store/reducer";
import API from "../../../axios";
import { errorsSetter } from "../../../helpers/errors-setter";
import ApiImage from "../../../hooks/fetch-image";
import myTranslator from "../../../helpers/myTranslator";


export default function UpdateOrganization() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const id = useParams().id;
  const [isLoading, setIsLoading] = useState(false);
  const [value] = React.useState(0);
  const { selectedLang, translation } = useSelector((state) => state.storeReducer);
  const [formData, setFormData] = useState({
    title: "",
    description: "",
    status: true,
    _method: "PATCH",
  });
  const [formErrors, setFormErrors] = React.useState({});
  const [setOpen] = useState(false);
  const [payload, setPayload] = useState({
    type: "error",
    message: "",
  });

  useEffect(() => {
    getOrganization();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getOrganization = async () => {
    try {
      const res = await API(`admin/organizations/${id}`, "get");
      let data = res?.data;
      setFormData({
        ...formData,
        title: data?.title,
        description: data?.description,
        status: data?.active,
      });
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      setOpen(true);
      setPayload({ ...payload, message: err?.response?.message });
    }
  };

  const handleImage = (e) => {
    let file = e.target.files[0];
    setFormData({ ...formData, image: file });
  };

  const handleChange = (e) => {
    let { name, value } = e.target;
    if (name === "status") {
      setFormData({ ...formData, [name]: formData.status ? 0 : 1 });
    } else setFormData({ ...formData, [name]: value });
  };

  const handleForm = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    setFormErrors({});
    let fd = new FormData();
    for (const [key, value] of Object.entries(formData)) {
      fd.append(key, value);
    }
    try {
      await API(`admin/organizations/${id}`, "post", fd);
      setIsLoading(false);
      dispatch(
        openPopUp({
          message: "Organization has been updated.",
          type: "success",
        })
      );
      navigate("/admin/organizations");
    } catch (err) {
      if (err?.response?.status === 422) {
        setFormErrors(errorsSetter(err));
      } else {
        setOpen(true);
        setPayload({ type: 'error', message: err?.response?.message });
      }
      setIsLoading(false);
    }
  };

  return (
    <Container maxWidth="100%" sx={{ py: 3 }}>
      <Tooltip title={myTranslator(translation, selectedLang, "sa_org_update_tooltip_back")} placement="top">
        <IconButton onClick={() => navigate('/admin/organizations')}>
          <KeyboardReturnRoundedIcon />
        </IconButton>
      </Tooltip>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs
          aria-label={"basic tabs example"}
          value={value}
        >
          <Tab
            label={myTranslator(translation, selectedLang, "sa_org_update_basic_info")}
            onClick={() =>
              navigate(`/admin/organizations/${id}`, {
                id: id,
              })
            }
          />
          <Tab
            label={myTranslator(translation, selectedLang, "sa_org_update_users")}
            onClick={() =>
              navigate(`/admin/organizations/${id}/users`, {
                id: id,
              })
            }
          />
        </Tabs>
      </Box>
      <TabPanel value={value} index={0}>
        <form autoComplete="off" onSubmit={handleForm}>
          <Box maxWidth="500px" mx="auto" mt={2} mb={3}>
            <Stack spacing={2}>
              <Typography variant="h6" component="p" mb={2}>
                {myTranslator(translation, selectedLang, "sa_org_update_organization_info")}
              </Typography>
              <Stack
                justifyContent='center'
                alignItems='center'
              >
                <ImageUploader id={id} handleImage={handleImage} />
              </Stack>
              <InputField
                placeholder={myTranslator(translation, selectedLang, "sa_org_update_title")}
                label={myTranslator(translation, selectedLang, "sa_org_update_title")}
                name="title"
                initValue={formData.title}
                error={formErrors.title}
                required
                size="small"
                fullWidth
                handleChange={handleChange}
              />
              <InputField
                placeholder={myTranslator(translation, selectedLang, "sa_org_update_description")}
                label={myTranslator(translation, selectedLang, "sa_org_update_description")}
                name="description"
                initValue={formData.description}
                error={formErrors.description}
                required
                size="small"
                fullWidth
                styles={{ mb: 2 }}
                multiline={true}
                rows={5}
                handleChange={handleChange}
              />
              <Stack justifyContent="space-between" alignItems="center" direction="row">
                <label>{myTranslator(translation, selectedLang, "sa_org__update_status")}</label>
                <Switch
                  name={myTranslator(translation, selectedLang, "sa_org__update_status")}
                  checked={formData.status}
                  error={formErrors.status}
                  value={formData.status}
                  onChange={handleChange}
                  color="success"
                />
              </Stack>
              <Box sx={{ textAlign: "end" }}>
                <Button
                  variant="contained"
                  size="small"
                  onClick={() => { }}
                  sx={{
                    mt: 2,
                  }}
                  disabled={isLoading}
                  type="submit"
                >
                  {isLoading ? (
                    <CircularProgress
                      size={18}
                      sx={{ color: "#fff", mr: 1, size: "12px" }}
                    />
                  ) : null}
                  {myTranslator(translation, selectedLang, "sa_org_update_update")}
                </Button>
              </Box>
            </Stack>
          </Box>
        </form>
      </TabPanel>
    </Container>
  );
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          {children}
        </Box>
      )}
    </div>
  );
}
