
import {
  Home,
  Projects,
  PartsOverView,
  Activities,
  CostAnalysis,
  Customer,
  Building,
  PvSystem,
  PromotionsBattery,
  EconomyPv,
  ProjectOverview,
  ModuleLayout,
  Inverter,
  PowerConsumption,
  ElectricityTariffs,
  BatteryStorage,
  Documents,
  ElectroFormSolar,
  MultiSteps,
  AdHome,
  GetOrganization,
  AddOrganization,
  UpdateOrganization,
  GetComponentTypes,
  GetManufacturer,
  AdTranslation,
  GetModels,
  UpdateModels,
  ComponentsPanel,
  GroupPanel,
  ProjectTemplatePanel,
  CalculatorPanel,
  MainTemplatePanel,
  RequirementsPanel,
  EditComponentsPanel,
  EditGroupPanel,
  AdminComponentsPanel,
  AdminEditComponentsPanel,
  EditProjectTemplatePanel,
  OrganizationUsers,
  ModalGroup,
  ProjectSettings,
  GetEnergyTariff,
  EnergyTariffForm,
  GetPowerConsumption,
} from "../pages";

import ComingSoon from "../pages/coming-soon";

const managerRoutes = [
  {
    path: "/",
    element: <Home />,
    layout: false,
    type: "MANAGER",
  },
  {
    path: "/projects",
    element: <Projects />,
    layout: false,
    type: "MANAGER",
  },
  {
    path: "/activities",
    element: <ComingSoon />,
    layout: false,
    type: "MANAGER",
  },
  {
    path: "/library/components",
    element: <ComponentsPanel />,
    layout: false,
    type: "MANAGER",
  },
  {
    path: "/library/components/:id",
    element: <EditComponentsPanel />,
    layout: false,
    type: "MANAGER",
  },
 
  {
    path: "/library/groups",
    element: <GroupPanel />,
    layout: false,
    type: "MANAGER",
  },
  {
    path: "/library/groups/:id",
    element: <EditGroupPanel />,
    layout: false,
    type: "MANAGER",
  },
  {
    path: "/library/project-template",
    element: <ProjectTemplatePanel />,
    layout: false,
    type: "MANAGER",
  },
  {
    path: "/library/project-template/:id",
    element: <EditProjectTemplatePanel />,
    layout: false,
    type: "MANAGER",
  },
  {
    path: "/library/main-template",
    element: <MainTemplatePanel />,
    layout: false,
    type: "MANAGER",
  },
  {
    path: "/library/solar-calculator",
    element: <CalculatorPanel />,
    layout: false,
    type: "MANAGER",
  },
  {
    path: "/library/subsidies",
    element: <RequirementsPanel />,
    layout: false,
    type: "MANAGER",
  },
  {
    path: "/projects/:id/overview",
    element: <ProjectOverview />,
    layout: true,
    type: "MANAGER",
  },
  {
    path: "/projects/:id/cost-analysis",
    element: <CostAnalysis />,
    layout: true,
    type: "MANAGER",
  },
  {
    path: "/projects/:id/customer",
    element: <Customer />,
    layout: true,
    type: "MANAGER",
  },
  {
    path: "/projects/:id/project-settings",
    element: <ProjectSettings />,
    layout: true,
    type: "MANAGER",
  },
  {
    path: "/projects/:id/project-installations",
    element: <ComingSoon />,
    layout: true,
    type: "MANAGER",
  },
  {
    path: "/projects/:id/battery-storage",
    element: <BatteryStorage />,
    layout: true,
    type: "MANAGER",
  },
  {
    path: "/projects/:id/building",
    element: <Building />,
    layout: true,
    type: "MANAGER",
  },
  {
    path: "/projects/:id/pv-system",
    element: <PvSystem />,
    layout: true,
    type: "MANAGER",
  },
  {
    path: "/projects/:id/module-layout",
    element: <ModuleLayout />,
    layout: true,
    type: "MANAGER",
  },
  {
    path: "/projects/:id/inverter",
    element: <Inverter />,
    layout: true,
    type: "MANAGER",
  },
  {
    path: "/projects/:id/power-consumption",
    element: <PowerConsumption />,
    layout: true,
    type: "MANAGER",
  },
  {
    path: "/projects/:id/electricity-tariffs",
    element: <ElectricityTariffs />,
    layout: true,
    type: "MANAGER",
  },
  {
    path: "/projects/:id/pv-batteries",
    element: <PromotionsBattery />,
    layout: true,
    type: "MANAGER",
  },
  {
    path: "/projects/:id/eco-pv",
    element: <EconomyPv />,
    layout: true,
    type: "MANAGER",
  },
  {
    path: "/projects/:id/eco-battery-system",
    element: <ComingSoon />,
    layout: true,
    type: "MANAGER",
  },
  {
    path: "/projects/:id/parts-overview",
    element: <PartsOverView />,
    layout: true,
    type: "MANAGER",
  },
  {
    path: "/projects/:id/parts-pv",
    element: <Activities />,
    layout: true,
    type: "MANAGER",
  },
  {
    path: "/projects/:id/batteries-system",
    element: <ComingSoon />,
    layout: true,
    type: "MANAGER",
  },
  {
    path: "/projects/:id/recurring-cost",
    element: <ComingSoon />,
    layout: true,
    type: "MANAGER",
  },
  {
    path: "/projects/:id/patch-pv",
    element: <ComingSoon />,
    layout: true,
    type: "MANAGER",
  },
  {
    path: "/projects/:id/ele-form-solar",
    element: <ElectroFormSolar />,
    layout: true,
    type: "MANAGER",
  },
  {
    path: "/projects/:id/documents",
    element: <Documents />,
    layout: true,
    type: "MANAGER",
  },
  {
    path: "/projects/:id/steps",
    element: <MultiSteps />,
    layout: false,
    type: "MANAGER",
  },
];

const adminRoutes = [
  {
    path: "/admin",
    element: <AdHome />,
    layout: true,
    type: "SUPER_ADMIN",
  },
  {
    path: "/admin/organizations",
    element: <GetOrganization />,
    layout: true,
    type: "SUPER_ADMIN",
  },
  {
    path: "/admin/organizations/create",
    element: <AddOrganization />,
    layout: true,
    type: "SUPER_ADMIN",
  },
  {
    path: "/admin/organizations/:id",
    element: <UpdateOrganization />,
    layout: true,
    type: "SUPER_ADMIN",
  },
  {
    path: "/admin/organizations/:id/users",
    element: <OrganizationUsers />,
    layout: true,
    type: "SUPER_ADMIN",
  },
  {
    path: "/admin/component-types",
    element: <GetComponentTypes />,
    layout: true,
    type: "SUPER_ADMIN",
  },
  {
    path: "/admin/energy-tariffs",
    element: <GetEnergyTariff />,
    layout: true,
    type: "SUPER_ADMIN",
  },
  {
    path: "/admin/energy-tariffs/create",
    element: <EnergyTariffForm />,
    layout: true,
    type: "SUPER_ADMIN",
  },
  {
    path: "/admin/energy-tariffs/:id",
    element: <EnergyTariffForm />,
    layout: true,
    type: "SUPER_ADMIN",
  },
  {
    path: "/admin/manufacturer",
    element: <GetManufacturer />,
    layout: true,
    type: "SUPER_ADMIN",
  },
  {
    path: "/admin/models",
    element: <GetModels />,
    layout: true,
    type: "SUPER_ADMIN",
  },
  {
    path: "/admin/manufacturer/:manufacturer_id/model/:id",
    element: <UpdateModels />,
    layout: true,
    type: "SUPER_ADMIN",
  },
  {
    path: "/admin/manufacturer/:manufacturer_id/model/:id/groups",
    element: <ModalGroup />,
    layout: true,
    type: "SUPER_ADMIN",
  }, 
  {
    path: "/admin/translation",
    element: <AdTranslation />,
    layout: true,
    type: "SUPER_ADMIN",
  },
  {
    path: "/admin/components",
    element: <AdminComponentsPanel />,
    layout: true,
    type: "SUPER_ADMIN",
  },
  {
    path: "/admin/components/:id",
    element: <AdminEditComponentsPanel />,
    layout: true,
    type: "SUPER_ADMIN",
  },
  {
    path: "/admin/power-consumptions",
    element: <GetPowerConsumption />,
    layout: true,
    type: "SUPER_ADMIN",
  },
];

export { managerRoutes, adminRoutes };
