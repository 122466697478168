import React, { useState, useEffect } from 'react';
import {
    Box,
    Typography,
    Container,
    Stack,
    IconButton,
    TableRow,
    TableCell,
    Tooltip,
    ClickAwayListener
} from '@mui/material';
import SimCardDownloadIcon from '@mui/icons-material/SimCardDownload';
import AddIcon from '@mui/icons-material/Add';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';

import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import API from '../../../axios';
import { openPopUp } from '../../../store/reducer';
import PageTitle from '../../../hooks/page-title';
import {
    StartIconBtn,
    SaveChangesBtn,
    MyLoader,
    TableWrapper,
    SearchComboBox
} from '../../../components';

export default function Inverter() {
    PageTitle('Inverter');
    const { id } = useParams();
    const [showUpdateBtn, setShowUpdateBtn] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [addComponent, setAddComponent] = useState(false);
    const [toggler, setToggler] = useState(false);
    const [tableData, setTableData] = useState([]);
    const [removedComponents, setRemovedComponents] = useState([]);
    const [newAddedComponents, setNewAddedComponents] = useState([]);
    const _userRole = useSelector((state) => state.storeReducer.user.role);

    const dispatch = useDispatch();

    useEffect(() => {
        getData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getData = async () => {
        setIsLoading(true);
        try {
            let { data } = await API(`${_userRole}/pv-inverter/${id}`, 'get');
            if (!!data) {
                setTableData(data?.inverters || [])
            }
        } catch (error) {
            console.error("🚀 ~ file: inverter.js ~ getData ~ error", error)
        } finally {
            setIsLoading(false);
        }
    }

    const handleForm = async () => {
        let _data = {
            components: newAddedComponents,
            project_id: Number(id)
        }
        setIsLoading(true);
        try {
            await API(`${_userRole}/pv-inverters`, 'post', _data);
            setShowUpdateBtn(false);
            dispatch(openPopUp({
                open: true,
                message: 'Inverter has been updated.',
                type: 'success'
            }))
            setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
            dispatch(openPopUp({
                open: true,
                message: 'Error Occurred. While updating inverter.',
                type: 'error'
            }))
        }
    }

    const removeRow = (item, index) => {
        let arr = tableData;
        arr.splice(index, 1);
        setTableData(arr);
        let arr2 = newAddedComponents;
        let _index = arr2.findIndex(x => x === item.id);
        if (_index < 0) {
            setShowUpdateBtn(true);
            setRemovedComponents([...removedComponents, item.components.id]);
            return;
        } else {
            arr2.splice(_index, 1);
            setNewAddedComponents(arr2);
            setToggler(!toggler);
        }
    };

    const addRow = (item) => {
        let _index = tableData.findIndex(x => x.inverter.id === item.id);
        if (_index !== -1) {
            dispatch(
                openPopUp({
                    message: "Duplicate components are not allowed.",
                    type: "warning",
                })
            );
            return
        }
        setTableData([...tableData, { inverter: item }]);
        setNewAddedComponents([...newAddedComponents, item.id]);
        setShowUpdateBtn(true);
        setAddComponent(!addComponent);
    }

    return (
        <>
            <Container
                maxWidth="100%"
                sx={{ paddingTop: '12px', paddingBottom: '12px' }}
            >
                <Typography
                    variant="h6"
                    component="h6"
                    mt={"12px"}
                    mb={"10px"}
                >
                    INVERTER CONFIGURATION
                </Typography>
                <Typography
                    variant="p"
                    component="p"
                    sx={{
                        color: "#969696",
                        mb: 4
                    }}
                >
                    Configure the inverters of the system.
                </Typography>
                <Stack
                    direction={{ md: "row", xs: 'column' }}
                    align="stretch"
                    spacing={2}>
                    <Box sx={CardStyle}>
                        <p>OVERVIEW PV GENERATOR</p>
                        <table width='100%'>
                            <tbody>
                                <tr>
                                    <td><small>overall system</small></td>
                                    <td><small>10,000 kWp</small></td>
                                    <td><li><small>25 Module</small></li></td>
                                </tr>
                                <tr>
                                    <td><small>roof 1</small></td>
                                    <td><small>10,000 kWp</small></td>
                                    <td><li><small>25 Module</small></li></td>
                                </tr>
                            </tbody>
                        </table>
                    </Box>
                    <Box sx={CardStyle}>
                        <p>INVERTER</p>
                        <table width='100%'>
                            <tbody>
                                <tr>
                                    <td style={{ borderRight: '1px solid #C0C0C0', borderBottom: '1px solid #C0C0C0' }}></td>
                                    <td style={{ borderBottom: '1px solid #C0C0C0' }}><b><small>SolarEdge</small></b><br />
                                        <small>SE7K</small>
                                    </td>
                                </tr>
                                <tr>
                                    <td style={{ borderRight: '1px solid #C0C0C0' }}>
                                        <small>DC input</small><br />
                                        <small>AC power</small><br />
                                        <small>Number of DC inputs</small><br />
                                        <small>Number of MPP trackers</small><br />
                                    </td>
                                    <td>
                                        <small>9.45 kW</small><br />
                                        <small>7 kW</small><br />
                                        <small>2</small><br />
                                        <small>0</small><br />
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </Box>
                </Stack>
                <Typography
                    variant="h6"
                    component="h6"
                    mt={4}
                    mb={"10px"}
                >
                    INVERTER
                </Typography>
                <Stack
                    direction={{ md: 'row', xs: 'column' }}
                    alignItems='flex-end'
                    spacing={2}
                >
                    <TableWrapper
                        thContent={
                            thLabels.map((v, i) => (
                                <TableCell key={i}>{v}</TableCell>
                            ))
                        }
                        spanTd={thLabels.length}
                        isContent={tableData.length}
                        tableStyle={{minWidth:'800px'}}
                    >
                        {tableData?.map((v, i) => {
                            return (
                                <TableRow key={i}>
                                    <TableCell>{v?.inverter?.name || '-'} St</TableCell>
                                    <TableCell>{v?.inverter?.item_number || '-'}</TableCell>
                                    <TableCell>{v?.inverter?.item_number || '-'}</TableCell>
                                    <TableCell>-</TableCell>
                                    <TableCell>-</TableCell>
                                    <TableCell>
                                        <Tooltip title='Delete'>
                                            <IconButton
                                                onClick={() => removeRow(v, i)}
                                                color='error'
                                                disabled={isLoading}
                                            >
                                                <DeleteForeverIcon />
                                            </IconButton>
                                        </Tooltip>
                                    </TableCell>
                                </TableRow>
                            );
                        })}
                    </TableWrapper>
                    <Box>
                        <IconButton sx={{ backgroundColor: '#000', '&:hover': { backgroundColor: '#000' } }}>
                            <SimCardDownloadIcon sx={{ color: '#fff' }} />
                        </IconButton>
                    </Box>
                </Stack>
                <Stack
                    spacing={2}
                    direction={{ md: 'row', xs: 'column' }}
                    mt={4}
                >
                    <StartIconBtn
                        title="Add Component"
                        variant="contained"
                        size="small"
                        fullWidth
                        styles={{ maxWidth: "150px" }}
                        handleClick={() => setAddComponent(true)}
                        icon={<AddIcon />}
                    />
                    {addComponent &&
                        <ClickAwayListener onClickAway={() => setAddComponent(false)}>
                            <Box>
                                <SearchComboBox
                                    url={`${_userRole}/components?active=1&column=created_at&orderby=desc&`}
                                    objLabel="name"
                                    label='Components'
                                    size="small"
                                    placement='top'
                                    handleChange={addRow}
                                />
                            </Box>
                        </ClickAwayListener>
                    }
                </Stack>
                {showUpdateBtn &&
                    <SaveChangesBtn update={handleForm} cancel={() => setShowUpdateBtn(false)} />}
            </Container >
            {isLoading &&
                <MyLoader />
            }
        </>
    )
}

const thLabels = ["Manufacturer", "Typ", "Item number", "Main component template", "Crowd", "Action"];
const CardStyle = { '& p': { marginBottom: '15px' }, '& td': { padding: '5px', color: '#464646' }, p: 2, backgroundColor: '#FCFCFC', border: '1px solid #E8E8E8', borderRadius: '7px' }