import React from 'react';
import {
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Box
} from '@mui/material';
import RestaurantIcon from '@mui/icons-material/Restaurant';
import FlagIcon from '@mui/icons-material/Flag';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import SmartphoneIcon from '@mui/icons-material/Smartphone';
import Diversity3Icon from '@mui/icons-material/Diversity3';
import EmailIcon from '@mui/icons-material/Email';

export default function TypeDefinitionPanel() {
    return (
        <Box
            mt={3}
        >
            <Table
                sx={{
                    minWidth: 350,
                    '& .MuiTableCell-root': {
                        paddingTop: '10px',
                        paddingBottom: '10px',
                    }
                }}
            >
                <TableHead>
                    <TableRow
                        sx={{
                            backgroundColor: "#E6F5FE",
                        }}
                    >
                        <TableCell>
                            Icon
                        </TableCell>
                        <TableCell>
                            German (CH)
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    <TableRow>
                        <TableCell>
                            <RestaurantIcon />
                        </TableCell>
                        <TableCell>
                            Having lunch
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>
                            <FlagIcon />
                        </TableCell>
                        <TableCell>
                            First
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>
                            <AccessTimeIcon />
                        </TableCell>
                        <TableCell>
                            task
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>
                            <SmartphoneIcon />
                        </TableCell>
                        <TableCell>
                            Phone call
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>
                            <Diversity3Icon />
                        </TableCell>
                        <TableCell>
                            Meeting
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>
                            <EmailIcon />
                        </TableCell>
                        <TableCell>
                            Email
                        </TableCell>
                    </TableRow>
                </TableBody>
            </Table>
        </Box>
    );
}