import React, { useState } from "react";

import PageTitle from "../../../../hooks/page-title";
import LibraryLayout from "../library-layout";
import EditCalculatorPanel from "./edit-calculator-panel";
import ViewCalculatorPanel from "./view-calculator-panel";


export default function CalculatorPanel() {
    PageTitle('Groups');
    const [showEdit, setShowEdit] = useState(false);

    const switchScreen = () => setShowEdit(!showEdit)

    return (
        <LibraryLayout>
            {showEdit ?
                <EditCalculatorPanel switchScreen={switchScreen} />
                :
                <ViewCalculatorPanel switchScreen={switchScreen} />
            }
        </LibraryLayout>
    )
}