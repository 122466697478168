import React from 'react';
import {
    Container,
    Table,
    TableContainer,
    TableHead,
    TableBody,
    TableRow,
    TableCell,
    Checkbox,
    Button,
    Typography,
    Stack

} from '@mui/material';
import TaskIcon from '@mui/icons-material/Task';

export default function DataSheets() {
    return (
        <>
            <Container
                maxWidth="100%"
                sx={{ paddingTop: "15px", paddingBottom: "15px" }}
            >
                <TableContainer>
                    <Table
                        sx={{
                            minWidth: '1450px',
                            '& .MuiTableCell-root': {
                                paddingTop: "5px",
                                paddingBottom: "5px",
                            }
                        }}
                    >
                        <TableHead>
                            <TableRow
                                sx={{
                                    backgroundColor: "#E6F5FE",
                                }}
                            >
                                <TableCell
                                    sx={{ width: "10px", paddingLeft: 0, paddingRight: 0 }}
                                >
                                    <Checkbox color="success" />
                                </TableCell>
                                <TableCell>
                                    Name
                                </TableCell>
                                <TableCell>
                                    main component
                                </TableCell>
                                <TableCell>
                                    variant
                                </TableCell>
                                <TableCell>
                                    Languages
                                </TableCell>
                                <TableCell>
                                    Add in another language
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {Array(6).fill('').map((v, i) => {
                                return (
                                    <TableRow key={i}>
                                        <TableCell
                                            sx={{ width: "10px", paddingLeft: 0, paddingRight: 0 }}
                                        >
                                            <Checkbox color="success" />
                                        </TableCell>
                                        <TableCell>
                                            Standard
                                            <Typography
                                                variant='small'
                                                color='#969696'
                                                sx={{ display: 'block', fontSize: 12 }}
                                            >
                                                Audach bricks
                                            </Typography>
                                        </TableCell>
                                        <TableCell>
                                            substructure
                                        </TableCell>
                                        <TableCell>
                                            solar system
                                        </TableCell>
                                        <TableCell>
                                            <Stack
                                                direction="row"
                                                alignItems='center'
                                            >
                                                <TaskIcon fontSize='small' />
                                                OF
                                            </Stack>
                                        </TableCell>
                                        <TableCell>
                                            <Button
                                                sx={{
                                                    textTransform: 'none',
                                                    color: '#000',
                                                    borderColor: '#000',
                                                    backgroundColor: '#D9D9D9',
                                                }}
                                            >
                                                Send data  Sheet
                                            </Button>
                                        </TableCell>
                                    </TableRow>)
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>

            </Container>
        </>
    )
}
