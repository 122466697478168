import './App.css';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { BrowserRouter } from "react-router-dom";
import AppRoutes from './routes/app-routes';
import React from 'react';
import store from './store/store';
import { Provider } from 'react-redux';
import { grey } from '@mui/material/colors';

export default function App() {  
  const theme = createTheme({
    typography: {
      fontFamily: [
        'Inter',
      ].join(','),
    },
    palette:{
      primary: {
        thin: grey[50],
        exLight: grey[300],
        light: grey[500],
        main: grey[900],
      },
    }
  });

  return (
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <BrowserRouter>
          <AppRoutes />
        </BrowserRouter>
      </ThemeProvider>
    </Provider >
  );
}