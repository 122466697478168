import React, { useState } from 'react';
import {
    Box,
    Container,
    Paper,
    Typography,
    Stack,
    Button,
    CircularProgress,
} from "@mui/material";
import axios from "axios";
import { Link, useNavigate, useParams } from 'react-router-dom';

import PageTitle from "../../hooks/page-title";
import { PasswordInputField, PopUpMsg } from "../../components";

export default function ResetPassword() {
    PageTitle('Reset Password');
    const [isLoading, setIsLoading] = React.useState(false);
    const { token } = useParams();
    const [password, setPassword] = useState('');
    const [passwordErr, setPasswordErr] = useState('');
    const navigate = useNavigate();
    const [open, setOpen] = React.useState(false);
    const [payload, setPayload] = React.useState({
        type: "info",
        message: "",
    });

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true);
        const url = process.env.REACT_APP_API_KEY;
        try {
            let { data } = await axios.post(url + 'reset/password', {
                reset_token: token,
                password: password
            });
            setOpen(true);
            setPayload({ message: data, type: 'success' });
            setTimeout(() => {
                navigate('/sign-in');
            }, 2000);
        } catch (error) {
            setIsLoading(false);
            if (error?.response?.status === 422) {
                let { detail } = error?.response?.data;
                let _token = !!detail?.reset_token ? detail?.reset_token[0] : '';
                let _pass = !!detail?.password ? detail?.password[0] : '';
                setPasswordErr(_pass);
                if (!!_token) {
                    setOpen(true);
                    setPayload({ message: _token, type: 'error' });
                }
                return
            }
            setOpen(true);
            setPayload({ message: error?.response?.data?.message, type: 'error' });
        }
    }

    return (
        <>
            <div className='sign_in_page'>
                <Container maxWidth="100%" sx={{ pt: 2, pb: 2 }}>
                    <Box
                        component={Paper}
                        sx={{
                            maxWidth: "450px",
                            pt: 4,
                            pb: 1,
                            px: 4,
                        }}
                    >
                        <Typography
                            variant="h4"
                            component="h4"
                            sx={{
                                color: "#407BFF",
                                textAlign: "center",
                            }}
                        >
                            Forget Password
                        </Typography>
                        <Box
                            component="form"
                            onSubmit={handleSubmit}
                            sx={{ padding: { sm: "75px 0", xs: "25px 0" } }}
                        >
                            <Stack direction="column" spacing={2}>
                                {/* <InputField
                                    type="text"
                                    size="small"
                                    initValue={token}
                                    labelTop="Token"
                                    disabled
                                    fullWidth
                                    required
                                    error={tokenErr}
                                /> */}
                                <PasswordInputField
                                    name="password"
                                    placeholder="xxxxxx"
                                    size="small"
                                    labelTop="Password"
                                    fullWidth
                                    required
                                    error={passwordErr}
                                    handleChange={(e) => setPassword(e.target.value)}
                                />
                            </Stack>
                            <div style={{ marginTop: "35px" }}>
                                <Button
                                    variant="contained"
                                    fullWidth
                                    disabled={isLoading}
                                    type="submit"
                                    style={{
                                        backgroundColor: "#333",
                                        borderRadius: "25px",
                                        textTransform: "none",
                                    }}
                                >
                                    {isLoading ? (
                                        <CircularProgress
                                            size={18}
                                            sx={{ color: "#fff", mr: 1, size: "12px" }}
                                        />
                                    ) : null}
                                    Submit
                                </Button>
                            </div>
                        </Box>
                        <Link
                            to='/sign-in'
                            style={{
                                textAlign: 'center',
                                width: '100%',
                                display: 'block',
                                fontSize: '14px',
                                textDecoration: "none",
                                color: '#ccc'
                            }}
                        >Go back to signIn.</Link>
                    </Box>
                </Container>
            </div>
            <PopUpMsg
                open={!!open}
                type={payload.type}
                message={payload.message ?? "error occurred"}
                handleClose={() => setOpen(false)}
                duration={1500}
            />
        </>
    )
}
