import React, { useState, useEffect } from 'react'
import {
    Box,
    Container,
    FormControlLabel,
    FormControl,
    Grid,
    Typography,
    Stack,
    RadioGroup,
    Radio,
} from '@mui/material';

import { useDispatch, useSelector } from "react-redux";

import PageTitle from '../../../hooks/page-title';
import API from '../../../axios';
import {
    InputField,
    MyLoader,
    InfoHeading,
    SaveChangesBtn
} from "../../../components";
import { openPopUp } from '../../../store/reducer';
import { useParams } from 'react-router-dom';
import { errorsSetter } from '../../../helpers/errors-setter';

export default function Customer() {
    PageTitle('Customer');
    const { id } = useParams();
    const [showUpdateBtn, setShowUpdateBtn] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [formData, setFormData] = useState({
        _method: 'patch',
    });
    const [formErrors, setFormErrors] = useState({});
    const _userRole = useSelector((state) => state.storeReducer.user.role);

    const dispatch = useDispatch();

    useEffect(() => {
        getData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getData = async () => {
        setIsLoading(true);
        try {
            let { data } = await API(`${_userRole}/projects/${id}`, 'get');
            setIsLoading(false);
            setFormData({
                ...formData,
                customer_type: data?.customer_type || '',
                gender_type: data?.gender_type || '',
                first_name: data?.first_name || '',
                surname: data?.surname || '',
                email: data?.email || '',
                language: data?.language || '',
                street: data?.street || '',
                no: data?.no || '',
                postal_code: data?.postal_code || '',
                location: data?.location || '',
                land: data?.land || '',
                telephone: data?.telephone || '',
                phone_type: data?.phone_type || '',
                private_tel: data?.private_tel || '',
                mobile_phone: data?.mobile_phone || '',
                address: data?.address || '',
                project_template_id: Number(data?.project_template_id),
                organization_id: Number(data?.organizations?.id),
            });
        }finally{
            setIsLoading(false);
        }
    }

    const handleInput = (e) => {
        const { value, name } = e.target;
        setFormData({ ...formData, [name]: value });
        setShowUpdateBtn(true);
    }

    const handleRadio = (name, value) => {
        setFormData({ ...formData, [name]: value });
        setShowUpdateBtn(true);
    }

    const handleForm = async (e) => {
        e.preventDefault();
        setFormErrors({});
        let _fd = formData;
        setIsLoading(true);
        try {
            await API(`${_userRole}/projects/${id}`, 'post', _fd);
            setShowUpdateBtn(false);
            dispatch(openPopUp({
                open: true,
                message: 'Project has been updated.',
                type: 'success'
            }))
            setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
            setFormErrors(errorsSetter(error));
            dispatch(openPopUp({
                open: true,
                message: 'Error Occurred. While updating project.',
                type: 'error'
            }))
        }
    }

    const _pattern = "[0-9]{11}"

    return (
        <>
            <Container
                maxWidth="100%"
                sx={{ paddingTop: "15px", paddingBottom: "15px" }}
            >
                <Typography
                    variant="h5"
                    component="h5"
                    fontSize={"18px"}
                    mt={"12px"}
                    mb={"10px"}
                >
                    Customer
                </Typography>
                <Typography
                    variant="p"
                    component="p"
                    sx={{
                        color: "#969696",
                    }}
                >
                    Change the customer details
                </Typography>
                <Box
                    component='form'
                    autoComplete="off"
                    onSubmit={handleForm}
                >
                    <Typography variant="subtitle1" component="h5"
                        mt={2}
                        sx={{
                            color: '#464646'
                        }}
                    >
                        Customer Type
                    </Typography>
                    <FormControl>
                        <RadioGroup
                            row
                            name="controlled-radio-buttons-group">
                            <FormControlLabel value="private" control={
                                <Radio
                                    checked={formData.customer_type === 'private' ? true : false}
                                    onChange={() => handleRadio('customer_type', 'private')}
                                    color='success'
                                    size="small" />}
                                label='Private' />
                            <FormControlLabel value="business"
                                control={
                                    <Radio
                                        checked={formData.customer_type === 'company' ? true : false}
                                        onChange={() => handleRadio('customer_type', 'company')}
                                        color='success'
                                        size="small" />}
                                label='Company' />
                        </RadioGroup>
                    </FormControl>
                    <Typography variant="subtitle1" component="h5"
                        mt={2}
                        sx={{
                            color: '#464646'
                        }}>
                        Gender
                    </Typography>
                    <FormControl>
                        <RadioGroup
                            row
                            name="controlled-radio-buttons-group">
                            <FormControlLabel value="herr"
                                control={<Radio
                                    checked={formData.gender_type === 'mr' ? true : false}
                                    onChange={() => handleRadio('gender_type', 'mr')}
                                    color="success"
                                    size="small" />} label='Male' />
                            <FormControlLabel value="women"
                                control={<Radio
                                    checked={formData.gender_type === 'women' ? true : false}
                                    onChange={() => handleRadio('gender_type', 'women')}
                                    color="success"
                                    size="small" />} label='Female' />
                            <FormControlLabel value="not_specified"
                                control={<Radio
                                    checked={formData.gender_type === 'not_specified' ? true : false}
                                    onChange={() => handleRadio('gender_type', 'not_specified')}
                                    color="success"
                                    size="small" />} label='Not specified' />
                        </RadioGroup>
                    </FormControl>
                    <Grid container spacing={2} mt={1}>
                        <Grid item xs={12}>
                            <Grid container spacing={2}>
                                <Grid item lg={3} md={4} sm={6} xs={12}>
                                    <InputField
                                        fullWidth
                                        labelTop="First Name"
                                        required
                                        placeholder="First Name"
                                        size="small"
                                        name='first_name'
                                        initValue={formData.first_name}
                                        error={formErrors?.first_name}
                                        handleChange={handleInput}
                                    />
                                </Grid>
                                <Grid item lg={3} md={4} sm={6} xs={12}>
                                    <InputField
                                        initValue={formData.surname}
                                        error={formErrors?.surname}
                                        fullWidth
                                        labelTop="Surname"
                                        required
                                        placeholder="Surname"
                                        name="surname"
                                        size="small"
                                        handleChange={handleInput}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <Grid container spacing={2}>
                                <Grid item lg={3} sm={6} xs={12}>
                                    <Stack direction='row' spacing={1}>
                                        <Box sx={{ width: '100%' }}>
                                            <InputField
                                                initValue={formData.street}
                                                error={formErrors?.street}
                                                fullWidth
                                                labelTop="Street"
                                                placeholder="Street"
                                                size="small"
                                                name='street'
                                                handleChange={handleInput}
                                            />
                                        </Box>
                                        <Box sx={{ width: '100px' }}>
                                            <InputField
                                                initValue={formData.no}
                                                error={formErrors?.no}
                                                labelTop="No."
                                                type="number"
                                                size="small"
                                                name='no'
                                                handleChange={handleInput}
                                            />
                                        </Box>
                                    </Stack>
                                </Grid>
                                <Grid item lg={3} sm={6} xs={12}>
                                    <InputField
                                        initValue={formData.postal_code}
                                        error={formErrors?.postal_code}
                                        fullWidth
                                        labelTop="Postal Code"
                                        placeholder="Postal Code"
                                        type="number"
                                        size="small"
                                        name='postal_code'
                                        handleChange={handleInput}
                                    />
                                </Grid>
                                <Grid item lg={3} sm={6} xs={12}>
                                    <InputField
                                        initValue={formData.location}
                                        error={formErrors?.location}
                                        fullWidth
                                        labelTop="Location"
                                        placeholder="Location"
                                        size="small"
                                        name='location'
                                        handleChange={handleInput}
                                    />
                                </Grid>
                                <Grid item lg={3} sm={6} xs={12}>
                                    <InfoHeading
                                        text="Land"
                                        containerSx={{ mb: 1 }}
                                    />
                                    <InputField
                                        initValue={formData.land}
                                        error={formErrors?.land}
                                        fullWidth
                                        placeholder="Land"
                                        size="small"
                                        name='land'
                                        handleChange={handleInput}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <Grid container spacing={2}>
                                <Grid item lg={3} md={4} sm={6} xs={12}>
                                    <InputField
                                        initValue={formData.email}
                                        error={formErrors?.email}
                                        fullWidth
                                        labelTop="Email"
                                        placeholder="doe@example.com"
                                        type="email"
                                        size="small"
                                        name='email'
                                        handleChange={handleInput}
                                    />
                                </Grid>
                                <Grid item lg={3} md={4} sm={6} xs={12}>
                                    <InputField
                                        initValue={formData.private_tel}
                                        error={formErrors?.private_tel}
                                        fullWidth
                                        labelTop="Private telephone"
                                        placeholder="Private telephone"
                                        type="tel"
                                        size="small"
                                        name='private_tel'
                                        inputProps={{
                                            pattern: _pattern,
                                            title:'[098765432105]'
                                        }}
                                        handleChange={handleInput}
                                    />
                                </Grid>
                                <Grid item lg={3} md={4} sm={6} xs={12}>
                                    <InputField
                                        initValue={formData.mobile_phone}
                                        error={formErrors?.mobile_phone}
                                        fullWidth
                                        labelTop="Mobile phone"
                                        placeholder="Mobile phone"
                                        type="tel"
                                        size="small"
                                        name='mobile_phone'
                                        inputProps={{
                                            pattern: _pattern,
                                            title:'[098765432105]'
                                        }}
                                        handleChange={handleInput}
                                    />
                                </Grid>
                                <Grid item lg={3} md={4} sm={6} xs={12}>
                                    <InputField
                                        initValue={formData.telephone}
                                        error={formErrors?.telephone}
                                        fullWidth
                                        labelTop="Telephone store"
                                        placeholder="Telephone store"
                                        type="tel"
                                        size="small"
                                        name='telephone'
                                        inputProps={{
                                            pattern: _pattern,
                                            title:'[098765432105]'
                                        }}
                                        handleChange={handleInput}
                                    />
                                </Grid>
                                <Grid item md={8} xs={12}>
                                    <InfoHeading
                                        text="Building Address"
                                        containerSx={{ mb: 1 }}
                                    />
                                    <InputField
                                        initValue={formData?.address}
                                        error={formErrors?.address}
                                        fullWidth
                                        placeholder="Address..."
                                        size="small"
                                        name="address"
                                        handleChange={handleInput}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    {showUpdateBtn &&
                        <SaveChangesBtn type='submit' cancel={() => setShowUpdateBtn(false)} />}
                </Box>
            </Container>
            {isLoading &&
                <MyLoader />
            }
        </>
    )
}