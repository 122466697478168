import {
  Box,
  Button,
  TableCell,
  Tabs,
  Tab,
  IconButton,
  Tooltip,
} from "@mui/material";
import KeyboardReturnRoundedIcon from "@mui/icons-material/KeyboardReturnRounded";
import AddIcon from "@mui/icons-material/Add";
import React, { useState, useEffect } from "react";
import GroupTableRow from "./table-row";
import {
  SelectBox,
  StartIconBtn,
  TableWrapper,
} from "../../../components";

import API from "../../../axios";
import { openPopUp } from "../../../store/reducer";
import { useDispatch, useSelector } from "react-redux";
import { Container, Stack } from "@mui/system";
import { useNavigate, useParams } from "react-router-dom";
import { generateKey } from "../../../helpers/generate-key";
import myTranslator from "../../../helpers/myTranslator";



export default function ModalGroup() {
  const id = useParams().id;
  const manufacturer_id = useParams().manufacturer_id;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [modalInfo, setModalInfo] = useState();
  const [groups_, setGroups] = useState([]);
  const [groupSearch, setGroupSearch] = useState("");
  const [toggler, setToggler] = useState(false);
  const [value] = useState(1);
  const { selectedLang, translation } = useSelector((state) => state.storeReducer);
  
  useEffect(() => {
    getModelInfo();
    getGroups();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getModelInfo = async (e, _search = false) => {
    let search = "";
    if (_search) {
    } else {
      search = groupSearch;
    }
    try {
      const res = await API(
        `admin/modals/${id}/modalInformations?search=${search}`,
        "get"
      );
      setModalInfo(res?.data || []);

    } catch (err) {
      let obj = {
        message: err?.response?.message,
        type: "error",
      };
      dispatch(openPopUp(obj));
    }
  };

  const getGroups = async () => {
    try {
      const res = await API(`admin/modals/${id}/modalInformations?unique=1`, "get");
      setGroups(res?.data || []);
    } catch (err) {
      dispatch(openPopUp({ message: err?.response?.message, type: "error" }));
    }
  };

  const addHandle = (e) => {
    let group = groups_;
    group.push({ 'group': e })
    setGroups(group);
  }

  const printTh = () => {
    return (
      <>
        <td></td>
        <TableCell>Group</TableCell>
        <TableCell>Label</TableCell>
        <TableCell>Value</TableCell>
        <TableCell>Language</TableCell>
        <TableCell>Description</TableCell>
        <TableCell>Actions</TableCell>
      </>
    );
  };

  const removeRow = (index) => {
    let arr = modalInfo;
    arr.splice(index, 1);
    setModalInfo(arr);
    setToggler(!toggler);
  };

  const newRecord = () => {
    setModalInfo([
      ...modalInfo,
      {
        price_level: "",
        cost_price: "",
        calculation_surcharge: "",
        installation_cost: "",
        selling_price: "",
        editable: true,
        id: generateKey(),
      },
    ]);
  };

  return (
    <Container maxWidth="100%" sx={{ py: 3 }}>
      <Tooltip title="Back" placement="top">
        <IconButton onClick={() => navigate("/admin/models")}>
          <KeyboardReturnRoundedIcon />
        </IconButton>
      </Tooltip>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs
          aria-label="basic tabs example"
          value={value}
        >
          <Tab
            label="Basic Info"
            onClick={() =>
              navigate(
                `/admin/manufacturer/${manufacturer_id}/model/${id}`,
                {
                  id: id,
                  manufacturer_id: manufacturer_id,
                }
              )
            }
          />
          <Tab label="Groups" onClick={() =>
            navigate(
              `/admin/manufacturer/${manufacturer_id}/model/${id}/groups`,
              {
                id: id,
                manufacturer_id: manufacturer_id,
              }
            )
          } />
        </Tabs>
      </Box>
      <TabPanel value={value} index={1}>
        <Box>
          <Stack
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-end"
            spacing={1}
            flexWrap="wrap"
            sx={{ "& > div,& > button": { marginTop: "10px !important" } }}
          >
            <SelectBox
              items={!!groups_ && groups_.map((v) => v.group)}
              size="small"
              label="Search"
              initValue={groups_}
              handleChange={(item) => setGroupSearch(item.target.value)}
            />
            <Button
              variant="contained"
              sx={{
                backgroundColor: "#464646",
                borderRadius: "25px",
                textTransform: "none",
                "&:hover": {
                  backgroundColor: "#464646",
                },
              }}
              onClick={getModelInfo}
            >
              Search
            </Button>
            <Button
              variant="contained"
              sx={{
                backgroundColor: "#464646",
                borderRadius: "25px",
                textTransform: "none",
                "&:hover": {
                  backgroundColor: "#464646",
                },
              }}
              onClick={(e) => {
                getModelInfo(e, true);
              }}
            >
              All
            </Button>
          </Stack>
        </Box>
        <StartIconBtn
          title="Add Group"
          variant="contained"
          size="small"
          fullWidth
          styles={{ maxWidth: "150px", mt: 4 }}
          handleClick={newRecord}
          icon={<AddIcon />}
        />
        <Box component="div" sx={{ mt: 3 }}>
          <TableWrapper
            thContent={printTh()}
            isContent={modalInfo?.length}
            spanTd={7}
          >
            {modalInfo?.map((v, i) => {
              return (
                <GroupTableRow
                  key={v.id}
                  item={v}
                  index={i}
                  modalId={id}
                  afterSubmit={() => { getModelInfo(); getGroups(); }}
                  removeRow={() => removeRow(i)}
                  groups_={groups_}
                  addHandle={(e) => addHandle(e)}
                />
              );
            })}
          </TableWrapper>
        </Box>
      </TabPanel>

    </Container>
  );
}
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          {children}
        </Box>
      )}
    </div>
  );
}
