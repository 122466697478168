import React from 'react';
import {
    Switch,
    FormControlLabel,
    Box,
    Typography,
    Button,
    Checkbox,
    Grid,
    Tooltip,
    Stack
} from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';

import { InputField } from '../../../../components';

export default function ElectronicSigPanel() {
    return (
        <Box
            mt={3}
        >
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Typography variant='h6'>
                        DOCUSIGN
                    </Typography>
                    <p>Configure your settings for DocuSign here</p>
                </Grid>
                <Grid item xs={12}>
                    <Button variant="contained" sx={{ backgroundColor: '#000', color: '#fff', '&:hover': { backgroundColor: '#000', color: '#fff' } }}>
                        Link DocuSign Account
                    </Button>
                </Grid>
                <Grid item xs={12}>
                    <FormControlLabel control={<Switch color='success' />} label="Activate production mode" />
                </Grid>
                <Grid item xs={12}>
                    <Typography variant='h6'>
                        DEFAULT RECIPIENTS FOR EMAIL NOTIFICATIONS
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <FormControlLabel control={<Checkbox color='success' defaultChecked />} label="Configure your settings for DocuSign here" />
                </Grid>
                <Grid item md={4} xs={12}>
                    <InputField
                        label='first name'
                        initValue='Marcel'
                        fullWidth
                    />
                </Grid>
                <Grid item md={4} xs={12}>
                    <InputField
                        label='Surname'
                        initValue='Steer'
                        fullWidth
                    />
                </Grid>
                <Grid item md={4} xs={12}>
                    <InputField
                        label='Email Address'
                        initValue='marcel.steiert@gama-pv.ch'
                        fullWidth
                        disabled
                    />
                </Grid>
                <Grid item xs={12}>
                    <Button>Add New Status</Button>
                </Grid>
                <Grid item md={4} xs={12}>
                    <Stack
                        mb={3}
                        direction='row'
                        alignItems='center'
                        spacing={2}>
                        <Typography variant='h6'>
                            E-MAIL SUBJECT
                        </Typography>
                        <Tooltip title='info'>
                            <InfoIcon />
                        </Tooltip>
                    </Stack>
                    <InputField
                        label='German (CH)'
                        initValue='German (CH)'
                        fullWidth
                    />
                </Grid>
            </Grid>
        </Box>
    );
}