import React from 'react'
import {
    Box,
    Grid,
    Typography,
    Stack,
    Button,
    Table,
    TableRow,
    TableHead,
    TableBody,
    TableCell,
    TableContainer,
} from '@mui/material'
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';

export default function WorkFlowTempaltePanel() {
    return (
        <Box
            mt={3}
        >
            <Typography variant="h6">
                PROJECT STATUS
            </Typography>
            <Typography variant="p">
                Create, manage and delete the available status of your company's projects.
            </Typography>
            <Grid container spacing={3} mt={3}>
                <Grid item md={3} sm={6} xs={12}>
                    <Box
                        style={{ borderRadius: 2 }}
                    >
                        <Box
                            backgroundColor='#C2E9FF'
                            p={1}
                            px={2}
                            borderRadius={1}>
                            <Stack
                                direction='row'
                                justifyContent='space-between'
                                alignItems='center'
                            >
                                <Typography variant='h6'>
                                    sale
                                </Typography>
                                <Typography
                                    variant='p'
                                    color={'#fff'}
                                    backgroundColor='#C6C6C6'
                                    borderRadius={1}
                                    style={{
                                        minWidth: 40,
                                        height: 40,
                                        textAlign: 'center',
                                        paddingTop: 10,
                                    }}
                                >
                                    468
                                </Typography>
                            </Stack>
                        </Box>
                        <Box
                            backgroundColor='#F0FAFF'
                            borderRadius={1}
                            py={2}
                        >
                            <CardStrip />
                            <Box
                                style={{ textAlign: 'center' }}
                            >
                                <ArrowDropDownIcon />
                            </Box>
                            <CardStrip />
                            <Box
                                style={{ textAlign: 'center' }}
                            >
                                <ArrowDropDownIcon />
                            </Box>
                            <CardStrip />
                            <Box
                                textAlign="center"
                                mt={1}
                            >
                                <Button
                                    variant='text'
                                    sx={{
                                        textTransform: 'none',
                                        color: '#635E5E',
                                    }}
                                >
                                    Add new status
                                </Button>
                            </Box>
                        </Box>
                    </Box>
                </Grid>
                <Grid item md={3} sm={6} xs={12}>
                    <Box
                        style={{ borderRadius: 2 }}
                    >
                        <Box
                            backgroundColor='#C2E9FF'
                            p={1}
                            px={2}
                            borderRadius={1}>
                            <Stack
                                direction='row'
                                justifyContent='space-between'
                                alignItems='center'
                            >
                                <Typography variant='h6'>
                                    implementation
                                </Typography>
                                <Typography
                                    variant='p'
                                    color={'#fff'}
                                    backgroundColor='#C6C6C6'
                                    borderRadius={1}
                                    style={{
                                        minWidth: 40,
                                        height: 40,
                                        textAlign: 'center',
                                        paddingTop: 10
                                    }}
                                >
                                    90
                                </Typography>
                            </Stack>
                        </Box>
                        <Box
                            backgroundColor='#F0FAFF'
                            borderRadius={1}
                            py={2}
                        >
                            <CardStrip />
                            <Box
                                textAlign="center"
                                mt={1}
                            >
                                <Button
                                    variant='text'
                                    sx={{
                                        textTransform: 'none',
                                        color: '#635E5E',
                                    }}
                                >
                                    Add new status
                                </Button>
                            </Box>
                        </Box>
                    </Box>
                </Grid>
                <Grid item md={3} sm={6} xs={12}>
                    <Box
                        style={{ borderRadius: 2 }}
                    >
                        <Box
                            backgroundColor='#C2E9FF'
                            p={1}
                            px={2}
                            borderRadius={1}>
                            <Stack
                                direction='row'
                                justifyContent='space-between'
                                alignItems='center'
                            >
                                <Typography variant='h6'>
                                    After-Sales
                                </Typography>
                                <Typography
                                    variant='p'
                                    color={'#fff'}
                                    backgroundColor='#C6C6C6'
                                    borderRadius={1}
                                    style={{
                                        minWidth: 40,
                                        height: 40,
                                        textAlign: 'center',
                                        paddingTop: 10
                                    }}
                                >
                                    1
                                </Typography>
                            </Stack>
                        </Box>
                        <Box
                            backgroundColor='#F0FAFF'
                            borderRadius={1}
                            py={2}
                        >
                            <CardStrip />
                            <Box
                                textAlign="center"
                                mt={1}
                            >
                                <Button
                                    variant='text'
                                    sx={{
                                        textTransform: 'none',
                                        color: '#635E5E',
                                    }}
                                >
                                    Add new status
                                </Button>
                            </Box>
                        </Box>
                    </Box>
                </Grid>
                <Grid item md={3} sm={6} xs={12}>
                    <Box
                        style={{ borderRadius: 2 }}
                    >
                        <Box
                            backgroundColor='#C2E9FF'
                            p={1}
                            px={2}
                            borderRadius={1}>
                            <Stack
                                direction='row'
                                justifyContent='space-between'
                                alignItems='center'
                            >
                                <Typography variant='h6'>
                                    Closed
                                </Typography>
                                <Typography
                                    variant='p'
                                    color={'#fff'}
                                    backgroundColor='#C6C6C6'
                                    borderRadius={1}
                                    style={{
                                        minWidth: 40,
                                        height: 40,
                                        textAlign: 'center',
                                        paddingTop: 10
                                    }}
                                ></Typography>
                            </Stack>
                        </Box>
                        <Box
                            backgroundColor='#F0FAFF'
                            borderRadius={1}
                            py={2}
                        >
                            <CardStrip />
                            <Box
                                style={{ textAlign: 'center' }}
                            >
                                <ArrowDropDownIcon />
                            </Box>
                            <CardStrip />
                            <Box
                                textAlign="center"
                                mt={1}
                            >
                                <Button
                                    variant='text'
                                    sx={{
                                        textTransform: 'none',
                                        color: '#635E5E',
                                    }}
                                >
                                    Add new status
                                </Button>
                            </Box>
                        </Box>
                    </Box>
                </Grid>
            </Grid>
            <Box
                mt={3}
            >
                <Typography variant="h6">
                    PROJECT MARKERS
                </Typography>
                <Typography variant="p">
                    Define internal project makers
                </Typography>
            </Box>
            <Box
                mt={3}
            >
                <Stack
                    direction='row'
                >
                    <Button
                        variant="contained"
                        color='success'
                        startIcon={<CheckIcon />}
                        sx={{
                            textTransform: 'none',
                            marginRight: 1
                        }}
                    >
                        Sold
                    </Button>
                    <Button
                        variant="contained"
                        color='error'
                        startIcon={<CloseIcon />}
                        sx={{
                            textTransform: 'none',
                            marginRight: 1
                        }}
                    >
                        Lost
                    </Button>
                </Stack>
            </Box>
            <Box
                mt={3}
            >
                <Button
                    variant='text'
                    sx={{
                        textTransform: 'none'
                    }}
                >
                    <Typography variant='h6'>
                        Add new marker
                    </Typography>
                </Button>
            </Box>
            <Box
                mt={3}
            >
                <Typography variant="h6" mb={2}>
                    MANAGE TRANSLATIONS
                </Typography>
                <TableContainer
                    sx={{
                        maxWidth: 992,
                        borderRadius: 1
                    }}>
                    <Table
                        sx={{
                            minWidth: 650,
                            '& .MuiTableCell-root': {
                                paddingTop: '5px',
                                paddingBottom: '5px',
                            }
                        }}
                    >
                        <TableHead>
                            <TableRow
                                sx={{
                                    backgroundColor: "#E6F5FE",
                                }}
                            >
                                <TableCell>Typ</TableCell>
                                <TableCell>German (CH)</TableCell>
                                <TableCell>Webhook</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <TableRow>
                                <TableCell>Status</TableCell>
                                <TableCell>project created</TableCell>
                                <TableCell>Created</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>Status</TableCell>
                                <TableCell>project created</TableCell>
                                <TableCell>Created</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>Status</TableCell>
                                <TableCell>project created</TableCell>
                                <TableCell>Created</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>Status</TableCell>
                                <TableCell>project created</TableCell>
                                <TableCell>Created</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>Status</TableCell>
                                <TableCell>project created</TableCell>
                                <TableCell>Created</TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
            </Box>
        </Box>
    )
}

const CardStrip = () => {
    return (
        <>

            <Stack
                direction='row'
                alignItems='center'
                mt={1}
            >
                <DragIndicatorIcon color='disabled' />
                <Stack
                    direction='row'
                    alignItems='center'
                    justifyContent='space-between'
                    flex={0.9}
                    backgroundColor='#fff'
                    p={1}
                    borderRadius={1}
                >
                    <Typography variant='p' pl={1}>
                        project created
                    </Typography>
                    <Box
                        style={{ alignItems: 'center', display: 'flex' }}
                    >
                        <Typography
                            variant='p'
                            color={'#fff'}
                            p={1}
                            backgroundColor='#C6C6C6'
                            borderRadius={1}
                            style={{
                                minWidth: 40,
                                height: 40,
                                paddingTop: 10
                            }}
                        >
                            442
                        </Typography>
                        <MoreHorizIcon color='disabled' />
                    </Box>
                </Stack>
            </Stack>
        </>
    )
}

