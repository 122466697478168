import React from 'react'
import {
    Box,
    Container,
    Typography,
    Grid,
} from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';

const YourEnergySystem = () => {
    const min535px = useMediaQuery('(min-width:33.4375rem)');
    return (
        <Container maxWidth="100%" sx={{ paddingTop: "25px", paddingBottom: "105px" }}>
            <Box sx={{ display: 'flex', justifyContent: "space-between", alignItems: "center" }}>
                <Box>
                    <Typography component="h5" variant="h5" sx={{ fontSize: { xs: '1rem', md: "1.35rem" }, fontWeight: 500 }}>IHR ENERGIESYSTEM</Typography>
                    <Typography component="p" sx={{ fontSize: '0.77rem' }}>Qualitativ Hochwertige Komponenten</Typography>
                </Box>
                <img width={min535px ? "154" : "100"} src={require('../../../../../assets/images/logo.png')} alt="logo"></img>
            </Box>
            <Grid container sx={{ mt: 10 }}>
                <Grid md={9} xs={12} lg={6} sx={{ padding: '0.9375rem', paddingBottom: '1.8rem', border: '1px solid #CECECE', borderRadius: '4px' }}>
                    <Box sx={{ display: 'flex' }}>
                        <span style={styles.arrow}></span>
                        <Typography component="h6" variant="h6" sx={{ fontSize: { xs: '0.95rem', md: "1.3rem" }, fontWeight: 500 }}>SOLARMODULE</Typography>
                    </Box>
                    <Box sx={{ display: 'flex', flexDirection: { sm: "row", xs: "column" }, alignItems: 'center' }}>
                        <img style={{ width: '100%', maxWidth: '150px', borderRadius: '4px' }} src={require('../../../../../assets/images/placeholder.png')} alt="placeholder"></img>
                        <Box sx={{ ml: { md: 17, sm: 3 }, width: "100%", textAlign: { sm: "left", xs: "center" } }}>
                            <Typography component="p" sx={styles.head}>Deutsches Full Black Modul</Typography>
                            <Box sx={{ display: 'flex', alignItems: 'center', mt: 2, justifyContent: { sm: "left", xs: "center" } }}>
                                <Typography sx={styles.head}>Hersteller:</Typography>
                                <Typography sx={{ color: "#464646", ml: 1 }}>Solyco</Typography>
                            </Box>
                            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: { sm: "left", xs: "center" } }}>
                                <Typography sx={styles.head}>Typ:</Typography>
                                <Typography sx={{ color: "#464646", ml: 1 }}>R-BF 108p.3/400</Typography>
                            </Box>
                            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: { sm: "left", xs: "center" } }}>
                                <Typography sx={styles.head}>Leistung:</Typography>
                                <Typography sx={{ color: "#464646", ml: 1 }}>400 Wp</Typography>
                            </Box>
                            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: { sm: "left", xs: "center" } }}>
                                <Typography sx={styles.head}>Wirkungsgrad:</Typography>
                                <Typography sx={{ color: "#464646", ml: 1 }}>20.5%</Typography>
                            </Box>
                            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: { sm: "left", xs: "center" } }}>
                                <Typography sx={styles.head}>Herstellergarantie:</Typography>
                                <Typography sx={{ color: "#464646", ml: 1 }}>15 Jahre.</Typography>
                            </Box>
                            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: { sm: "left", xs: "center" } }}>
                                <Typography sx={styles.head}>Leistungsgarantie:</Typography>
                                <Typography sx={{ color: "#464646", ml: 1 }}>25 Jahre / 85.00%</Typography>
                            </Box>
                        </Box>
                    </Box>
                </Grid>
            </Grid>
            <Grid container sx={{ mt: 3 }}>
                <Grid md={9} xs={12} lg={6} sx={{ padding: '0.9375rem', paddingBottom: '1.8rem', border: '1px solid #CECECE', borderRadius: '4px' }}>
                    <Box sx={{ display: 'flex' }}>
                        <span style={styles.arrow}></span>
                        <Typography component="h6" variant="h6" sx={{ fontSize: { xs: '0.95rem', md: "1.3rem" }, fontWeight: 500 }}>WECHSELRICHTER</Typography>
                    </Box>
                    <Box sx={{ display: 'flex', flexDirection: { sm: "row", xs: "column" }, alignItems: 'center' }}>
                        <img style={{ width: '100%', maxWidth: '150px', borderRadius: '4px' }} src={require('../../../../../assets/images/placeholder.png')} alt="placeholder"></img>
                        <Box sx={{ ml: { md: 17, sm: 3 }, textAlign: { sm: "left", xs: "center" } }} >
                            <Typography component="p" sx={styles.head}>Deutsches Full Black Modul</Typography>
                            <Box sx={{ display: 'flex', alignItems: 'center', mt: 2, justifyContent: { sm: "left", xs: "center" } }}>
                                <Typography sx={styles.head}>Hersteller:</Typography>
                                <Typography sx={{ color: "#464646", ml: 1 }}>SolarEdge</Typography>
                            </Box>
                            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: { sm: "left", xs: "center" } }}>
                                <Typography sx={styles.head}>Typ:</Typography>
                                <Typography sx={{ color: "#464646", ml: 1 }}>SE7K</Typography>
                            </Box>
                            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: { sm: "left", xs: "center" } }}>
                                <Typography sx={styles.head}>Maximaler Wirkungsgrad:</Typography>
                                <Typography sx={{ color: "#464646", ml: 1 }}>98%</Typography>
                            </Box>
                            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: { sm: "left", xs: "center" } }}>
                                <Typography sx={styles.head}>Herstellergarantie:</Typography>
                                <Typography sx={{ color: "#464646", ml: 1 }}>12 Jahre</Typography>
                            </Box>
                            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: { sm: "left", xs: "center" } }}>
                                <Typography sx={styles.head}>Herkunft:</Typography>
                                <Typography sx={{ color: "#464646", ml: 1 }}>Israel</Typography>
                            </Box>
                        </Box>
                    </Box>
                </Grid>
            </Grid>
        </Container >
    )
}

const styles = {
    arrow: {
        width: 0,
        height: 0,
        display: 'inline-block',
        borderTop: '7px solid transparent',
        borderBottom: '7px solid transparent',
        borderLeft: '7px solid black',
        transform: 'rotate(221deg)',
        marginRight: '5px'
    },
    head: {
        fontSize: { xs: '0.95rem', md: "1.04rem" },
        fontWeight: 400
    }
}

export default YourEnergySystem