import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { openPopUp } from "../../../store/reducer";

import {
  Box,
  Button,
  CircularProgress,
  Container,
  Stack,
  Switch,
  Typography,
} from "@mui/material";
import { CustomModal, InputField } from "../../../components";
import API from "../../../axios";
import { errorsSetter } from "../../../helpers/errors-setter";

import myTranslator from "../../../helpers/myTranslator";
export default function ComponentType({
  open,
  handleClose,
  getData = () => { },
}) {
  const [isLoading, setIsLoading] = useState(false);
  const { selectedLang, translation } = useSelector((state) => state.storeReducer);  
  const [formData, setFormData] = useState({
    title: "",
    is_custom: 0,
    cycling: 0,
  });
  const [formErrors, setFormErrors] = React.useState({});

  const dispatch = useDispatch();

  const handleForm = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    setFormErrors({});
    try {
      await API("admin/component-types", "post", formData);
      setIsLoading(false);
      dispatch(
        openPopUp({
          message: myTranslator(translation,selectedLang,"sa_create_comp_alert_success"),
          type: "success",
        })
      );
      handleClose(false);
      getData();
    } catch (err) {
      if (err?.response?.status === 422) {
        setFormErrors(errorsSetter(err));
      } else {
        let obj = {
          message: err?.response?.message,
          type: "error"
        };
        dispatch(openPopUp(obj));
      }
      setIsLoading(false);
    }
  };
  return (
    <>
      <CustomModal
        open={!!open}
        handleClose={() => {
          handleClose();
        }}
      >
        <Container maxWidth="100%">
          <Box>
            <Typography component="h6" variant="h6" textAlign="">
           { myTranslator(translation,selectedLang,"sa_create_comp_heading")}
            </Typography>
          </Box>
          <form autoComplete="off" onSubmit={handleForm}>
            <Box mt={2} mb={3}>
              <InputField
                placeholder={myTranslator(translation,selectedLang,"sa_create_comp_label_title")}
                size="small"
                label={myTranslator(translation,selectedLang,"sa_create_comp_label_title")}
                required
                fullWidth
                styles={{ mb: 2 }}
                name="title"
                error={formErrors.title}
                handleChange={(e) =>
                  setFormData({ ...formData, title: e.target.value })
                }
              />
              <Stack justifyContent="space-between" direction="row">
                <label>{myTranslator(translation,selectedLang,"sa_create_comp_label_custom")}</label>
                <Switch
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      is_custom: e.target.checked ? 1 : 0,
                    })
                  }
                  color="success"
                />
              </Stack>
              {formData.is_custom ?
                <Stack justifyContent="space-between" direction="row">
                  <label>{myTranslator(translation,selectedLang,"sa_create_comp_label_cycling")}</label>
                  <Switch
                    onChange={(e) =>
                      setFormData({
                        ...formData,
                        cycling: e.target.checked ? 1 : 0,
                      })
                    }
                    color="success"
                  />
                </Stack>
                :null
              }
              <Box sx={{ textAlign: "end" }}>
                <Button
                  onClick={() => handleClose(false)}
                  sx={{
                    mt: 2,
                    mr: 1,
                  }}
                  type="button"
                >
                  {myTranslator(translation,selectedLang,"sa_create_comp_btn_cancel")}
                </Button>
                <Button
                  variant="contained"
                  onClick={() => { }}
                  sx={{
                    mt: 2,
                  }}
                  disabled={isLoading}
                  type="submit"
                >
                  {isLoading ? (
                    <CircularProgress
                      size={18}
                      sx={{ color: "#fff", mr: 1, size: "12px" }}
                    />
                  ) : null}
                 {myTranslator(translation,selectedLang,"sa_create_comp_btn_create")}
                </Button>
              </Box>
            </Box>
          </form>
        </Container>
      </CustomModal>
    </>
  );
}
