import React, { useState, useEffect } from "react";

import {
  Container,
  Box,
  TableRow,
  TableCell,
  Typography,
  Chip,
  IconButton,
  Tooltip,
} from "@mui/material";

import ModeEditIcon from "@mui/icons-material/ModeEdit";

import API from "../../../axios";
import { AddProjectBtn, TableWrapper, UsePagination } from "../../../components";
import CreateManufacturer from "./create-manufacturer";
import UpdateManufacturer from "./update-manufacturer";
import myTranslator from "../../../helpers/myTranslator";
import { useSelector } from "react-redux";

var tableCols = ["sa_mnu_get_tb_manufacturer", "sa_mnu_get_tb_component_type", "sa_mnu_get_tb_status", "sa_mnu_get_tb_action"];

export default function GetManufacturer() {
  const [isLoading, setIsLoading] = useState(false);
  const [manufacturer, setManufacturer] = useState(null);
  const [componentId, setComponentId] = useState(0);
  const [id, setId] = useState(0);
  const [page, setPage] = useState(1);
  const [createModal, setCreateModal] = useState(false);
  const [updateModal, setUpdateModal] = useState(false);
  const { selectedLang, translation } = useSelector((state) => state.storeReducer);  

  useEffect(() => {
    GetManufacturers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  const GetManufacturers = async () => {
    setIsLoading(true);
    const res = await API(`admin/manufacturers?page=${page}`, "get");
    try {
      setManufacturer(res.data);
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      setManufacturer(null);
    }
  };

  return (
    <>
      <Container maxWidth="100%" sx={{ py: 3 }}>
        <Typography variant="h6"  mb={2}>{myTranslator(translation,selectedLang,"sa_mnu_get_heading")}</Typography>
        <TableWrapper
          thContent={tableCols.map((column, index) => (
            <TableCell key={index}>{myTranslator(translation,selectedLang,column)}</TableCell>
          ))}
          spanTd={tableCols.length}
          isLoading={isLoading}
          isContent={!!manufacturer && manufacturer?.data.length ? true : false}
          tableStyle={{ minWidth: "400px" }}
        >
          {!!manufacturer &&
            manufacturer?.data.map((manufacturer, index) => (
              <TableRow key={index}>
                <TableCell>{manufacturer.manufacturer}</TableCell>
                <TableCell>{manufacturer.componenttypes?.title}</TableCell>
                <TableCell>
                  <Chip
                    label={manufacturer.active ? "active" : "disabled"}
                    color={manufacturer.active ? "success" : "default"}
                    size="small"
                  />
                </TableCell>
                <TableCell>
                  <Tooltip title={myTranslator(translation,selectedLang,"sa_mnu_get_tooltip_edit")} placement="top">
                    <IconButton
                      onClick={() => {
                        setUpdateModal(true);
                        setId(manufacturer.id);
                        setComponentId(manufacturer.component_type_id);
                      }}
                      color="primary"
                    >
                      <ModeEditIcon />
                    </IconButton>
                  </Tooltip>{" "}
                </TableCell>
              </TableRow>
            ))}
        </TableWrapper>

        {!!manufacturer && manufacturer?.last_page > 1 && (
          <Box component="div" sx={{ mt: 2 }}>
            <UsePagination
              total={manufacturer?.total}
              perPage={manufacturer?.per_page}
              page={page}
              setPage={setPage}
            />
          </Box>
        )}
      </Container>
      {createModal && (
        <CreateManufacturer
          handleClose={() => setCreateModal(false)}
          getData={GetManufacturers}
        />
      )}
      {updateModal && (
        <UpdateManufacturer
          id={id}
          setId={setId}
          componentId={componentId}
          setComponentId={setComponentId}
          handleClose={() => setUpdateModal(false)}
          getData={GetManufacturers}
        />
      )}
       <AddProjectBtn handleClick={() =>  setCreateModal(true)} tooltip={myTranslator(translation,selectedLang,"sa_mnu_get_tooltip_create_manufacturer")} />
    </>
  );
}
