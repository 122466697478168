import React from "react";
import {
    useMediaQuery,
    Box,
    Stack,
    Button,
    IconButton,
    Typography,
    Modal,
    CircularProgress,
    Backdrop,
    Snackbar,
    Grid,
} from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';

import { SelectBox } from "../../../../components";

export default function CreateComponentForm({
    open = false,
    handleClose = () => { } }) {
    const [showMessage, setShowMessage] = React.useState(false);
    const matches = useMediaQuery('(min-width:900px)');

    const handleForm = (e) => {
        e.preventDefault();
    }

    return (
        <>
            <Modal
                open={!!open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                sx={{
                    overflowY: 'auto',
                }}
            >
                <Box sx={matches ? modalStyle : modalStyle2}>
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'flex-end',
                        }}
                    >
                        <IconButton
                            onClick={handleClose}
                        >
                            <CloseIcon />
                        </IconButton>
                    </Box>
                    <Typography variant="h6" component="h5">
                        CREATE NEW MAIN COMPONENT TEMPLATE
                    </Typography>
                    {/* Form */}
                    <Box
                        component='form'
                        noValidate
                        autoComplete="off"
                        onSubmit={handleForm}
                        sx={{ mt: 6, mb: 4 }}
                    >
                        <Grid container spacing={2}>
                            <Grid item md={6} xs={12}>
                                <SelectBox
                                    items={['other component', 'battery storage', 'energy manager', 'Photovoltaikmodul', 'substructure', 'inverter', 'recurring costs']}
                                    size='small'
                                    label='For the main component'
                                    required
                                    fullWidth
                                />
                            </Grid>
                            <Grid item md={6} xs={12}>
                                <SelectBox
                                    items={['other component', 'battery storage', 'energy manager', 'Photovoltaikmodul', 'substructure', 'inverter', 'recurring costs']}
                                    size='small'
                                    label='Name of the main component template'
                                    required
                                    fullWidth
                                />
                            </Grid>
                        </Grid>
                        <Stack
                            direction='row'
                            spacing={1}
                            mt={7}
                        >
                            <Button
                                type="submit"
                                sx={{
                                    backgroundColor: '#000',
                                    '&:hover': {
                                        backgroundColor: '#000',
                                    },
                                    ...btn
                                }}
                            >
                                Add to
                            </Button>
                            <Button
                                type="button"
                                variant="contained"
                                sx={{
                                    backgroundColor: '#D71B1B',
                                    '&:hover': {
                                        backgroundColor: '#D71B1B',
                                    },
                                    ...btn
                                }}
                                onClick={handleClose}
                            >
                                Abort
                            </Button>
                        </Stack>
                    </Box>
                </Box>
            </Modal>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 999999 }}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
            <Snackbar
                open={!!showMessage}
                autoHideDuration={6000}
                onClose={() => setShowMessage(false)}
            />
        </>
    );
}

const btn = {
    color: '#fff',
    textTransform: 'none',
    paddingLeft: '25px',
    paddingRight: '25px',
}
const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '100%',
    maxWidth: '800px',
    bgcolor: '#fff',
    border: '0',
    borderRadius: '7px',
    boxShadow: 24,
    p: 4,
    zIndex: 100
};

const modalStyle2 = {
    width: '100%',
    maxWidth: '100%',
    bgcolor: '#fff',
    border: '0',
    borderRadius: '7px',
    boxShadow: 24,
    p: 4,
    zIndex: 100
};