import React from "react";
import PageTitle from "../../../hooks/page-title";
import {
  Container,
  Typography,
  Box,
  Divider,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  Paper,
  TableBody,
  Button,
  Popper,
  Grow,
  ClickAwayListener,
  MenuList,
  MenuItem,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&:before": {
    display: "none",
  },
}));

const AccordionSummary = styled((props) => <MuiAccordionSummary {...props} />)(
  ({ theme }) => ({
    backgroundColor: "#E6F5FE",
    color: "#464646",
    "& .MuiAccordionSummary-content": {
      justifyContent: "space-between",
    },
    "& .Mui-expanded p": {
      fontWeight: "600",
      color: "#000000",
    },
  })
);

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(0),
  borderTop: "1px solid rgba(0, 0, 0, .125)",
}));

export default function PartsOverView() {
  PageTitle("Projekte | GamaAg");
  const [expanded, setExpanded] = React.useState("panel1");

  // For Language Changes
  // const { t } = useTranslation();
  // function handleClick(lang) {
  //   i18next.changeLanguage(lang);
  // }

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  return (
    <>
      <Container
        maxWidth="100%"
        sx={{ paddingTop: "15px", paddingBottom: "15px" }}
      >
        <Typography
          variant="h5"
          component="h5"
          fontSize={"18px"}
          mb={"10px"}
        >
          GESAMTKOSTENÜBERSICHT
        </Typography>
        <Typography
          variant="p"
          component="p"
          sx={{
            color: "#969696",
          }}
        >
          Verchaffen Sie sich einen überblick über die Gesamtkosten ihres
          angebots and verwalten sie Rabatte
        </Typography>
        <TableContainer
        >
          <Box
            component="div"
            sx={{
              maxWidth: "750px",
              minWidth: "500px",
              mt: "20px",
              mb: "20px",
            }}
          >
            <Accordion
              expanded={expanded === "panel1"}
              onChange={handleChange("panel1")}
            >
              <AccordionSummary
                aria-controls="panel1d-content"
                id="panel1d-header"
              >
                <Typography>Collapsible Group Item #1</Typography>
                <Typography>Systempreis</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <table width={"100%"}>
                  <tbody>
                    <tr>
                      <td
                        align="left"
                        style={{
                          padding: "10px",
                          borderBottom: "1px solid rgba(0, 0, 0, .125)",
                        }}
                      >
                        <Typography color="#464646">
                          Total PV exkl. MWST
                        </Typography>
                      </td>
                      <td
                        align="right"
                        style={{
                          padding: "10px",
                          borderBottom: "1px solid rgba(0, 0, 0, .125)",
                        }}
                      >
                        <Typography color="#464646">
                          33’294.80 CHF
                        </Typography>
                      </td>
                    </tr>
                    <tr>
                      <td
                        align="left"
                        style={{
                          padding: "10px",
                          borderBottom: "1px solid rgba(0, 0, 0, .125)",
                        }}
                      >
                        <Typography color="#464646">
                          Total Batterie exkl. MWST
                        </Typography>
                      </td>
                      <td
                        align="right"
                        style={{
                          padding: "10px",
                          borderBottom: "1px solid rgba(0, 0, 0, .125)",
                        }}
                      >
                        <Typography color="#464646">
                          9’000.00 CHF
                        </Typography>
                      </td>
                    </tr>
                    <tr>
                      <td align="left" style={{ padding: "10px" }}>
                        <Typography color="#464646">
                          Total Rabatte
                        </Typography>
                      </td>
                      <td align="right" style={{ padding: "10px" }}>
                        <Typography color="#464646">0.00 CHF</Typography>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </AccordionDetails>
            </Accordion>
            <Accordion
              expanded={expanded === "panel2"}
              onChange={handleChange("panel2")}
            >
              <AccordionSummary
                aria-controls="panel2d-content"
                id="panel2d-header"
              >
                <Typography>Total Investmentkosten exkl. MWST</Typography>
                <Typography>42’294.80 CHF</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <table width={"100%"}>
                  <tbody>
                    <tr>
                      <td
                        align="left"
                        style={{
                          padding: "10px",
                          borderBottom: "1px solid rgba(0, 0, 0, .125)",
                        }}
                      >
                        <Typography color="#464646">
                          Total PV exkl. MWST
                        </Typography>
                      </td>
                      <td
                        align="right"
                        style={{
                          padding: "10px",
                          borderBottom: "1px solid rgba(0, 0, 0, .125)",
                        }}
                      >
                        <Typography color="#464646">
                          33’294.80 CHF
                        </Typography>
                      </td>
                    </tr>
                    <tr>
                      <td
                        align="left"
                        style={{
                          padding: "10px",
                          borderBottom: "1px solid rgba(0, 0, 0, .125)",
                        }}
                      >
                        <Typography color="#464646">
                          Total Batterie exkl. MWST
                        </Typography>
                      </td>
                      <td
                        align="right"
                        style={{
                          padding: "10px",
                          borderBottom: "1px solid rgba(0, 0, 0, .125)",
                        }}
                      >
                        <Typography color="#464646">
                          9’000.00 CHF
                        </Typography>
                      </td>
                    </tr>
                    <tr>
                      <td align="left" style={{ padding: "10px" }}>
                        <Typography color="#464646">
                          Total Rabatte
                        </Typography>
                      </td>
                      <td align="right" style={{ padding: "10px" }}>
                        <Typography color="#464646">0.00 CHF</Typography>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </AccordionDetails>
            </Accordion>
            <Accordion
              expanded={expanded === "panel3"}
              onChange={handleChange("panel3")}
            >
              <AccordionSummary
                aria-controls="panel3d-content"
                id="panel3d-header"
              >
                <Typography>Total Investmentkosten inkl. MWST</Typography>
                <Typography>45’551.50 CHF</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <table width={"100%"}>
                  <tbody>
                    <tr>
                      <td
                        align="left"
                        style={{
                          padding: "10px",
                          borderBottom: "1px solid rgba(0, 0, 0, .125)",
                        }}
                      >
                        <Typography color="#464646">
                          Total PV exkl. MWST
                        </Typography>
                      </td>
                      <td
                        align="right"
                        style={{
                          padding: "10px",
                          borderBottom: "1px solid rgba(0, 0, 0, .125)",
                        }}
                      >
                        <Typography color="#464646">
                          33’294.80 CHF
                        </Typography>
                      </td>
                    </tr>
                    <tr>
                      <td
                        align="left"
                        style={{
                          padding: "10px",
                          borderBottom: "1px solid rgba(0, 0, 0, .125)",
                        }}
                      >
                        <Typography color="#464646">
                          Total Batterie exkl. MWST
                        </Typography>
                      </td>
                      <td
                        align="right"
                        style={{
                          padding: "10px",
                          borderBottom: "1px solid rgba(0, 0, 0, .125)",
                        }}
                      >
                        <Typography color="#464646">
                          9’000.00 CHF
                        </Typography>
                      </td>
                    </tr>
                    <tr>
                      <td align="left" style={{ padding: "10px" }}>
                        <Typography color="#464646">
                          Total Rabatte
                        </Typography>
                      </td>
                      <td align="right" style={{ padding: "10px" }}>
                        <Typography color="#464646">0.00 CHF</Typography>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </AccordionDetails>
            </Accordion>
          </Box>
        </TableContainer>
        <Divider />
        <Typography
          variant="h5"
          component="h5"
          fontSize={"18px"}
          mt={"20px"}
          mb={"10px"}
        >
          PV-SYSTEM
        </Typography>
        <Box>
          <TableContainer
            component={Paper}
            sx={{
              borderRadius: 0,
            }}
          >
            <Table sx={{
              minWidth: 650,
              '& .MuiTableCell-root': {
                paddingTop: '5px',
                paddingBottom: '5px',
              }
            }}>
              <TableHead>
                <TableRow
                  sx={{
                    backgroundColor: "#E6F5FE",
                  }}
                >
                  <TableCell align="left">Komponent</TableCell>
                  <TableCell align="center">Artikelnummer</TableCell>
                  <TableCell align="center">Menge</TableCell>
                  <TableCell align="center">Einheit</TableCell>
                  <TableCell align="center"></TableCell>
                  <TableCell align="center">Einheitspreis</TableCell>
                  <TableCell align="center">Rabatt</TableCell>
                  <TableCell align="center">Preis</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow
                  sx={{
                    backgroundColor: "#F9FDFF",
                  }}
                >
                  <TableCell align="left">
                    <Typography variant="subtitle2" component="p">
                      PV-Model
                    </Typography>
                  </TableCell>
                  <TableCell />
                  <TableCell />
                  <TableCell />
                  <TableCell />
                  <TableCell />
                  <TableCell />
                  <TableCell align="center">
                    <Typography variant="subtitle2" component="p">
                      6’250.00 CHF
                    </Typography>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell align="left">
                    Solyco - R-BF 108p.3/400
                  </TableCell>
                  <TableCell align="center">51</TableCell>
                  <TableCell align="center">25.00</TableCell>
                  <TableCell align="center">St.</TableCell>
                  <TableCell />
                  <TableCell align="center">250.00 CHF</TableCell>
                  <TableCell align="center">0.00%</TableCell>
                  <TableCell align="center">
                    <DropDown />
                  </TableCell>
                </TableRow>
                <TableRow
                  sx={{
                    backgroundColor: "#F9FDFF",
                  }}
                >
                  <TableCell align="left">
                    <Typography variant="subtitle2" component="p">
                      Wechselrichter
                    </Typography>
                  </TableCell>
                  <TableCell />
                  <TableCell />
                  <TableCell />
                  <TableCell />
                  <TableCell />
                  <TableCell />
                  <TableCell align="center">
                    <Typography variant="subtitle2" component="p">
                      1’700.00 CHF
                    </Typography>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell align="left">SolarEdge SE7K</TableCell>
                  <TableCell align="center">32</TableCell>
                  <TableCell align="center">1.00</TableCell>
                  <TableCell align="center">St.</TableCell>
                  <TableCell />
                  <TableCell align="center">1’700.00 CHF</TableCell>
                  <TableCell align="center">0.00%</TableCell>
                  <TableCell align="center">
                    <DropDown />
                  </TableCell>
                </TableRow>
                <TableRow
                  sx={{
                    backgroundColor: "#F9FDFF",
                  }}
                >
                  <TableCell align="left">
                    <Typography variant="subtitle2" component="p">
                      Unterkonstruktion
                    </Typography>
                  </TableCell>
                  <TableCell />
                  <TableCell />
                  <TableCell />
                  <TableCell />
                  <TableCell />
                  <TableCell />
                  <TableCell align="center">
                    <Typography variant="subtitle2" component="p">
                      6’834.80 CHF
                    </Typography>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell align="left">
                    Novotegra - side-fx Einlegsystem Ziegel
                  </TableCell>
                  <TableCell align="center">26</TableCell>
                  <TableCell align="center">48.82</TableCell>
                  <TableCell align="center">m²</TableCell>
                  <TableCell align="center">
                    <div
                      style={{
                        backgroundColor: "#717171",
                        borderRadius: 2,
                        color: "#fff",
                        width: "fit-content",
                        padding: "2px 7px",
                      }}
                    >
                      Auto
                    </div>
                  </TableCell>
                  <TableCell align="center">140.00 CHF</TableCell>
                  <TableCell align="center">0.00%</TableCell>
                  <TableCell align="center">
                    <DropDown />
                  </TableCell>
                </TableRow>
                <TableRow
                  sx={{
                    backgroundColor: "#F9FDFF",
                  }}
                >
                  <TableCell align="left">
                    <Typography variant="subtitle2" component="p">
                      Adhere komponenten
                    </Typography>
                  </TableCell>
                  <TableCell />
                  <TableCell />
                  <TableCell />
                  <TableCell />
                  <TableCell />
                  <TableCell />
                  <TableCell align="center">
                    <Typography variant="subtitle2" component="p">
                      18’510.00 CHF
                    </Typography>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell align="left">
                    Uberspannungsschutz DC-seitig Typ II
                  </TableCell>
                  <TableCell align="center">45</TableCell>
                  <TableCell align="center">1.00</TableCell>
                  <TableCell align="center">St.</TableCell>
                  <TableCell />
                  <TableCell align="center">500.00 CHF</TableCell>
                  <TableCell align="center">0.00%</TableCell>
                  <TableCell align="center">
                    <DropDown />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell align="left">
                    Solarkabel und Erdungskabel
                  </TableCell>
                  <TableCell align="center">36</TableCell>
                  <TableCell align="center">1.00</TableCell>
                  <TableCell align="center">St.</TableCell>
                  <TableCell />
                  <TableCell align="center">500.00 CHF</TableCell>
                  <TableCell align="center">0.00%</TableCell>
                  <TableCell align="center">
                    <DropDown />
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Container>
    </>
  );
}

const DropDown = () => {
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  function handleListKeyDown(event) {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpen(false);
    } else if (event.key === "Escape") {
      setOpen(false);
    }
  }

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(open);
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);
  return (
    <>
      <Button
        variant="text"
        aria-controls={open ? "composition-menu" : undefined}
        aria-expanded={open ? "true" : undefined}
        aria-haspopup="true"
        disableElevation
        onClick={handleToggle}
        ref={anchorRef}
        endIcon={open ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
        sx={{
          color: "#000",
          textTransform: "none",
          fontWeight: "400",
        }}
      >
        1’700.00 CHF
      </Button>
      <Popper
        open={!!open}
        anchorEl={anchorRef.current}
        role={undefined}
        placement="bottom-start"
        transition
        disablePortal
        sx={{
          zIndex: 1,
        }}
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === "bottom-start" ? "left top" : "left bottom",
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList
                  autoFocusItem={open}
                  id="composition-menu"
                  aria-labelledby="composition-button"
                  onKeyDown={handleListKeyDown}
                >
                  <MenuItem onClick={handleClose}>1’700.00 CHF</MenuItem>
                  <MenuItem onClick={handleClose}>1’700.00 CHF</MenuItem>
                  <MenuItem onClick={handleClose}>1’700.00 CHF</MenuItem>
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );
};
