import React from 'react';
import {
  Container,
  Table,
  TableContainer,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Checkbox,

} from '@mui/material';
import { FilterLabel } from '../../../../components';

export default function FileManager() {
  return (
    <>
      <Container
        maxWidth="100%"
        sx={{ paddingTop: "15px", paddingBottom: "15px" }}
      >
        <TableContainer>
          <Table
            sx={{
              minWidth: '1450px',
              '& .MuiTableCell-root': {
                paddingTop: "5px",
                paddingBottom: "5px",
              }
            }}
          >
            <TableHead>
              <TableRow
                sx={{
                  backgroundColor: "#E6F5FE",
                }}
              >
                <TableCell
                  sx={{ width: "10px", paddingLeft: 0, paddingRight: 0 }}
                >
                  <Checkbox color="success" />
                </TableCell>
                <TableCell>
                  <FilterLabel title={'filename'} />
                </TableCell>
                <TableCell>
                  <FilterLabel title={'Project variant'} />
                </TableCell>
                <TableCell>
                  {'Creation Date'}
                </TableCell>
                <TableCell>
                  <FilterLabel title={'user'} />
                </TableCell>
                <TableCell>
                  <FilterLabel title={'Format'} />
                </TableCell>
                <TableCell>
                  {'Typ'}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {Array(2).fill('').map((v, i) => {
                return (
                  <TableRow key={i}>
                    <TableCell
                      sx={{ width: "10px", paddingLeft: 0, paddingRight: 0 }}
                    >
                      <Checkbox color="success" />
                    </TableCell>
                    <TableCell>
                      202210311_Breu_Berneck.pdf
                    </TableCell>
                    <TableCell>
                      solar system
                    </TableCell>
                    <TableCell>
                      31.10.2022 | 14:26
                    </TableCell>
                    <TableCell />
                    <TableCell>
                      PDF
                    </TableCell>
                    <TableCell>
                      Documents
                    </TableCell>
                  </TableRow>)
              })}
            </TableBody>
          </Table>
        </TableContainer>

      </Container>
    </>
  )
}
