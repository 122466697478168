
import { generateKey } from "../../../helpers/generate-key";

const langOptions = [
    { label: 'Deutsch (CH)', value: 'dutch' },
    { label: 'English', value: 'english' }
];

const techOptions = [
    { label: 'Battery Storage', value: 'battery_storage' },
    { label: 'Energy Management', value: 'energy_management' },
    { label: 'Photovoltaic', value: 'photovoltaic' }
];

const statusOpt = [
    { label: 'Project Created', value: 'project_created' },
    { label: 'Offer Expected', value: 'offer_expected' },
    { label: 'Offer Sent', value: 'offer_sent' },
    { label: 'Sold', value: 'sold' },
    { label: 'Customer Service', value: 'customer_service' },
    { label: 'Offer Made', value: 'offer_made' },
    { label: 'Offer Changed', value: 'offer_changed' },
    { label: 'Offer Rejected', value: 'offer_rejected' },
    { label: 'Project Completed', value: 'project_completed' },
];

const _columns = [
    {
        id: generateKey(),
        label: "Project References",
        value: "reference",
        show: true,
        type: 'order_label',
    },
    {
        id: generateKey(),
        label: "Project Name",
        value: "title",
        show: true,
        type: 'order_label',
    },
    {
        id: generateKey(),
        label: "Technologies",
        value: "technologies",
        show: true,
        type: 'simple_label',
    },
    {
        id: generateKey(),
        label: "To sue",
        value: "to_sue",
        show: true,
        type: 'switch_label',
    },
    {
        id: generateKey(),
        label: "Project Manager",
        value: "project_manager",
        show: true,
        type: 'simple_label',
    },
    {
        id: generateKey(),
        label: "Customer",
        value: "customer",
        show: true,
        type: 'simple_label',
    },
    {
        id: generateKey(),
        label: "Building Address",
        value: "building_address",
        show: true,
        type: 'simple_label',
    },
    {
        id: generateKey(),
        label: "Status",
        value: "project_status",
        show: true,
        type: 'simple_label',
    },
    {
        id: generateKey(),
        label: "Project Costs",
        value: "projects",
        show: true,
        type: 'simple_label',
    },
    {
        id: generateKey(),
        label: "Last Updated",
        value: "updated_at",
        show: true,
        type: 'order_label',
    },
];

const rowsOption = [
    { label: "25 Rows", value: "25" },
    { label: "50 Rows", value: "50" },
    { label: "75 Rows", value: "75" },
    { label: "100 Rows", value: "100" },
];


export {
    langOptions,
    techOptions,
    statusOpt,
    _columns,
    rowsOption
}