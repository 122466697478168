import { createSlice } from "@reduxjs/toolkit";

export const myReducer = createSlice({
  name: "storeReducer",
  initialState: {
    isLogged: false,
    user: null,
    token: null,
    openMenu: true,
    open: false,
    type: "success",
    message: "",
    projectID: null,
    translation: null,
    selectedLang: 'english',
  },

  reducers: {
    toggleMenu: (state, { payload }) => {
      state.openMenu = payload;
    },
    storeUser: (state, { payload }) => {
      state.isLogged = true;
      state.user = payload;
    },
    storeTranslation: (state, { payload }) => {
      state.translation = payload;
    },
    langSetter: (state, { payload }) => {
      state.selectedLang = payload;
    },
    loginUser: (state, { payload }) => {
      state.isLogged = true;
      localStorage.setItem("@ACCESS_TOKEN", payload?.access_token);
      state.user = payload?.user;
      state.token = payload?.access_token;
      state.translation = payload?.translation;
    },
    registerUser: (state, { payload }) => {
      localStorage.setItem("@ACCESS_TOKEN", payload.token);
      state.isLogged = true;
      state.user = payload.user;
      state.token = payload.token;
    },
    logout: (state, { payload }) => {
      localStorage.removeItem('@ACCESS_TOKEN');
      state.isLogged = false;
      state.user = null;
      state.token = null;
      state.translation = payload;
    },
    openPopUp: (state, { payload }) => {
      state.message = payload?.message;
      state.type = payload?.type ?? state.type;
      state.open = true;
    },
    closePopUp: (state, data) => {
      state.open = false;
    },
    setProjectID: (state, { payload }) => {
      state.projectID = payload;
    },
    clearProjectID: (state) => {
      state.projectID = null;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  storeUser,
  toggleMenu,
  loginUser,
  registerUser,
  logout,
  closePopUp,
  openPopUp,
  storeTranslation,
  setProjectID,
  clearProjectID,
  langSetter
} =
  myReducer.actions;

export default myReducer.reducer;
