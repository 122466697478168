import React from 'react';
import {
    Box,
    Typography,
    Container,
    Grid,
    Paper,
    TableContainer,
    Table,
    TableBody,
    TableRow,
    TableCell,
} from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';

import PageTitle from '../../../hooks/page-title';

export default function ProjectOverview() {
    PageTitle('Project Overview');

    return (
        <Container
            maxWidth="100%"
            sx={{ paddingTop: '12px', paddingBottom: '12px' }}
        >
            <Typography
                variant="h6"
                component="h6"
                mb={"10px"}
            >
                ProjectOverview
            </Typography>
            <Typography
                variant="p"
                component="p"
                sx={{
                    color: "#969696",
                    mb: 4
                }}
            >
                PV
            </Typography>
            <Grid container spacing={2} mt={3}>
                <Grid item lg={3} sm={6} xs={12}>
                    <Box sx={cardStyle} component={Paper}>
                        <p>ECONOMICS</p>
                        <table width='100%'>
                            <tbody>
                            <tr>
                                <td><small>Internal rate of return (IRR)</small></td>
                                <td><small>0.08%</small></td>
                            </tr>
                            <tr>
                                <td><small>production costs</small></td>
                                <td><small>13.06 Rp./kWh</small></td>
                            </tr>
                            <tr>
                                <td><small>payback period</small></td>
                                <td><small>24.8 years</small></td>
                            </tr>
                            </tbody>
                        </table>
                    </Box>
                </Grid>
                <Grid item lg={3} sm={6} xs={12}>
                    <Box sx={cardStyle} component={Paper}>
                        <p style={{ textAlign: 'center' }}>INDEPENDENCE</p>
                        <Box sx={{ position: 'relative', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <CircularProgress color='success' variant="determinate" size={100} value={40} />
                            <Box
                                sx={{
                                    top: 0,
                                    left: 0,
                                    bottom: 0,
                                    right: 0,
                                    position: 'absolute',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                }}
                            >
                                <Typography variant="caption" component="div">
                                    40%
                                </Typography>
                            </Box>
                        </Box>
                    </Box>
                </Grid>
                <Grid item lg={3} sm={6} xs={12}>
                    <Box sx={cardStyle} component={Paper}>
                        <p style={{ textAlign: 'center' }}>OWNCONSUMPTION</p>
                        <Box sx={{ position: 'relative', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <CircularProgress variant="determinate" size={100} value={60} />
                            <Box
                                sx={{
                                    top: 0,
                                    left: 0,
                                    bottom: 0,
                                    right: 0,
                                    position: 'absolute',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                }}
                            >
                                <Typography variant="caption" component="div">
                                    60%
                                </Typography>
                            </Box>
                        </Box>
                    </Box>
                </Grid>
                <Grid item lg={3} sm={6} xs={12}>
                    <Box sx={cardStyle} component={Paper}>
                        <p>SYSTEMDETAILS</p>
                        <table width='100%'>
                            <tbody>
                            <tr>
                                <td><small>number of modules</small></td>
                                <td><small>34</small></td>
                            </tr>
                            <tr>
                                <td><small>plant performance</small></td>
                                <td><small>13.60 kWh</small></td>
                            </tr>
                            <tr>
                                <td><small>battery capacity</small></td>
                                <td><small>10.00 kWh</small></td>
                            </tr>
                            <tr>
                                <td><small>Annual Income</small></td>
                                <td><small>14'974.37 kWh</small></td>
                            </tr>
                                </tbody>
                        </table>
                    </Box>
                </Grid>
            </Grid>
            <Grid container mt={3}>
                <Grid item md={4} sm={6} xs={12}>
                    <TableContainer>
                        <Table>
                            <TableBody>
                                <TableRow>
                                    <TableCell>
                                        <Typography>
                                            ECONOMICS
                                        </Typography>
                                    </TableCell>
                                    <TableCell>
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>
                                        <Typography variant='caption'>
                                            PV system incl. Discount
                                        </Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography variant='caption' color='text.secondary'>
                                            31'120.45 CHF
                                        </Typography>
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>
                                        <Typography variant='caption'>
                                            Battery system incl. discount
                                        </Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography variant='caption' color='text.secondary'>
                                            7'363.15 CHF
                                        </Typography>
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>
                                        <Typography variant='caption'>
                                            VAT 7.7%
                                        </Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography variant='caption' color='text.secondary'>
                                            2'963.24 CHF
                                        </Typography>
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>
                                        <Typography variant='caption'>
                                            <strong>Total incl. VAT</strong>
                                        </Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography variant='caption'>
                                            <strong>2'963.24 CHF</strong>
                                        </Typography>
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>
                                        <Typography variant='caption'>
                                            investment promotion
                                        </Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography variant='caption'>
                                            -5'518.00 CHF
                                        </Typography>
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>
                                        <Typography variant='caption'>
                                            Additional Promotions
                                        </Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography variant='caption'>
                                            -4'500.00 CHF
                                        </Typography>
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>
                                        <Typography variant='caption'>
                                            investment
                                        </Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography variant='caption'>
                                            -4'500.00 CHF
                                        </Typography>
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>
                                        <Typography variant='caption'>
                                            Expected tax savings
                                        </Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography variant='caption'>
                                            -4'500.00 CHF
                                        </Typography>
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>
                                        <Typography variant='caption'>
                                            actual cost
                                        </Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography variant='caption'>
                                            -4'500.00 CHF
                                        </Typography>
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
            </Grid>
        </Container >
    )
}

const cardStyle = { '& p': { marginBottom: '15px' }, '& td': { padding: '5px', color: '#464646' }, p: 2, backgroundColor: '#F8F8F8' }