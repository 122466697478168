import React, { useState } from "react";
import {
    Container,
    Box,
    Typography,
    Grid,
    Paper
} from "@mui/material";

import CountUp from 'react-countup';
import PageTitle from "../../../hooks/page-title";
import API from "../../../axios";

export default function Home() {
    PageTitle('Home');
    const [isLoading, setIsLoading] = useState(false);
    const [record, setRecord] = useState(null);

    React.useEffect(() => {
        // getData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getData = async () => {
        setIsLoading(true);
        try {
            let { data } = await API('manager/home', 'get');
            setRecord(data);
            setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
        }
    }

    return (
        <>
            <Container
                maxWidth="100%"
                sx={{ paddingTop: "15px", paddingBottom: "15px" }}
            >
                <Grid container spacing={2}>
                    <Grid item lg={3} md={4} sm={6} xs={12}>
                        <Card title='Employees' count={isLoading ? 0 : record?.employees} />
                    </Grid>
                    <Grid item lg={3} md={4} sm={6} xs={12}>
                        <Card title='Projects' count={isLoading ? 0 : record?.projects} />
                    </Grid>
                    <Grid item lg={3} md={4} sm={6} xs={12}>
                        <Card title='Active Projects' count={isLoading ? 0 : record?.activeProjects} />
                    </Grid>
                    <Grid item lg={3} md={4} sm={6} xs={12}>
                        <Card title='Complete Projects' count={isLoading ? 0 : record?.completeProjects} />
                    </Grid>
                    <Grid item lg={3} md={4} sm={6} xs={12}>
                        <Card title='Cancel Projects' count={isLoading ? 0 : record?.cancelProjects} />
                    </Grid>
                    <Grid item lg={3} md={4} sm={6} xs={12}>
                        <Card title='Components' count={isLoading ? 0 : record?.components} />
                    </Grid>
                </Grid>
            </Container>
        </>
    )
}

const Card = ({ title = 'title', count = 0 }) => {
    return (
        <Box component={Paper}
            sx={{ p: 3 }}
        >
            <Typography variant="h6">
                {title}
            </Typography>
            <br />
            <Box sx={{ textAlign: 'end' }}>
                <CountUp
                    duration={0.5}
                    end={count} />
            </Box>
        </Box>
    );
}