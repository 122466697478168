import React from 'react';
import LaptopWindowsIcon from '@mui/icons-material/LaptopWindows';
import { Link } from 'react-router-dom';
import { Typography, Box } from '@mui/material';
import { useSelector } from 'react-redux';
import myTranslator from '../helpers/myTranslator';

export default function ComingSoon() {
    const { selectedLang, translation } = useSelector((state) => state.storeReducer);  
    return (
        <Box
            sx={{
                width: '100%',
                height: '50vh',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'
            }}
        >
            <Box sx={{
                opacity: '0.5',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center'
            }}>
                <LaptopWindowsIcon sx={{ fontSize: '120px' }} />
                <Typography
                    variant='h4'
                    component='h4'
                >{myTranslator(translation,selectedLang,"sa_comingsoon_typo_comingsoon")}</Typography>
                <Link to={-1}>{myTranslator(translation,selectedLang,"sa_comingsoon_link_goback")}</Link>
            </Box>
        </Box>
    );
}