import React, { useState, useEffect } from 'react';
import {
    Box,
    Typography,
    Container,
    InputAdornment,
    TableRow,
    TableCell,
    Tabs,
    Tab,
    Grid,
    Paper,
    Radio,
} from '@mui/material';

import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import PageTitle from '../../../hooks/page-title';
import {
    InputField,
    SaveChangesBtn,
    MyLoader,
    TableWrapper
} from '../../../components';
import API from '../../../axios';
import { openPopUp } from '../../../store/reducer';
import { errorsSetter } from '../../../helpers/errors-setter';

export default function PowerConsumption() {
    PageTitle('Power Consumption');
    const [activeTab, setActiveTab] = useState('DEFAULT_LIBRARY');
    const { id } = useParams();
    const [showUpdateBtn, setShowUpdateBtn] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [record, setRecord] = useState(null);
    const [selectedID, setSelectedID] = useState(null);
    const [consumptionValue, setConsumptionValue] = useState(11808);
    const [selectedConsumption, setSelectedConsumption] = useState(null);
    const [formErrors, setFormErrors] = useState({});
    const { user } = useSelector((state) => state.storeReducer);
    const _userRole = useSelector((state) => state.storeReducer.user.role);
    const dispatch = useDispatch();

    useEffect(() => {
        getData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getData = async () => {
        setIsLoading(true);
        try {
            let { data } = await API(`${_userRole}/power-consumptions?active=1`, 'get');
            getProject(data)
        } catch (err) {
        }
    }

    const getProject = async (_data) => {
        try {
            let { data } = await API(`${_userRole}/projects/${id}`, 'get');
            findConsumption(_data, data.power_consumption_id, data?.annual_consumption);
        } finally {
            setIsLoading(false);
        }
    }

    const findConsumption = (_data, _id, _ac = 11808) => {
        setRecord(_data);
        let _index = _data.findIndex(x => x?.id === Number(_id));
        if (_index < 0) return
        setSelectedID(Number(_data[_index].id));
        setConsumptionValue(_ac);
        setSelectedConsumption(_data[_index])
    }

    const handleTabs = (_, newValue) => {
        setActiveTab(newValue);
    };

    const handleForm = async (e) => {
        e.preventDefault();
        setFormErrors({});
        let _fd = {
            _method: 'patch',
            organization_id: Number(user.organization_id)
        };
        if (selectedID !== null) {
            _fd = {
                ..._fd,
                power_consumption_id: Number(selectedID),
                annual_consumption: consumptionValue,
            }
        }
        setIsLoading(true);
        try {
            await API(`${_userRole}/projects/${id}`, 'post', _fd);
            setShowUpdateBtn(false);
            dispatch(openPopUp({
                open: true,
                message: 'Power consumption has been updated.',
                type: 'success'
            }))
        } catch (error) {
            setFormErrors(errorsSetter(error));
            dispatch(openPopUp({
                open: true,
                message: 'Error Occurred. While updating power consumption.',
                type: 'error'
            }))
        } finally {
            setIsLoading(false);
        }
    }

    return (
        <>
            <Container
                maxWidth="100%"
                sx={{ paddingTop: '12px', paddingBottom: '12px', position: 'relative' }}
            >
                <Box
                    component='form'
                    autoComplete="off"
                    onSubmit={handleForm}
                >
                    <Grid container spacing={2}>
                        <Grid item lg={8} md={7} xs={12}>
                            <Typography
                                variant="h6"
                                component="h6"
                                mt={"12px"}
                                mb={"10px"}
                            >
                                POWER CONSUMPTION
                            </Typography>
                            <Typography
                                variant="p"
                                component="p"
                                sx={{
                                    color: "#969696",
                                    mb: 4
                                }}
                            >
                                Choose a suitable consumption profile for the simulation or import your own profile
                            </Typography>
                            <InputField
                                label='Annual consumption'
                                value={Number(consumptionValue) === 11808 ? 0 : consumptionValue}
                                InputProps={{
                                    endAdornment: <InputAdornment position="start">kWh</InputAdornment>,
                                }}
                                inputProps={{
                                    pattern: "^-?\\d*(\\.\\d+)?$",
                                    title: 'number only'
                                }}
                                handleChange={(e) => {
                                    setConsumptionValue(e.target.value);
                                    setShowUpdateBtn(true);
                                }}
                                error={formErrors?.annual_consumption}
                            />
                            <small>Default value: 11808 kWh</small>
                        </Grid>
                        <Grid item lg={4} md={5} xs={12}>
                            <Box component={Paper} sx={cardStyle}>
                                <Typography>OVERVIEW</Typography>
                                <table width='100%'>
                                    <tbody>
                                        <tr>
                                            <td valign='top'>
                                                <small>Name</small>
                                            </td>
                                            <td align='right' valign='top'>
                                                <small>{selectedConsumption?.name || '-'}</small>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td valign='top'>
                                                <small>Building type</small>
                                            </td>
                                            <td align='right' valign='top'>
                                                <Box component='small' sx={{ textTransform: 'capitalize' }}>{selectedConsumption?.building_type.replace('_', ' ') || '-'}</Box>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td valign='top'>
                                                <small>Heating system</small>
                                            </td>
                                            <td align='right' valign='top'>
                                                <small>{selectedConsumption?.heating_system || '-'}</small>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td valign='top'>
                                                <small>Domestic water system</small>
                                            </td>
                                            <td align='right' valign='top'>
                                                <small>{selectedConsumption?.water_system || '-'}</small>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </Box>
                        </Grid>
                    </Grid>
                    <Box sx={{ width: '100%', mt: 3, borderBottom: '1px solid #ccc' }}>
                        <Tabs
                            value={activeTab}
                            onChange={handleTabs}
                            textColor="primary"
                            indicatorColor="primary"
                            aria-label="secondary tabs example"
                            sx={{ '& .MuiTabs-flexContainer': { overflowX: 'auto' } }}
                        >
                            <Tab value="DEFAULT_LIBRARY" label="DEFAULT LIBRARY" sx={{ textTransform: 'none' }} />
                            <Tab value="CSV_IMPORT" label="CSV IMPORT" sx={{ textTransform: 'none' }} />
                        </Tabs>
                    </Box>
                    <Box sx={{ display: activeTab === 'DEFAULT_LIBRARY' ? 'block' : 'none' }} >
                        <Box pt={4}>
                            <TableWrapper
                                thContent={tableCols.map((column, index) => (
                                    <TableCell key={index}>{column}</TableCell>
                                ))}
                                spanTd={tableCols.length}
                                isContent={Boolean(record?.length)}
                                tableStyle={{ minWidth: "400px" }}
                            >
                                {Boolean(record?.length) && record.map((item, i) => {
                                    return (
                                        <TableRow key={i}>
                                            <TableCell>
                                                <Radio
                                                    color='success'
                                                    value={item.id}
                                                    checked={Number(selectedID) === Number(item.id)}
                                                    onChange={(e) => {
                                                        setSelectedID(e.target.value);
                                                        setShowUpdateBtn(true);
                                                        setSelectedConsumption(item);
                                                        setConsumptionValue(Number(item.annual_consumption));
                                                    }}
                                                />
                                            </TableCell>
                                            <TableCell>{item?.name}</TableCell>
                                            <TableCell sx={{ textTransform: 'capitalize' }}>{item?.building_type.replace('_', ' ')}</TableCell>
                                            <TableCell>{item?.heating_system}</TableCell>
                                            <TableCell>{item?.water_system}</TableCell>
                                            <TableCell>{item?.annual_consumption} kWh</TableCell>
                                        </TableRow>
                                    );
                                })}
                            </TableWrapper>
                        </Box>
                    </Box>
                    <Box sx={{ display: activeTab === 'CSV_IMPORT' ? 'block' : 'none' }} >
                        <></>
                    </Box>
                    {showUpdateBtn &&
                        <SaveChangesBtn type='submit' cancel={() => setShowUpdateBtn(false)} />}
                </Box>
            </Container >
            {isLoading &&
                <MyLoader />
            }
        </>
    )
}
const tableCols = ['Active', 'Name', 'Building type', 'Heating system', 'Domestic water system', 'Annual consumption']
const cardStyle = { '& p': { marginBottom: '15px' }, '& td': { padding: '5px' }, '& td:last-child': { color: '#969696' }, p: 2 }