import React from "react";
import {
    useMediaQuery,
    Box,
    Stack,
    Button,
    IconButton,
    Typography,
    Modal,
    CircularProgress,
    Backdrop,
    Snackbar,
    Grid,
    TableContainer,
    Table,
    TableBody,
    TableRow,
    TableCell
} from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import AddIcon from '@mui/icons-material/Add';

import { InputField, SelectBox } from "../../../../components";

export default function AccountInfoForm({
    open = false,
    submitForm = () => { },
    handleClose = () => { } }) {
    const [showMessage, setShowMessage] = React.useState(false);
    const matches = useMediaQuery('(min-width:900px)');

    const handleForm = (e) => {
        e.preventDefault();
        submitForm();
    }

    return (
        <>
            <Modal
                open={!!open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                sx={{
                    overflowY: 'auto',
                }}
            >
                <Box sx={matches ? modalStyle : modalStyle2}>
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'flex-end',
                        }}
                    >
                        <IconButton
                            onClick={handleClose}
                        >
                            <CloseIcon />
                        </IconButton>
                    </Box>
                    <Typography variant="h6" component="h5">
                        ACCOUNT INFORMATION
                    </Typography>
                    {/* Form */}
                    <Box
                        component='form'
                        noValidate
                        autoComplete="off"
                        onSubmit={handleForm}
                        sx={{ mt: 6, mb: 4 }}
                    >

                        <Grid container spacing={2}>
                            <Grid item md={3} sm={6} xs={12}>
                                <InputField
                                    size='small'
                                    placeholder='first name'
                                    label='first name'
                                    initValue='Marcel'
                                    error=''
                                    required
                                    fullWidth
                                    handleChange={() => { }}
                                />
                            </Grid>
                            <Grid item md={3} sm={6} xs={12}>
                                <InputField
                                    size='small'
                                    placeholder='Surname'
                                    label='Surname'
                                    initValue='Steer'
                                    error=''
                                    required
                                    fullWidth
                                    handleChange={() => { }}
                                />
                            </Grid>
                            <Grid item md={3} sm={6} xs={12}>
                                <InputField
                                    label='User name'
                                    initValue='marcel.steiert@gama-pv.ch'
                                    size='small'
                                    disabled
                                    fullWidth
                                />
                            </Grid>
                            <Grid item md={3} sm={6} xs={12}>
                                <InputField
                                    size='small'
                                    initValue='marcel.steiert@gama-pv.ch'
                                    disabled
                                    label='Email'
                                    fullWidth
                                />
                            </Grid>
                            <Grid item md={3} sm={6} xs={12}>
                                <InputField
                                    size='small'
                                    label='ACCOUNT INFORMATION'
                                    initValue='41794170600'
                                    error=''
                                    required
                                    fullWidth
                                    handleChange={() => { }}
                                />
                            </Grid>
                            <Grid item md={3} sm={6} xs={12}>
                                <InputField
                                    size='small'
                                    placeholder='Mobile phone'
                                    label='Mobile phone'
                                    initValue='0987654321'
                                    error=''
                                    required
                                    fullWidth
                                    handleChange={() => { }}
                                />
                            </Grid>
                            <Grid item md={3} sm={6} xs={12}>
                                <SelectBox
                                    items={['German', 'English']}
                                    size='small'
                                    label='language'
                                    required
                                    fullWidth
                                />
                            </Grid>
                            <Grid item md={3} sm={6} xs={12}>
                                <InputField
                                    size='small'
                                    type="password"
                                    initValue='0123456789'
                                    label='Password'
                                    fullWidth
                                />
                            </Grid>
                        </Grid>
                        <Grid container spacing={2}
                            sx={{ mt: 4, border: '1px solid #ccc', borderLeft: 0, borderRight: 0 }}
                        >
                            <Grid item md={5} sm={12} sx={{ py: 4 }}>
                                <p>USAGE INSTRUCTIONS</p>
                                <TableContainer>
                                    <Table sx={cell}>
                                        <TableBody>
                                            <TableRow>
                                                <TableCell>Business</TableCell>
                                                <TableCell>Gama PV</TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell>number of projects</TableCell>
                                                <TableCell>538</TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell>Number of projects with generated documents</TableCell>
                                                <TableCell>350</TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell>EF solar projects (billing via Eturnity)</TableCell>
                                                <TableCell>40</TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell>Support (Budget)</TableCell>
                                                <TableCell>7 November</TableCell>
                                            </TableRow>
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Grid>
                            <Grid item md={2} sx={{ pt: '0 !important', display: { md: 'block', sm: 'none' } }}>
                                <Box
                                    sx={{
                                        display: 'box',
                                        height: '100%',
                                        width: '1px',
                                        background: '#ccc',
                                        margin: '0 auto'
                                    }}
                                />
                            </Grid>
                            <Grid item md={5} sm={12} sx={{ py: 4 }}>
                                <p>CUSTOMER SUPPORT</p>
                                <TableContainer>
                                    <Table sx={cell}>
                                        <TableBody>
                                            <TableRow>
                                                <TableCell>phone number</TableCell>
                                                <TableCell>41794170600</TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell>Browserversion</TableCell>
                                                <TableCell>firefox, v.106.0.0</TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell>Email</TableCell>
                                                <TableCell>support@eturnity.com</TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell>EF solar projects (billing via Eturnity)</TableCell>
                                                <TableCell>
                                                    <Button
                                                        color="success"
                                                        size="small"
                                                        startIcon={<AddIcon />}
                                                        variant="contained"
                                                    >
                                                        No problem
                                                    </Button>
                                                </TableCell>
                                            </TableRow>
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Grid>
                        </Grid>
                        <Stack
                            direction='row'
                            spacing={1}
                            mt={7}
                        >
                            <Button
                                type="submit"
                                sx={{
                                    backgroundColor: '#000',
                                    '&:hover': {
                                        backgroundColor: '#000',
                                    },
                                    ...btn
                                }}
                            >
                                Save on computer
                            </Button>
                            <Button
                                type="button"
                                variant="contained"
                                sx={{
                                    backgroundColor: '#D71B1B',
                                    '&:hover': {
                                        backgroundColor: '#D71B1B',
                                    },
                                    ...btn
                                }}
                                onClick={handleClose}
                            >
                                Abort
                            </Button>
                        </Stack>
                    </Box>
                </Box>
            </Modal>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 999999 }}
                open={""}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
            <Snackbar
                open={!!showMessage}
                autoHideDuration={6000}
                onClose={() => setShowMessage(false)}
                message={""}
            />
        </>
    );
}

const cell = {
    marginTop: '15px',
    '& .MuiTableCell-root': {
        paddingTop: '3px',
        paddingBottom: '3px',
        border: 0
    }
}
const btn = {
    color: '#fff',
    textTransform: 'none',
    paddingLeft: '25px',
    paddingRight: '25px',
}
const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '100%',
    maxWidth: '800px',
    bgcolor: '#fff',
    border: '0',
    borderRadius: '7px',
    boxShadow: 24,
    p: 4,
    zIndex: 100
};

const modalStyle2 = {
    width: '100%',
    maxWidth: '100%',
    bgcolor: '#fff',
    border: '0',
    borderRadius: '7px',
    boxShadow: 24,
    p: 4,
    zIndex: 100
};