import React from "react";

import { Box } from "@mui/material";

import { AdminDrawer, AdminHeader, PopUpMsg } from "../../components";
import { useDispatch, useSelector } from "react-redux";
import { closePopUp } from "../../store/reducer";

export default function AdminLayout({ children }) {
  const dispatch = useDispatch();
  const { open, message, type } = useSelector((state) => state.storeReducer)
  return (
    <>
      <Box
        sx={{
          display: "flex",
          minHeight: "100vh",
        }}
      >
        <AdminDrawer />
        <main>
          <AdminHeader />
          {children}
        </main>
      </Box>
      <PopUpMsg
        open={open}
        type={type}
        message={message ?? "error occured"}
        handleClose={() => dispatch(closePopUp())}
      />
    </>
  );
}
