import React from 'react';
import { SelectBox, InputField } from '../../../components';
import {
    Box,
    Typography,
    Container,
    Stack,
    Grid,
    Tooltip
} from '@mui/material';
import InfoRoundedIcon from '@mui/icons-material/InfoRounded';
import PageTitle from '../../../hooks/page-title';

export default function EconomyPv() {
    PageTitle('Kunde | GamaAg');

    return (
        <Container
            maxWidth="100%"
            sx={{ paddingTop: '12px', paddingBottom: '12px' }}
        >
            <Typography
                variant="h5"
                component="h5"
                fontSize={"18px"}
                mt={"12px"}
                mb={"10px"}
            >
                WIRTSCHAFTLICHKEITSEINSTELLUNGEN FÜR DAS PV-SYSTEM
            </Typography>
            <Typography
                variant="p"
                component="p"
                sx={{
                    color: "#969696",
                }}
            >
                Definieren Sie die Wirtschaftlichkeitseinstellungen für das PV-System
            </Typography>

            <Box
                mt={4}
                pb={5}
                sx={{ borderBottom: 1, borderColor: '#CCCCCC' }}
            >
                <Stack
                    direction="row"
                    alignItems='center'
                >
                    <Typography
                        variant="p"
                        component="span"
                        mt={"12px"}
                        mb={"12px"}
                        pr={1}
                    >
                        BETRIEBSKOSTEN
                    </Typography>
                    <Tooltip title='info'>
                        <InfoRoundedIcon sx={{ opacity: 0.5 }} fontSize='12px' />
                    </Tooltip>                    
                </Stack>
                <Grid container spacing={2}>
                    <Grid item md={3} xs={12} mt={1}>
                        <SelectBox
                            label='Operating costs of PV system are based on'
                            items={['Prozent der Investition', 'Prozent der Investition']}
                            size='small'
                            styles={{ mt: 2 }}
                            fullWidth
                        />
                    </Grid>
                    <Grid item md={3} xs={12} mt={1}>
                        <InputField
                            label='percent of the investment'
                            size='small'
                            fullWidth
                            styles={{ mt: 2 }}
                        />
                    </Grid>
                </Grid>
            </Box>
            <Box
                mt={4}
                pb={5}
                sx={{ borderBottom: 1, borderColor: '#CCCCCC' }}
            >
                <Stack
                    direction="row"
                    alignItems='center'
                >
                    <Typography
                        variant="p"
                        component="span"
                        mt={"12px"}
                        mb={"12px"}
                        pr={1}
                    >
                        EIGENKAPITAL
                    </Typography>
                    <Tooltip title='info'>
                        <InfoRoundedIcon sx={{ opacity: 0.5 }} fontSize='12px' />
                    </Tooltip>  
                </Stack>
                <Grid container spacing={2}>
                    <Grid item md={3} xs={12} mt={1}>
                        <InputField
                            label='The opportunity cost'
                            size='small'
                            fullWidth
                            styles={{ mt: 2 }}
                        />
                    </Grid>
                </Grid>
            </Box>
            <Box
                mt={4}
                pb={5}
                sx={{ borderBottom: 1, borderColor: '#CCCCCC' }}
            >
                <Stack
                    direction="row"
                    alignItems='center'
                >
                    <Typography
                        variant="p"
                        component="span"
                        mt={"12px"}
                        mb={"12px"}
                        pr={1}
                    >
                        STROMPREISENTWICKLUNG
                    </Typography>
                    <Tooltip title='info'>
                        <InfoRoundedIcon sx={{ opacity: 0.5 }} fontSize='12px' />
                    </Tooltip>  
                </Stack>
                <Grid container spacing={2}>
                    <Grid item md={3} xs={12} mt={1}>
                        <InputField
                            label='Annual increase in consumption tariffs'
                            size='small'
                            fullWidth
                            styles={{ mt: 2 }}
                        />
                    </Grid>
                    <Grid item md={3} xs={12} mt={1}>
                        <InputField
                            label='Annual change in feed-in tariff'
                            size='small'
                            fullWidth
                            styles={{ mt: 2 }}
                        />
                    </Grid>
                </Grid>
            </Box>
            <Box
                mt={4}
                pb={5}
            >
                <Stack
                    direction="row"
                    alignItems='center'
                >
                    <Typography
                        variant="p"
                        component="span"
                        mt={"12px"}
                        mb={"12px"}
                        pr={1}
                    >
                        NUTZUNGSDAUER
                    </Typography>
                    <Tooltip title='info'>
                        <InfoRoundedIcon sx={{ opacity: 0.5 }} fontSize='12px' />
                    </Tooltip>  
                </Stack>
                <Grid container spacing={2}>
                    <Grid item md={3} xs={12} mt={1}>
                        <InputField
                            label='useful life'
                            size='small'
                            fullWidth
                            styles={{ mt: 2 }}
                        />
                    </Grid>
                </Grid>
            </Box>

        </Container>
    )
}