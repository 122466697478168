import React, { useState, useEffect } from 'react';
import {
    Box,
    Typography,
    Container,
    Stack,
    Grid,
    IconButton,
    Divider,
} from '@mui/material';
import SimCardDownloadIcon from '@mui/icons-material/SimCardDownload';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';

import { useDispatch, useSelector } from 'react-redux';
import API from '../../../axios';
import { useParams } from 'react-router-dom';

import {
    InfoHeading,
    InputField,
    MegaSearchBox,
    MyLoader,
    SaveChangesBtn,
    SelectBox
} from '../../../components';
import PageTitle from '../../../hooks/page-title';
import { errorsSetter } from '../../../helpers/errors-setter';
import { openPopUp } from '../../../store/reducer';

export default function ModuleLayout() {
    PageTitle('Module Layout');
    const { id } = useParams();
    const [showUpdateBtn, setShowUpdateBtn] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [record, setRecord] = useState({});
    const [formData, setFormData] = useState({});
    const [formErrors, setFormErrors] = useState({});
    const _userRole = useSelector((state) => state.storeReducer.user.role);

    const dispatch = useDispatch();

    useEffect(() => {
        getData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getData = async () => {
        setIsLoading(true);
        try {
            let { data } = await API(`${_userRole}/pv-module/${id}`, 'get');
            if (!!data) {
                setRecord(data);
                setFormData({
                    ...formData,
                    pv_module: data.pv_module.id || null,
                    sub_structure: data.sub_structure.id || null,
                    arrangement: data.arrangement || '',
                    dach_name: data.dach_name || '',
                    orientation: data.orientation || '',
                    module_tilt: data.module_tilt || '',
                    specific_yield: data.specific_yield || '',
                    roof_type: data.roof_type || '',
                    first_hole: data.first_hole || '',
                    rafter: data.rafter || '',
                    roof_convering: data.roof_convering || '',
                });
            }
        } catch (error) {
            console.error("🚀 ~ file: module-layout.js ~ getData ~ error", error)
        } finally {
            setIsLoading(false);
        }
    }

    const handleInput = (e) => {
        const { value, name } = e.target;
        setFormData({ ...formData, [name]: value });
        setShowUpdateBtn(true);
    }

    const validate = async (e) => {
        let _flag = false;
        let error = { ...formErrors };
        if (!(!!formData?.pv_module)) {
            error = { ...error, pv_module: 'The pv module field is required.' };
            _flag = true;
        }
        if (!(!!formData?.sub_structure)) {
            error = { ...error, sub_structure: 'The sub structure field is required.' };
            _flag = true;
        }
        setFormErrors(error);
        return _flag;
    }

    const handleForm = async (e) => {
        e.preventDefault();
        if (validate()) { return; }
        let _data = {
            ...formData,
            project_id: Number(id)
        }
        setFormErrors({});
        setIsLoading(true);
        try {
            await API(`${_userRole}/pv-modules`, 'post', _data);
            setShowUpdateBtn(false);
            dispatch(openPopUp({
                open: true,
                message: 'Module layout has been updated.',
                type: 'success'
            }))
            setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
            setFormErrors(errorsSetter(error));
            dispatch(openPopUp({
                open: true,
                message: 'Error Occurred. While updating module layout.',
                type: 'error'
            }))
        }
    }

    return (
        <>
            <Container
                maxWidth="100%"
                sx={{ paddingTop: '12px', paddingBottom: '12px' }}
            >
                <Box
                    component='form'
                    autoComplete='off'
                    onSubmit={handleForm}
                    mt={4}
                    pb={5}
                >
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Typography
                                variant="h6"
                                mt={2}
                                mb={1}
                            >
                                COMPONENT SELECTION
                            </Typography>
                            <Typography
                                variant="p"
                                component="p"
                                sx={{
                                    color: "#969696",
                                }}
                            >
                                Configure the components for the selected roof orientation
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <InfoHeading text='PV Module' containerSx={{ mb: 1 }} />
                            <Stack direction='row' alignItems='center' spacing={2}>
                                <Box sx={{ flexGrow: 1 }}>
                                    {/* add component */}
                                    <MegaSearchBox
                                        url={`${_userRole}/components?active=1&column=created_at&orderby=desc&`}
                                        objLabel="name"
                                        placeholder='Type ...'
                                        size="small"
                                        fullWidth
                                        obj={record?.pv_module}
                                        error={formErrors?.pv_module}
                                        handleChange={item => {
                                            setFormData({ ...formData, pv_module: item.id });
                                            setShowUpdateBtn(true);
                                        }}
                                    />
                                </Box>
                                <IconButton sx={{ backgroundColor: '#000', '&:hover': { backgroundColor: '#000' } }}>
                                    <SimCardDownloadIcon sx={{ color: '#fff' }} />
                                </IconButton>
                            </Stack>
                        </Grid>
                        <Grid item xs={12}>
                            <InfoHeading text='Substructure' containerSx={{ mb: 1 }} />
                            <Stack direction='row' alignItems='center' spacing={2}>
                                <Box sx={{ flexGrow: 1 }}>
                                    {/* add component */}
                                    <MegaSearchBox
                                        url={`${_userRole}/components?active=1&column=created_at&orderby=desc&`}
                                        objLabel="name"
                                        placeholder='Type ...'
                                        size="small"
                                        fullWidth
                                        obj={record?.sub_structure}
                                        error={formErrors?.sub_structure}
                                        handleChange={item => {
                                            setFormData({ ...formData, sub_structure: item.id });
                                            setShowUpdateBtn(true);
                                        }}
                                    />
                                </Box>
                                <IconButton sx={{ backgroundColor: '#000', '&:hover': { backgroundColor: '#000' } }}>
                                    <SimCardDownloadIcon sx={{ color: '#fff' }} />
                                </IconButton>
                            </Stack>
                        </Grid>
                        <Grid item xs={12}>
                            <Divider sx={{ mt: 4, mb: 4 }} />
                        </Grid>
                        <Grid item xs={12}>
                            <Typography
                                variant="p"
                                component="p"
                            >
                                MODULE LAYOUT
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Grid container spacing={3}>
                                <Grid item lg={3} md={4} sm={6} xs={12}>
                                    <InputField
                                        labelTop='Dachname'
                                        initValue={formData?.dach_name || ''}
                                        size="small"
                                        fullWidth
                                        required
                                        name='dach_name'
                                        handleChange={handleInput}
                                        error={formErrors?.dach_name || ''}
                                    />
                                </Grid>
                                <Grid item lg={3} md={4} sm={6} xs={12}>
                                    <InfoHeading text='Module arrangement' containerSx={{ mb: 1 }} />
                                    <SelectBox
                                        items={[
                                            { label: 'Horizontal', value: 'horizontal' },
                                            { label: 'Vertical', value: 'vertical' },
                                        ]}
                                        size="small"
                                        fullWidth
                                        required
                                        initValue={formData?.arrangement || ''}
                                        name='arrangement'
                                        handleChange={handleInput}
                                        error={formErrors?.arrangement || ''}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <Grid container spacing={3}>
                                <Grid item lg={3} md={4} sm={6} xs={12}>
                                    <InfoHeading text='Orientation' containerSx={{ mb: 1 }} />
                                    <InputField
                                        name='orientation'
                                        size="small"
                                        fullWidth
                                        type='number'
                                        required
                                        initValue={formData?.orientation || ''}
                                        handleChange={handleInput}
                                        error={formErrors?.orientation || ''}
                                    />
                                </Grid>
                                <Grid item lg={3} md={4} sm={6} xs={12}>
                                    <InfoHeading text='Module tilt' containerSx={{ mb: 1 }} />
                                    <InputField
                                        initValue={formData?.module_tilt || ''}
                                        name='module_tilt'
                                        size="small"
                                        type='number'
                                        fullWidth
                                        required
                                        handleChange={handleInput}
                                        error={formErrors?.module_tilt || ''}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <Grid container spacing={3}>
                                <Grid item lg={3} md={4} sm={6} xs={12}>
                                    <InputField
                                        labelTop='Specific yield'
                                        initValue={formData?.specific_yield || ''}
                                        name='specific_yield'
                                        size="small"
                                        type='number'
                                        fullWidth
                                        required
                                        handleChange={handleInput}
                                        error={formErrors?.specific_yield || ''}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <Divider sx={{ mt: 4, mb: 4 }} />
                        </Grid>
                        <Grid item xs={12}>
                            <Typography
                                variant="h6"
                                mb={1}
                            >
                                ROOF SURFACES
                            </Typography>
                            <Typography
                                variant="p"
                                sx={{
                                    color: "#969696",
                                }}
                            >
                                Specify the roof areas intended for PV modules
                            </Typography>
                            <Box sx={{ overflowX: 'auto' }}>
                                <Box
                                    sx={{ border: '1px solid #ccc', p: 1, maxWidth: '480px', minWidth: '400px', borderRadius: '7px', mt: 2, overflowX: 'auto' }}
                                >
                                    <Stack direction='row' alignItems='center' spacing={4}>
                                        <small>106.782</small>
                                        <small>20,800 kWp</small>
                                        <InputField
                                            size='small'
                                            initValue='52'
                                            type='text'
                                            sx={{ maxWidth: '100px' }}
                                        />
                                        <small>52 Module</small>
                                        <button
                                            style={{
                                                backgroundColor: 'transparent',
                                                border: 0,
                                                cursor: 'pointer',
                                                borderLeft: '1px solid #ccc',
                                            }}
                                        >
                                            <ArrowRightIcon />
                                        </button>
                                    </Stack>
                                </Box>
                            </Box>
                        </Grid>
                        <Grid item xs={12}>
                            <Divider sx={{ mt: 4, mb: 4 }} />
                        </Grid>
                        <Grid item xs={12}>
                            <Typography
                                variant="h6"
                                mb={1}
                            >
                                MORE INFORMATION
                            </Typography>
                            <Typography
                                variant="p"
                                sx={{
                                    color: "#969696",
                                }}
                            >
                                Here you can enter additional information about the project, but without influencing the simulation.
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Grid container spacing={3}>
                                <Grid item lg={3} md={4} sm={6} xs={12}>
                                    <InputField
                                        labelTop='Roof Type'
                                        size="small"
                                        fullWidth
                                        required
                                        initValue={formData?.roof_type || ''}
                                        name='roof_type'
                                        handleChange={handleInput}
                                        error={formErrors?.roof_type || ''}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography
                                variant="p"
                                sx={{
                                    color: "#969696",
                                }}
                            >
                                To change the roof type, <a href="https://www.google.com/" target='_blank' rel="noreferrer">please change the mounting system</a>. The mounting system determines the roof type.
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Grid container spacing={3}>
                                <Grid item lg={3} md={4} sm={6} xs={12}>
                                    <InfoHeading text='First hole' containerSx={{ mb: 1 }} />
                                    <InputField
                                        initValue={formData?.first_hole || ''}
                                        name='first_hole'
                                        size="small"
                                        fullWidth
                                        required
                                        handleChange={handleInput}
                                        error={formErrors?.first_hole || ''}
                                    />
                                </Grid>
                                <Grid item lg={3} md={4} sm={6} xs={12}>
                                    <InputField
                                        labelTop='Rafter or draw spacing'
                                        initValue={formData?.rafter || ''}
                                        name='rafter'
                                        size="small"
                                        required
                                        fullWidth
                                        handleChange={handleInput}
                                        error={formErrors?.rafter || ''}
                                    />
                                </Grid>
                                <Grid item lg={3} md={4} sm={6} xs={12}>
                                    <SelectBox
                                        labelTop='Roof covering'
                                        items={[
                                            { label: 'Interlocking tiles', value: 'interlocking_tiles' },
                                            { label: 'Beavertail', value: 'biberschwanz' },
                                            { label: 'trapezoidal sheet metal', value: 'trapezoisizal_sheet_mental' },
                                            { label: 'Wavetemit', value: 'welletemit' },
                                            { label: 'Cement shingle', value: 'etemitschindel' },
                                            { label: 'Standing seam', value: 'stehfalz' },
                                        ]}
                                        name='roof_convering'
                                        size="small"
                                        fullWidth
                                        required
                                        initValue={formData?.roof_convering || ''}
                                        handleChange={handleInput}
                                        error={formErrors?.roof_convering || ''}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    {showUpdateBtn &&
                        <SaveChangesBtn type='submit' cancel={() => setShowUpdateBtn(false)} />}
                </Box>
            </Container >
            {isLoading &&
                <MyLoader />
            }
        </>
    )
}