import React, { useState } from 'react';
import {
  Box,
  Container,
  Grid,
  Typography,
  TextField,
  Stack,
  Tooltip,
  IconButton,
  Button,
  useMediaQuery,
  Modal,
} from '@mui/material';
import { InputField, SaveChangesBtn } from '../../../../components';
import InfoIcon from '@mui/icons-material/Info';
import CloseIcon from "@mui/icons-material/Close";
import DeleteIcon from '@mui/icons-material/Delete';
import PageTitle from '../../../../hooks/page-title';

export default function CreateDoc() {
  PageTitle('PROJEKTDOKUMENTE')
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  return (
    <>
      <Container
        maxWidth="100%"
        sx={{ paddingTop: "15px", paddingBottom: "15px", position: 'relative' }}
      >
        <Grid container spacing={2}>
          <Grid item xs={12} md={6} lg={3} mt={3}>
            <Typography
              variant='p'
              component='p'
              sx={{ mb: 1 }}
            >
              document type
            </Typography>
            <TextField
              id="outlined-select-currency-native"
              select
              SelectProps={{
                native: true,
              }}
              sx={inputStyle}
            >
              {[{ label: 'Individual document', key: 'Id' },
              { label: 'Individual document', key: 'Id' }].map((v, i) => (
                <option key={i} value={v.key}>
                  {v.label}
                </option>
              ))}
            </TextField>
          </Grid>
          <Grid item xs={12} mt={3}>
            <Typography
              variant='p'
              component='p'
              sx={{ mb: 1 }}
            >
              description
            </Typography>
            <Box sx={{ maxWidth: '800px' }}>
              <InputField
                label='description'
                error=''
                size="small"
                required
                multiline
                fullWidth
                rows={4}
                handleChange={() => { }}
              />
            </Box>
          </Grid>
          <Grid item xs={12} md={7} lg={4} mt={3}>
            <Stack
              direction="row"
              alignItems="center"
            >
              <Typography
                variant='p'
                component='p'
                sx={{ mb: 1 }}
              >
                Address
              </Typography>
              <Tooltip title="Address">
                <InfoIcon sx={{ opacity: 0.4, mb: 0.5, ml: 1 }} />
              </Tooltip>
            </Stack>
            <TextField
              id="outlined-select-currency-native"
              select
              SelectProps={{
                native: true,
              }}
              sx={inputStyle}
            >
              {[{ label: 'Wieslistrasse 15, 9434 Au SG', key: 'w15AuSG' },
              { label: 'Wieslistrasse 15, 9434 Au SG', key: 'w15AuSG' }].map((v, i) => (
                <option key={i} value={v.key}>
                  {v.label}
                </option>
              ))}
            </TextField>
          </Grid>
        </Grid>
        <Grid container spacing={2} mt={1}>
          <Grid item xs={12} lg={6} xl={4} mt={3}>
            <Stack
              direction="row"
              alignItems="center"
            >
              <Typography
                variant='p'
                component='p'
                sx={{ mb: 1 }}
              >
                map source
              </Typography>
              <Tooltip title="map source">
                <InfoIcon sx={{ opacity: 0.4, mb: 0.5, ml: 1 }} />
              </Tooltip>
            </Stack>
            <TextField
              id="outlined-select-currency-native"
              select
              SelectProps={{
                native: true,
              }}
              sx={inputStyle}
            >
              {[{ label: 'SwissTopo (Satellit)', key: 'Swiss' },
              { label: 'SwissTopo (Satellit)', key: 'Swiss' }].map((v, i) => (
                <option key={i} value={v.key}>
                  {v.label}
                </option>
              ))}
            </TextField>
          </Grid>
          <Grid item xs={12} lg={6} xl={4} mt={3}>
            <Stack
              direction="row"
              alignItems="center"
            >
              <Typography
                variant='p'
                component='p'
                sx={{ mb: 1 }}
              >
                Zoom
              </Typography>
              <Tooltip title="Zoom">
                <InfoIcon sx={{ opacity: 0.4, mb: 0.5, ml: 1 }} />
              </Tooltip>
            </Stack>
            <TextField
              id="outlined-select-currency-native"
              select
              SelectProps={{
                native: true,
              }}
              sx={inputStyle}
            >
              {[{ label: 'Normal', key: 'Normal' },
              { label: 'Normal', key: 'Normal' }].map((v, i) => (
                <option key={i} value={v.key}>
                  {v.label}
                </option>
              ))}
            </TextField>
          </Grid>
          <Grid item xs={12} lg={6} xl={4} mt={3}>
            <Stack
              direction="row"
              alignItems="center"
            >
              <Typography
                variant='p'
                component='p'
                sx={{ mb: 1 }}
              >
                map source
              </Typography>
              <Tooltip title="map source">
                <InfoIcon sx={{ opacity: 0.4, mb: 0.5, ml: 1 }} />
              </Tooltip>
            </Stack>
            <TextField
              id="outlined-select-currency-native"
              select
              SelectProps={{
                native: true,
              }}
              sx={inputStyle}
            >
              {[{ label: 'SwissTopo (Satellit)', key: 'Swiss' },
              { label: 'SwissTopo (Satellit)', key: 'Swiss' }].map((v, i) => (
                <option key={i} value={v.key}>
                  {v.label}
                </option>
              ))}
            </TextField>
          </Grid>
        </Grid>
        <Grid container mt={2}>
          <Grid item xs={12} md={6} lg={4} mt={3}>
            <Stack
              direction="row"
              alignItems="center"
            >
              <Typography
                variant='p'
                component='p'
                sx={{ mb: 1 }}
              >
                Document Configuration
              </Typography>
              <Tooltip title="map source">
                <InfoIcon sx={{ opacity: 0.4, mb: 0.5, ml: 1 }} />
              </Tooltip>
            </Stack>
            <CusSelect />
          </Grid>
        </Grid>
        <Stack
          direction='row'
          flexWrap='wrap'
          mt={2}
        >
          <Button
            variant="text"
            sx={{ color: '#000', textTransform: 'none', }}
          >Add PDF Pages</Button>
          <Button
            variant="text"
            sx={{ color: '#000', textTransform: 'none', }}
          >Add PDF pages from a document</Button>
          <Button
            variant="text"
            sx={{ color: '#000', textTransform: 'none', }}
          >Add data sheet</Button>
        </Stack>
        <Stack
          direction='row'
          mt={2}
        >
          <Button
            variant='contained'
            sx={{
              textTransform: 'none',
              backgroundColor: '#000',
              m: 1
            }}
          >
            preview
          </Button>
          <Button
            variant='contained'
            sx={{
              textTransform: 'none',
              backgroundColor: '#000',
              m: 1
            }}
            onClick={handleOpen}
          >
            to generate
          </Button>
        </Stack>
        <SaveChangesBtn />
        <BasicModal open={!!open} handleClose={handleClose} />
      </Container>
    </>
  )
}

const BasicModal = ({ open, handleClose }) => {

  const match = useMediaQuery('(max-width:767px')
  const [counter, setCounter] = useState(1);
  return (
    <Modal
      open={!!open}
      onClose={() => {
        handleClose();
      }}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      sx={{
        overflowY: 'auto',
      }}
    >
      <Box sx={[modalStyle, { padding: match ? 2 : 4 }]}>
        <Box
          component='form'
          noValidate
          autoComplete="off"
        >
          {
            counter === 1 ?
              <>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                  }}
                >
                  <IconButton
                    sx={{ color: 'red' }}
                    onClick={() => {
                      handleClose();
                    }}
                  >
                    <CloseIcon />
                  </IconButton>
                </Box>
                <Typography variant="h6" component="h5">
                  CHOOSE A DOCUMENT
                </Typography>
                <Typography
                  variant="p"
                  component="p"
                  sx={{
                    color: "#969696",
                  }}
                >
                  Select a document and either click Add to add the pages to the current configuration or click Replace to replace all current pages with the pages from the document.
                </Typography>
                <Typography variant="h6" component="h5"
                  mt={2}
                  sx={{
                    color: '#000'
                  }}
                >
                  Document
                </Typography>
                <Grid container>
                  <Grid item md={6} xs={12}>
                    <TextField
                      id="outlined-select-currency-native"
                      select
                      SelectProps={{
                        native: true,
                      }}
                      sx={inputStyle}
                    >
                      {['offer', 'offer'].map((v, i) => (
                        <option key={i} value={v}>
                          {v}
                        </option>
                      ))}
                    </TextField>
                  </Grid>
                </Grid>
                <Stack
                  direction='row'
                  flexWrap='wrap'
                  mt={7}
                >
                  <Button
                    type="submit"
                    onClick={() => setCounter(2)}
                    sx={{
                      backgroundColor: '#000',
                      color: '#fff',
                      textTransform: 'none',
                      mt: 1,
                      mr: 1,
                      paddingLeft: '25px',
                      paddingRight: '25px',
                      minWidth: 150,
                      '&:hover': {
                        backgroundColor: '#000',
                      }
                    }}
                  >
                    Add to
                  </Button>
                  <Button
                    type="submit"
                    onClick={() => setCounter(2)}
                    sx={{
                      backgroundColor: '#000',
                      color: '#fff',
                      textTransform: 'none',
                      mt: 1,
                      mr: 1,
                      paddingLeft: '25px',
                      paddingRight: '25px',
                      minWidth: 150,
                      '&:hover': {
                        backgroundColor: '#000',
                      }
                    }}
                  >
                    Substitute
                  </Button>
                  <Button
                    type="button"
                    sx={{
                      backgroundColor: '#D71B1B',
                      color: '#fff',
                      textTransform: 'none',
                      mt: 1,
                      mr: 1,
                      paddingLeft: '25px',
                      paddingRight: '25px',
                      minWidth: 150,
                      '&:hover': {
                        backgroundColor: '#D71B1B',
                      }
                    }}
                    onClick={() => {
                      handleClose();
                    }}
                  >
                    Abort
                  </Button>
                </Stack>
              </>
              :
              <>
                <Typography
                  variant="h6"
                  component="h5"
                  sx={{
                    textAlign: "center"
                  }}>
                  GENERATING DOCUMENT...
                </Typography>
                <Typography
                  variant="p"
                  component="p"
                  sx={{
                    color: "#969696",
                    textAlign: "center"
                  }}
                >
                  The expected time to create the document is less than 2 minutes
                </Typography>
              </>
          }

        </Box>
      </Box>
    </Modal >
  )
}

const CusSelect = () => {
  return (
    <Stack
      direction='row'
      alignItems='center'
      mt={2}
    >
      <TextField
        id="outlined-select-currency-native"
        select
        SelectProps={{
          native: true,
        }}
        sx={inputStyle}
      >
        {[{ label: '01a offer', key: '01a offer' },
        { label: '01a offer', key: '01a offer' }].map((v, i) => (
          <option key={i} value={v.key}>
            {v.label}
          </option>
        ))}
      </TextField>
      <IconButton>
        <DeleteIcon fontSize='small' sx={{ opacity: 0.5 }} />
      </IconButton>
    </Stack>
  )
}

const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '100%',
  maxWidth: '800px',
  bgcolor: '#fff',
  border: '0',
  borderRadius: '7px',
  boxShadow: 24,
  zIndex: 100
};

const inputStyle = {
  width: '100%',
  minWidth: '280px',
  '& .MuiInputBase-input': {
    paddingTop: '10px',
    paddingBottom: '10px',
  },
  "& .Mui-focused fieldset": {
    borderColor: "#1B9607 !important",
  },
  '& .MuiNativeSelect-select': {
    paddingTop: '10px',
    paddingBottom: '10px',
  }
}

