import React from "react";
import {
    Container,
    Box,
    MenuItem,
} from "@mui/material";

import { Header, PopUpMsg } from "../../../components";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { closePopUp } from "../../../store/reducer";

export default function LibraryLayout({ children }) {
    const pathname = window.location.pathname.split("/");
    const { open, message, type } = useSelector((state) => state.storeReducer)
    const dispatch = useDispatch();
    const navigate = useNavigate();

    return (
        <>
            <Header hideMenu />
            <Container
                maxWidth="100%"
                sx={{ paddingTop: "15px", paddingBottom: "15px" }}
            >
                <Box sx={{ width: '100%', borderBottom: '1px solid #ccc' }}>
                    <Box
                        className="mycustom_tabs"
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'flex-start',
                        }}
                    >
                        {myTabs.map((v, i) => {
                            return (
                                <MenuItem
                                    key={i}
                                    selected={`/${pathname[1]}/${pathname[2]}` === v.url}
                                    onClick={() => navigate(v.url)}>{v.title}</MenuItem>
                            );
                        })}
                    </Box>
                </Box>
                <Box sx={{ pt: "15px", pb: "15px" }}>
                    {children}
                </Box>
            </Container>
            <PopUpMsg
                open={!!open}
                type={type}
                message={message}
                handleClose={() => dispatch(closePopUp())}
            />
        </>
    )
}

const myTabs = [
    {
        title: 'Components',
        url: '/library/components',
    },
    {
        title: 'The Group',
        url: '/library/groups',
    },
    {
        title: 'Project Template',
        url: '/library/project-template',
    },
]
// {
//     title: 'Subsidies',
//     url: '/library/subsidies',
// },