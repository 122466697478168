import React, { useState } from 'react'
import {
    Box,
} from '@mui/material';
import {
    CarbonFootprints,
    IndependenceAndConsumption,
    OrderForm,
    YourEnergySystem,
    InstallationProcedure,
    SolarPowerSystem,
    YourPersonalOffer,
    EnergySystem,
    ReturnCompared
} from "./steps"
import useMediaQuery from '@mui/material/useMediaQuery';
import '../../../App.css';

const MutliSteps = () => {
    const [steps, setSteps] = useState(1);

    const min450px = useMediaQuery('(min-width:28.125rem)');
    const pFontSize = min450px ? '1rem' : "0.7rem";

    return (
        <Box sx={{ display: 'flex', flexDirection: "column", justifyContent: "space-between" }}>
            {steps === 1 ?
                <YourPersonalOffer /> :
                steps === 2 ?
                    <EnergySystem /> :
                    steps === 3 ?
                        <IndependenceAndConsumption /> :
                        steps === 4 ?
                            <ReturnCompared /> :
                            steps === 5 ?
                                <YourEnergySystem /> :
                                steps === 6 ?
                                    <CarbonFootprints></CarbonFootprints> :
                                    steps === 7 ?
                                        <SolarPowerSystem /> :
                                        steps === 8 ?
                                            <InstallationProcedure /> :
                                            steps === 9 &&
                                            <OrderForm />
            }
            <Box sx={styles.pContainer}>
                <span onClick={() => { setSteps(1) }} className={steps === 1 ? 'pagi_active' : null} style={{ ...styles.pagination, fontSize: pFontSize }}>1</span>
                <span onClick={() => { setSteps(2) }} className={steps === 2 ? 'pagi_active' : null} style={{ ...styles.pagination, fontSize: pFontSize }}>2</span>
                <span onClick={() => { setSteps(3) }} className={steps === 3 ? 'pagi_active' : null} style={{ ...styles.pagination, fontSize: pFontSize }}>3</span>
                <span onClick={() => { setSteps(4) }} className={steps === 4 ? 'pagi_active' : null} style={{ ...styles.pagination, fontSize: pFontSize }}>4</span>
                <span onClick={() => { setSteps(5) }} className={steps === 5 ? 'pagi_active' : null} style={{ ...styles.pagination, fontSize: pFontSize }}>5</span>
                <span onClick={() => { setSteps(6) }} className={steps === 6 ? 'pagi_active' : null} style={{ ...styles.pagination, fontSize: pFontSize }}>6</span>
                <span onClick={() => { setSteps(7) }} className={steps === 7 ? 'pagi_active' : null} style={{ ...styles.pagination, fontSize: pFontSize }}>7</span>
                <span onClick={() => { setSteps(8) }} className={steps === 8 ? 'pagi_active' : null} style={{ ...styles.pagination, fontSize: pFontSize }}>8</span>
                <span onClick={() => { setSteps(9) }} className={steps === 9 ? 'pagi_active' : null} style={{ ...styles.pagination, fontSize: pFontSize }}>9</span>
                <span onClick={() => { setSteps(1) }} className={steps === 10 ? 'pagi_active' : null} style={{ ...styles.pagination, fontSize: pFontSize }}>10</span>
            </Box>
        </Box >
    )
}

const styles = {
    pContainer: {
        display: 'flex',
        background: "#000000",
        color: "#fff",
        pl: 2,
        pt: 1,
        pb: 1,
        position: "fixed",
        bottom: 0,
        width: "100%"
    },
    pagination: {
        paddingTop: "0.520em",
        paddingBottom: "0.520em",
        paddingLeft: "0.9375em",
        paddingRight: "0.9375em",
        fontWeight: 500,
        cursor: "pointer",
    },
}

export default MutliSteps